import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import CaptionButton from "../Components/CaptionButton";
import BackgroundImage from "../Images/initialBackground2160.jpg";
import { ReactComponent as ChatIcon } from "../Images/chatButton.svg";
import { ReactComponent as PuzzleIcon } from "../Images/puzzle-piece.svg";
import { ReactComponent as RocketIcon } from "../Images/rocket.svg";
import { ReactComponent as DocIcon } from "../Images/doc.svg";
import { ReactComponent as GeniusIcon } from "../Images/geniusOrange.svg";
import { headerCss, hideMobile } from "../Utils/utils";
import videoSrc from "../Videos/entrance-animation.mp4";
import { useAppSelector } from "Redux/hooks";
import { getUserGroup } from "Redux/slices/userSlice";

const useStyles = makeStyles((theme) => ({
  backGroundVideo: {
    height: "100%",
    width: "100%",
    float: "left",
    top: 0,
    position: "fixed",
    objectFit: "cover",
  },
  root: {
    //height: "100%",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    ...(headerCss(theme) as any),
    width: "100%",
    marginTop: 0,
  },
  firstRow: {
    width: "80%",
    margin: "auto",
    marginTop: "20vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: 25,
    },
  },
  secondRow: {
    width: "80%",
    margin: "auto",
    marginTop: "7vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "90%",
    },
  },
  thirdRow: {
    width: "80%",
    margin: "auto",
    marginTop: "15vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "90%",
    },
  },
  topText: {
    "font-family": "MuseoSlab",
    "font-size": "50px",
    "font-weight": 100,
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    "letter-spacing": "1.79px",
    "text-align": "center",
    color: "#01366c",
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: 25,
    },
  },
  hideMobile: hideMobile(theme) as any,
}));

export default function InitialMenu() {
  const classes = useStyles();
  const userRole = useAppSelector(getUserGroup);
  return (
    <div className={classes.root}>
      {userRole === "Customer" && (
        <video
          className={classes.backGroundVideo + " " + classes.hideMobile}
          autoPlay
          muted>
          <source src={videoSrc} type='video/mp4' />
        </video>
      )}
      <div>
        <Grid item className={classes.firstRow}>
          <Typography className={classes.topText}>
            What can we help you accomplish today?
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          justify='space-between'
          className={classes.secondRow}>
          <Grid item className={classes.item} style={{ display: "none" }}>
            <CaptionButton
              title='Problem to Solve'
              body='You want to review our recommendations to initiate a lab project.'
              color='orange'
              icon={
                <div style={{ overflow: "visible", width: 32, height: 38 }}>
                  <PuzzleIcon />
                </div>
              }
              to='/problem-to-solve'
              width={205}
            />
          </Grid>
          <Grid item className={classes.item}>
            <CaptionButton
              title='Project Launcher'
              body='You are ready to initiate a lab project with Mr. Adhesion'
              color='blue'
              icon={<RocketIcon />}
              to='/product-launcher'
              width={248}
            />
          </Grid>
          <Grid item className={classes.item}>
            <CaptionButton
              title='Sales Chat'
              body='You need to get a quote or place an order with Ms. Rheology'
              to='/chat'
              icon={<ChatIcon />}
              width={206}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justify='space-around'
          className={classes.thirdRow}>
          <Grid item className={classes.item} style={{ display: "none" }}>
            <CaptionButton
              title='Regulatory Genius'
              body='You need to download standard regulatory documents'
              icon={<DocIcon />}
              to='/regulatory-search'
              color='blue'
              width={270}
            />
          </Grid>
          <Grid item className={classes.item} style={{ display: "none" }}>
            <CaptionButton
              title='Product Genius'
              body='You are interested in reviewing our full portfolio to initiate a lab project'
              icon={<GeniusIcon />}
              to='/product-genius'
              color='blue'
              width={300}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
