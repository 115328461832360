import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Dialog from "./Dialog";
import NarrowSelect from "./NarrowSelect";
import NarrowTextfield from "./NarrowTextfield";
import SmallButton from "./SmallButton";
import { useAppSelector } from "Redux/hooks";
import { getVicePresidentUsers } from "Redux/slices/usersSlice";
import emailValidator from "Utils/emailValidator";
import { addUser } from "Redux/slices/usersSlice";
import { useDispatch } from "react-redux";
import { userApi } from "Utils/api/userApi";
import { ICreateUserDialogProps } from "Interfaces/props/createUserDialogProps";
import { IApiState } from "Interfaces/apiState";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 10,
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    marginTop: 10,
  },
  buttonRoot: {
    margin: "auto",
  },
}));

const roleOptions = [
  { label: "Vice President", value: "VicePresident" },
  { label: "Lab Worker", value: "LabWorker" },
  { label: "Lab Manager", value: "LabManager" },
  {
    label: "Technical Sales Representative",
    value: "TechnicalSalesRepresentative",
  },
  { label: "Customer", value: "Customer" },
  { label: "Admin", value: "Admin" },
];

const requiresReportsToField = [
  "LabWorker",
  "LabManager",
  "TechnicalSalesRepresentative",
];


export default function CreateUserDialog(props: ICreateUserDialogProps) {
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [reports, setReports] = React.useState("");

  const [showReportsToField, setShowReportsToField] = React.useState(false);
  const classes = useStyles();
  const vpUsers = useAppSelector(getVicePresidentUsers);
  React.useEffect(() => {
    console.log("vp ", vpUsers)
  });
  const reportsToOption: Array<any> = vpUsers.map((vpUser) => ({
    label: vpUser.name,
    value: vpUser.profile?.id,
  }));
  const [fieldErrors, setFieldErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [apiStatus, setApiStatus] = React.useState<IApiState>({});
  const dispatch = useDispatch();

  const validateFormFields = () => {
    let result = true;
    let evaluateFieldErrors: { [key: string]: string } = {
      fullName: "",
      email: "",
      role: "",
      reports: "",
    };
    if (!fullName) {
      result = false;
      evaluateFieldErrors = { fullName: "Can not be empty" };
    }
    if (!email) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        email: "Can not be empty",
      };
    } else if (!emailValidator(email)) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        email: "Not valid email",
      };
    }
    if (!role) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        role: "Please select a role",
      };
    }
    if (role && requiresReportsToField.includes(role) && !reports) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        reports: "Please select a manager",
      };
    }
    setFieldErrors(evaluateFieldErrors);
    return result;
  };

  const handleFormSubmission = () => {
    if (!validateFormFields()) {
      return;
    }
    setApiStatus({
      loading: true,
      loaded: false,
      error: false,
      errorMsg: "",
    });
    const apiPayload = {
      name: fullName,
      email,
      profile_type: role,
      profile: { vice_president_id: reports },
    };
    userApi
      .createUser(apiPayload)
      .then((response) => {
        setApiStatus({
          loading: false,
          loaded: true,
          error: false,
          errorMsg: "",
        });
        const vpName = reportsToOption.find((opt) => opt.value === reports)
          ?.label;

        let actionPayload = response.data;
        actionPayload = {
          ...actionPayload,
          reports_to: vpName,
        };
        dispatch(addUser(actionPayload));
        clearForm();
      })
      .catch((err) => {
        const serverErrorMsg = err.response?.data[0];
        setApiStatus({
          loading: false,
          loaded: false,
          error: true,
          errorMsg: serverErrorMsg || "Something went wrong!!",
        });
      });
  };

  const clearForm = () => {
    setFullName("");
    setEmail("");
  };

  return (
    <Dialog open={props.open} handleClose={props.handleClose}>
      <div className={classes.root}>
        <Typography className={classes.headerText}>New User</Typography>
        <Typography className={classes.subHeaderText}>
          Fill out the fields below to create a new user.
        </Typography>
        {apiStatus.loading && (
          <Typography className={classes.subHeaderText}>
            Creating.....
          </Typography>
        )}
        {apiStatus.error && (
          <Typography
            className={classes.subHeaderText}
            style={{ color: "#d0021b" }}
          >
            {apiStatus.errorMsg}
          </Typography>
        )}
        {apiStatus.loaded && (
          <Typography className={classes.subHeaderText}>
            User Created!!!!!
          </Typography>
        )}
        <NarrowTextfield
          width={300}
          placeholder="Full Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFullName(event.target.value);
          }}
          value={fullName}
          style={{ marginTop: 35 }}
        />
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}
        >
          {fieldErrors.fullName}
        </Typography>
        <NarrowTextfield
          width={300}
          placeholder="Email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          value={email}
          style={{ marginTop: 10 }}
        />
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}
        >
          {fieldErrors.email}
        </Typography>
        <NarrowSelect
          width={300}
          style={{ marginTop: 10 }}
          options={roleOptions}
          default="Role"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            if (requiresReportsToField.includes(event.target.value)) {
              setShowReportsToField(true);
            } else {
              setShowReportsToField(false);
            }
            setRole(event.target.value);
          }}
        />
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}
        >
          {fieldErrors.role}
        </Typography>
        {showReportsToField && (
          <NarrowSelect
            width={300}
            style={{ marginTop: 10 }}
            options={reportsToOption} //make sure to pass in object with name/id and then return id value
            default="Reports To"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setReports(event.target.value);
            }}
          />
        )}
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}
        >
          {fieldErrors.reports}
        </Typography>
        <SmallButton
          onClick={handleFormSubmission}
          color="green"
          className={classes.buttonRoot}
          style={{ marginTop: 25 }}
        >
          CREATE USER
        </SmallButton>
        <SmallButton
          onClick={props.handleClose}
          color="blue"
          style={{ marginTop: 10 }}
          className={classes.buttonRoot}
        >
          CANCEL
        </SmallButton>
      </div>
    </Dialog>
  );
}
