import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";

const TopHeader = (props: any) => {
  const classes = useStyles();
  React.useEffect(() => {
   console.log("customer name ", props.customerName)
  },[]);
  return (
    <>
      <Typography className={classes.subText} style={{ marginTop: 25 }}>
        From: {props.userName}
      </Typography>
      <Typography className={classes.subText} style={{ marginTop: 15 }}>
        To: {props.customerName || "John Doe"}
      </Typography>
    </>
  );
};

export default TopHeader;
