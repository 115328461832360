import { Grid, makeStyles, Typography } from "@material-ui/core";
import { IMultipleChoiceProps } from "Interfaces/props/multipleChoiceProps";
import * as React from "react";
import Option from "./MultipleChoiceOption";
import TipButton from "./TipButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  question: {
    fontFamily: "MuseoSlab",
    "font-weight": 300,
    "font-size": "28px",
    "line-height": 1.2,
    "letter-spacing": "1px",
    "text-align": "left",
    color: "#01366c",
    marginRight: (props: IMultipleChoiceProps) => (props.horizontal ? 25 : 0),
  },
  subText: {
    "font-family": "MontserratBold",
    "font-size": "14px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    "letter-spacing": "0.47px",
    color: "#01366c",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default function MultipleChoice(props: IMultipleChoiceProps) {
  const [showAll, setShowAll] = React.useState(false);
  const classes = useStyles(props);
  const maxOptions = showAll ? Number.MAX_VALUE : props.maxOptions || Number.MAX_VALUE;
  const displayAll = () => {
    setShowAll(true);
  };
  return (
    <Grid
      container
      direction={props.horizontal ? "row" : "column"}
      alignItems={props.horizontal ? "center" : undefined}
    >
      {props.tooltip ? (
        <Grid item style={{ marginRight: 10 }}>
          <TipButton onClick={props.onToolTip} />
        </Grid>
      ) : undefined}
      <Typography className={classes.question}>{props.title}</Typography>
      {props.options.map((label, idx) => {
        if (idx >= maxOptions) return <></>;
        return (
          <Option
            key={idx}
            onSelect={() => {
              props.onSelect(idx, props.dbField);
            }}
            label={label}
            isSelected={props.selectedIdx === idx}
            fontSize={props.fontSize}
            style={props.horizontal ? { marginRight: 25 } : {}}
            maxWidth={500}
            responsive={props.responsive}
          />
        );
      })}
      {props.options.length < maxOptions || showAll ? (
        <></>
      ) : (
        <Typography className={classes.subText} onClick={displayAll}>
          Show More
        </Typography>
      )}
    </Grid>
  );
}
