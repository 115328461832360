import { makeStyles, Typography } from "@material-ui/core";
import Message from "./Message";
import Loader from "./Loader";
import ChatTextField from "./ChatTextField";
import { IChatBoxProps } from "Interfaces/props/chatBoxProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: IChatBoxProps) => props.width || 500,
    minHeight: 400,
    //padding: "8px 16px 7px 15px"
    maxHeight: 400,
    // paddingLeft: 25,
    paddingTop: 5,
    // paddingRight: 25,
    paddingBottom: 20,
    //"border-radius": "50px",
    border: "solid 2.5px #01366c",
    backgroundColor: "white",
    "-webkit-box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.1)",
    "box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.1)",
    [theme.breakpoints.down("xs")]: {
      width: "90vw !important",
    },
    position: "relative",
    marginLeft: (props: IChatBoxProps) => (props.alignCenter ? "auto" : "0"),
    marginRight: (props: IChatBoxProps) => (props.alignCenter ? "auto" : "0"),
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 30,
    //marginBottom: 10,
  },
  chatParent: {
    overflow: "scroll",
    maxHeight: 250,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 70,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // justifyContent: 'center',
    // alignItems: 'center',
    // display: 'flex'
    // align
  },
  "chatParent::-webkit-scrollbar": {
    display: "none",
  },
  loaderContainer: {
    display: "flex",
    minHeight: 340,
    justifyContent: "center",
    alignItems: "center",
  },
  chatButton: {
    color: "#636f7e",
    "font-size": "1em",
    "font-weight": "bold",
  },
  startButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
    "-webkit-box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
    paddingTop: 10,
    paddingBottom: 10,
    background: "white",
  },
}));

export default function ChatBox(props: IChatBoxProps) {
  const classes = useStyles(props);
  return (
    <div className={classes.root + " " + props.className} style={props.style}>
      <Typography className={classes.titleText}>{props.title}</Typography>
      <div className={classes.chatParent} id={"chatContainer"}>
        {props.error && (
          <Message
            title="We are currently offline and will be available during regular business hours"
            date={""}
            message={""}
            self={false}
          />
        )}
        {props.messages.map((message: any) => {
          return <Message key={message.id} {...message} />;
        })}
        {props.loading && (
          <div className={classes.loaderContainer}>
            {" "}
            <Loader />
          </div>
        )}
      </div>

      {props.canMessage ? (
        <>
          <ChatTextField onSend={props.onNewMessage} />
        </>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          className={classes.startButtonContainer}
          onClick={() => props.startChat && props.startChat()}>
          <div className={classes.chatButton}>Start New Chat</div>
        </div>
      )}
    </div>
  );
}
