import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import ProductTileBlue from "../Images/product-tile-blue.svg";
import LargeButton from "./LargeButton";
import { ReactComponent as DetailsIcon } from "../Images/details.svg";
import { IPictureTileProps } from "Interfaces/props/pictureTileProps";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${ProductTileBlue})`,
    width: "364px",
    height: "479px",
    margin: 25,
    [theme.breakpoints.down(450)]: {
      //marginLeft: 0,
      ///marginRight: 0,
      marginTop: 50,
      //marginBottom: 25,
      backgroundSize: "cover",
      height: 479,
    },
    [theme.breakpoints.down(380)]: {
      height: 471,
    },
    [theme.breakpoints.down(350)]: {
      height: 380,
    },
  },
  topImage: {
    width: 358,
    height: 195,
    borderTopLeftRadius: "82px",
    borderTopRightRadius: "82px",
    borderBottom: "solid 4px #01366c",
    display: "block",
    margin: "auto",
    marginTop: 3,
    [theme.breakpoints.down(350)]: {
      width: 290,
    },
  },
  titleText: {
    "font-family": "MuseoSlab",
    "font-size": "28px",
    "font-weight": 300,
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.25,
    "letter-spacing": "1px",
    "text-align": "center",
    color: "#01366c",
    width: "80%",
    margin: "auto",
    paddingTop: 10,
    paddingBottom: 20,
  },
  bodyText: {
    "font-family": "MuseoSlab",
    "font-size": "15px",
    "font-weight": 300,
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.33,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    width: "90%",
    margin: "auto",
  },
}));

export default function PictureTile(props: IPictureTileProps) {
  const classes = useStyles();
  return (
    <Grid item className={classes.root}>
      <img src={props.src} alt="Top of the tile" className={classes.topImage} />
      <Typography className={classes.titleText}>{props.title}</Typography>
      <Typography className={classes.bodyText}>{props.body}</Typography>
      <LargeButton
        text="VIEW DETAILS"
        color="green"
        icon={<DetailsIcon />}
        onClick={props.onClick}
        style={{ margin: "auto", marginTop: 25 }}
      />
    </Grid>
  );
}
