import { Grid, makeStyles } from "@material-ui/core";
import { INarrowSelectProps } from "Interfaces/props/narrowSelectProps";
import * as React from "react";
import SelectOption from "./SelectOption";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: INarrowSelectProps) => props.width || 500,
    //padding: "8px 16px 7px 15px",
    paddingLeft: 15,
    paddingTop: 5,
    paddingRight: 10,
    "border-radius": "100px",
    border: "solid 2.5px #01366c",
    backgroundColor: "white",
    "-webkit-box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
  },
  content: {
    margin: (props: INarrowSelectProps) =>
      props.centered ? "auto" : undefined,
    width: (props: INarrowSelectProps) => (props.centered ? 500 : undefined),
  },
  input: {
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    outline: "none",
    fontSize: 15,
    height: 34,
    width: "100%",
    textOverflow: "ellipsis",
  },
  selectText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
}));

export default function NarrowSelect(props: INarrowSelectProps) {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.root} style={props.style}>
      <Grid container direction="row" className={classes.content}>
        <Grid item style={{ flexGrow: 1 }}>
          <select
            required={props.required}
            className={classes.input + " " + classes.selectText}
            onChange={props.onChange}
          >
            {props.default ? (
              <SelectOption value={""} label={props.default} selected={true} />
            ) : (
              <></>
            )}
            {props.options.map((option, idx) => {
              return (
                <SelectOption key={idx} value={option.value || ""} label={option.label} selected={option.selected}/>
              );
            })}
          </select>
        </Grid>
      </Grid>
    </Grid>
  );
}
