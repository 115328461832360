import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../Utils/utils";
import { RootState } from "../store";
import { IUserInfo } from "Interfaces/user";
import { IApiState } from "Interfaces/apiState";

// Define a type for the slice state
interface UserState {
  users: Array<IUserInfo & { role: string; reports_to: string; id: string }>;
  fetchApiState?: IApiState;
}

// Define the initial state using that type
const initialState: UserState = {
  users: [],
  fetchApiState: {},
};

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async () => {
    const response = await get("/users");
    return response.data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clear: (state) => {
      state = { fetchApiState: {}, users: [] };
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
      return state;
    },
    removeUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
      return state;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.fetchApiState = {
        loaded: true,
        loading: false,
        error: false,
      };
    });
    builder.addCase(fetchAllUsers.pending, (state, action) => {
      state.fetchApiState = {
        loaded: false,
        loading: true,
        error: false,
      };
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const getUsers = (state: RootState) => state.users.users;
export const getVicePresidentUsers = (state: RootState) =>
  state.users.users.filter((user) => user.profile_type === "VicePresident");
export const getFetchUserApiStatus = (state: RootState) =>
  state.users.fetchApiState;

export const { addUser, clear, removeUser, setUsers } = usersSlice.actions;

export default usersSlice.reducer;
