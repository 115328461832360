import { makeStyles } from "@material-ui/core";
import { ISelectOptionProps } from "Interfaces/props/selectOptionProps";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
}));

export default function SelectOption(props: ISelectOptionProps) {
  const classes = useStyles();
  return (
    <option
      value={props.value}
      selected={props.selected}
      className={classes.root}
    >
      {props.label}
    </option>
  );
}
