import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/store";
import { CssBaseline } from "@material-ui/core";
import LeaveDialog from "./Components/LeaveDialog";
import { post, patch } from "./Utils/utils";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const getUserConfirmation = (message: any, callback: any) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const closeModal = (callbackState: boolean) => {
    const tempProject = JSON.parse(message);
    if (callbackState) {
      if(!tempProject.id) {
        tempProject.is_submitted = false;
        post("/customer/projects", tempProject).then(() => {
          ReactDOM.unmountComponentAtNode(container);
          document.body.removeChild(container);
          callback(callbackState);
        });
      } else {
        patch(`/customer/projects/${tempProject.id}`, tempProject).then(() => {
          ReactDOM.unmountComponentAtNode(container);
          document.body.removeChild(container);
          callback(callbackState);
        });
      }
    } else {
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
      callback(callbackState);
    }
  };
  ReactDOM.render(
    <LeaveDialog
      open={true}
      handleClose={() => {
        closeModal(true);
      }}
      handleConfirm={() => {
        closeModal(false);
      }}
    />,
    container
  );
};

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
    >
      <Provider store={store}>
        <Router getUserConfirmation={getUserConfirmation}>
          <CssBaseline />
          <App />
        </Router>
      </Provider>
    </GoogleReCaptchaProvider>
    </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
