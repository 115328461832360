import { Grid, makeStyles } from "@material-ui/core";
import { ProgressBarProps } from "Interfaces/props/progressBarProps";
import React from "react";
import ProgressBarTile from "./ProgressBarTile";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    "border-radius": "40px",
    border: "solid 2.5px #01366c",
    display: "table",
    zIndex: 10,
  },
}));

const ProgressBar = (props: ProgressBarProps) => {
  const classes = useStyles();
  if (!props.stages) return <></>;
  let tileWidth = (100 / props.stages.length).toString();
  tileWidth += "%";
  return (
    <Grid container direction="row" className={classes.root}>
      {props.stages.map((stage, idx) => {
        let currentStage = "";
        let isFirst = idx === 0;
        let isLast = idx === props.stages.length - 1;
        if (props.currentStage < idx) {
          currentStage = "future";
        } else if (props.currentStage === idx) {
          currentStage = "current";
        } else if (props.currentStage > idx) {
          currentStage = "past";
        }
        return (
          <ProgressBarTile
            key={stage}
            label={stage}
            stage={currentStage}
            width={tileWidth}
            isFirst={isFirst}
            isLast={isLast}
            onClick={() => {
              if (props.onClick) props.onClick(idx);
            }}
          />
        );
      })}
    </Grid>
  );
};

export default ProgressBar;
