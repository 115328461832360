import { Grid, makeStyles, Typography } from "@material-ui/core";
import { IMessageTextFieldProps } from "Interfaces/props/messageTextFieldProps";
import * as React from "react";
import NextButton from "./NextButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: IMessageTextFieldProps) => props.width || "100%",
    //padding: "8px 16px 7px 15px",
    paddingLeft: 15,
    paddingTop: 5,
    "border-radius": "100px",
    border: "solid 2.5px #01366c",
    backgroundColor: "white",
    "-webkit-box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
  },
  inputRoot: {
    width: (props: IMessageTextFieldProps) => props.messageTextFieldWidth,
    [theme.breakpoints.down("xs")]: {
      width: 150,
    },
    [theme.breakpoints.down(350)]: {
      width: 110,
    },
  },
  input: {
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    outline: "none",
    fontSize: 15,
    height: 34,
    width: "100%",
    textOverflow: "ellipsis",
    "&::placeholder": {
      fontFamily: "VarelaRound",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "normal",
      color: "#01366c",
    },
  },
  buttonText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#01366c",
  },
}));

export default function MessageTextField(props: IMessageTextFieldProps) {
  const [message, setMessage] = React.useState("");
  const classes = useStyles(props);

  const changeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    if (props.onChangeCallBack) {
      props.onChangeCallBack();
    }
  };

  const sendMessage = () => {
    props.onSend(message);
    setMessage("");
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root + " " + props.className}
    >
      <Grid item className={classes.inputRoot}>
        <input
          className={classes.input}
          placeholder={props.placeholder || "Message"}
          onChange={changeMessage}
          value={message}
        ></input>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ width: "max-content" }}
      >
        <Typography className={classes.buttonText}>Submit</Typography>
        <NextButton
          onClick={sendMessage}
          isReady={true}
          style={{
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            marginTop: -5,
          }}
        />
      </Grid>
    </Grid>
  );
}
