import { patch } from "Utils/utils";
import snakecaseKeys from "snakecase-keys";

const updateProjectSteps = (params: {
  projectId: string;
  projectStatus?: Number;
  stepsPublished?: Boolean;
  noticeType?: string;
}) => {
  return patch(`/admin/projects/${params.projectId}`, snakecaseKeys(params));
};

export default updateProjectSteps;
