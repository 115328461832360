import { makeStyles, Modal } from "@material-ui/core";
import { IDialogProps } from "Interfaces/props/dialogProps";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    "border-radius": "25%",
    border: "solid 2.5px #01366c",
    "background-color": " #F79730",
    minWidth: "444px",
    padding: 20,
    //"min-height": " 350px",
    "box-shadow": "inset -4px -3px 0px 1px #F26410",
    outline: 0,
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
    //position: "relative",
    "z-index": 5,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    paddingBottom: 40,
  },
}));

const Dialog: React.FC<IDialogProps> = (props) => {
  const classes = useStyles();
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <div className={classes.root}>{props.children}</div>
    </Modal>
  );
};

export default Dialog;
