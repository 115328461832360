import { Grid, makeStyles, Typography } from "@material-ui/core";
import { TSRProps } from "Interfaces/props/lprDialogProps";
import * as React from "react";
import Dialog from "./Dialog";
import LargeButton from "./LargeButton";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  subTitleText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    margin: "auto",
    width: 325,
    marginTop: 20,
  },
  textFieldLabel: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#003570",
  },
}));

const TSRBody = (props: TSRProps) => {
  const classes = useStyles();

  const submit = () => {
    props.onSubmit();
  };

  return (
    <Grid container direction="column">
      <Typography className={classes.titleText}>Mark Complete</Typography>
      <Typography className={classes.subTitleText}>
        Mark this LPR complete?
      </Typography>
      <LargeButton
        color="green"
        text="MARK COMPLETE"
        style={{ margin: "auto", marginTop: 15 }}
        onClick={submit}
      />
      <LargeButton
        color="blue"
        text="CANCEL"
        style={{ margin: "auto", marginTop: 15 }}
        onClick={props.onClose}
      />
    </Grid>
  );
};

interface UserProps {
  onSubmit: () => void;
  onClose: () => void;
}

const UserBody = (props: UserProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Typography className={classes.titleText}>Submit LPR?</Typography>
      <Typography className={classes.subTitleText}>
        Are you sure you want to submit this LPR to the Regional VP for
        approval?
      </Typography>
      <LargeButton
        color="green"
        text="SUBMIT"
        style={{ margin: "auto", marginTop: 35 }}
        onClick={props.onSubmit}
      />
      <LargeButton
        color="blue"
        text="CANCEL"
        style={{ margin: "auto", marginTop: 15 }}
        onClick={props.onClose}
      />
    </Grid>
  );
};

interface SuccessProps {
  handleClose: (goBack:boolean) => void;
  isTPR: boolean;
  productName: string;
}

const SuccessMessage = (props: SuccessProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Typography className={classes.titleText}>Success!</Typography>
      <Typography className={classes.subTitleText}>
        The LPR for {props.productName}{" "}
        {props.isTPR
          ? "was marked complete and given a CPR."
          : "was submitted to the Regional VP for approval."}
      </Typography>
      <LargeButton
        color="blue"
        text="AWESOME"
        style={{ margin: "auto", marginTop: 15 }}
        onClick={()=>{
          props.handleClose(true);
        }}
      />
    </Grid>
  );
};

export interface ILPRDialogProps {
  isUser: boolean;
  isOpen: boolean;
  handleClose: (goBack:boolean) => void;
  productName: string;
  onSubmit: ()=>void;
}

export default function LPRDialog(props: ILPRDialogProps) {
  const [isReady, setReady] = React.useState(false);

  let mainElement = props.isUser ? (
    <UserBody
      onSubmit={() => {
        props.onSubmit();
        setReady(true);
      }}
      onClose={() => {
        props.handleClose(false);
      }}
    />
  ) : (
    <TSRBody
      onSubmit={() => {
        props.onSubmit();
        setReady(true);
      }}
      onClose={() => {
        props.handleClose(false);
      }}
    />
  );

  const handleClose = ()=>{
    props.handleClose(false);
  }

  return (
    <Dialog open={props.isOpen} handleClose={handleClose}>
      {isReady ? (
        <SuccessMessage
          productName={props.productName}
          handleClose={props.handleClose}
          isTPR={!props.isUser}
        />
      ) : (
        mainElement
      )}
    </Dialog>
  );
}
