import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import { useAppSelector } from "Redux/hooks";
import { getUserId } from "Redux/slices/userSlice";
import { projectApi } from "Utils/api/projectApi";
import { numberWithCommas, post } from "../Utils/utils";
import AddButton from "./AddButton";
import CommentBox from "./CommentBox";
import MultipleChoice from "./MultipleChoice";
import NarrowTextfield from "./NarrowTextfield";
import { ReactComponent as PDFIcon } from "../Images/pdf.svg";
import { ICommercialAssessmentProps } from "Interfaces/props/commercialAssessmentProps";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  assessmentText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 35,
  },
  ndaLink: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  textField: { marginTop: 15 },
  centerItem: { margin: "auto", marginTop: 25 },
  listText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    marginRight: 10,
  },
  iconRoot: {
    width: 35,
    height: 35,
  },
}));

export default function CommercialAssessment(
  props: ICommercialAssessmentProps
) {
  const { updateCommercialAssessment = () => {} } = props;
  const [nda, setNDA] = React.useState(props.nda !== undefined ? props.nda : -1);
  const [ndaLink, setNDALink] = React.useState(props.ndaLink || "");
  const [arp, setARP] = React.useState(props.arp || -1);
  const [customerEta, setCustomerETA] = React.useState(props.customerEta || -1);
  const [saleEta, setSalesETA] = React.useState(props.saleEta || -1);
  const [successProbability, setSuccessProbability] = React.useState(
    props.successProbability || -1
  );
  const [products, setProducts] = React.useState(props.products || []);
  const userId = useAppSelector(getUserId);
  const classes = useStyles();

  const id = props.id;
  const { apiState } = props;

  React.useEffect(() => {
    props.setCAErrors(
      !arp ||
        arp === -1 ||
        !customerEta ||
        customerEta === -1 ||
        !successProbability ||
        successProbability === -1 ||
        !saleEta ||
        saleEta === -1
    );
  });

  React.useEffect(() => {
    handleFormSubmission();
  }, [nda]);

  const handleFormSubmission = (updateProducts?: Array<string>) => {
    const bodyProducts = updateProducts ? updateProducts : products;
    const body = {
      commercial_assessment: {
        annual_revenue_potential: arp === -1 ? undefined : arp,
        customer_eta_in_days: customerEta === -1 ? undefined : customerEta,
        sales_eta_in_days: saleEta === -1 ? undefined : saleEta,
        nda_required: nda === -1 ? undefined : nda < 2,
        success_probability:
          successProbability === -1 ? undefined : successProbability,
        lines: bodyProducts,
      },
    };
    if (
      (arp === props.arp || arp === -1) &&
      (customerEta === props.customerEta || customerEta === -1) &&
      (saleEta === props.saleEta || saleEta === -1) &&
      (nda === props.nda || nda === -1) &&
      (successProbability === props.successProbability ||
        successProbability === -1) &&
      !updateProducts
    ) {
      return; //return if no changes made compared to project in api
    }

    updateCommercialAssessment(body.commercial_assessment);
    projectApi.updateCommercial(id, body);
  };

  const changeProducts = (idx: number, value: string) => {
    let newProducts = Array.from(products);
    if (value !== "") newProducts[idx] = value;
    else {
      newProducts = newProducts.filter((val, index) => index !== idx);
      handleFormSubmission(newProducts);
    }
    setProducts(newProducts);
  };

  const createProducts = () => {
    let newProducts = Array.from(products);
    newProducts.push("");
    setProducts(newProducts);
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("nda", file, file.name);
    post("/admin/projects/" + id + "/update_nda", formData).then((response) => {
      setNDALink(response.data.commercial_assessment?.nda);
    });
  };

  return (
    <>
      <Typography className={classes.assessmentText}>
        Commercial Assessment
      </Typography>
      {apiState?.loading && (
        <Typography className={classes.titleText}>
          Loading Data.........
        </Typography>
      )}
      {apiState?.loaded && (
        <>
          <Grid container direction="column">
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography className={classes.titleText}>
                  Products/Lines
                </Typography>
                {products.map((product, idx) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      key={idx}
                      alignItems="center"
                      className={classes.textField}
                    >
                      <Typography className={classes.listText}>
                        {(idx + 1).toString()}.{" "}
                      </Typography>
                      {props.editable ? (
                        <NarrowTextfield
                          width={250}
                          placeholder="Type product/line name here"
                          value={product}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            changeProducts(idx, event.target.value);
                          }}
                          onBlur={() => handleFormSubmission(products)}
                        />
                      ) : (
                        <Typography className={classes.listText}>
                          {product}
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
                {props.editable ? (
                  <AddButton
                    size={20}
                    onClick={createProducts}
                    label="Add Another Product/line"
                    fontSize={12}
                    style={{ marginTop: 10, marginLeft: 25 }}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Typography className={classes.titleText}>
                  Sales Timing {props.editable ? "(*)" : ""}
                </Typography>
                {props.editable ? (
                  <NarrowTextfield
                    width={250}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSalesETA(Number(event.target.value));
                    }}
                    placeholder="Type number of months here"
                    className={classes.textField}
                    value={!saleEta || saleEta === -1 ? "" : saleEta.toString()}
                    onBlur={() => handleFormSubmission()}
                  />
                ) : (
                  <Typography
                    className={classes.listText}
                    style={{ width: 200 }}
                  >
                    {saleEta === -1 ? "Not Set Yet" : saleEta + " Months"}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography className={classes.titleText}>
                  Annual Revenue Potential{props.editable ? "(*)" : ""}
                </Typography>
                {props.editable ? (
                  <NarrowTextfield
                    width={250}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      console.log(event.target.value);
                      setARP(parseInt(event.target.value));
                    }}
                    placeholder="Type dollar amount here"
                    className={classes.textField}
                    value={!arp || arp === -1 ? "" : arp.toString()}
                    onBlur={() => handleFormSubmission()}
                  />
                ) : (
                  <Typography className={classes.listText}>
                    {arp === -1 ? "Not Set Yet" : "$" + numberWithCommas(arp)}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography className={classes.titleText}>
                  Customer Time Frame{props.editable ? "(*)" : ""}
                </Typography>
                {props.editable ? (
                  <NarrowTextfield
                    width={250}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomerETA(parseInt(event.target.value));
                    }}
                    placeholder="Type number of months here"
                    className={classes.textField}
                    value={
                      !customerEta || customerEta === -1
                        ? ""
                        : customerEta.toString()
                    }
                    onBlur={() => handleFormSubmission()}
                  />
                ) : (
                  <Typography className={classes.listText}>
                    {customerEta === -1
                      ? "Not Set Yet"
                      : customerEta + " Months"}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row">
              {props.editable ? (
                <Grid item style={{ marginRight: 30 }}>
                  <Typography className={classes.titleText}>
                    CPR (Commercial Probability Rating)
                    {props.editable ? "(*)" : ""}
                  </Typography>
                  <Grid container direction="row">
                    <NarrowTextfield
                      width={250}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSuccessProbability(parseInt(event.target.value));
                      }}
                      className={classes.textField}
                      value={
                        !successProbability || successProbability === -1
                          ? ""
                          : successProbability.toString()
                      }
                      onBlur={() => handleFormSubmission()}
                      textToPrepend="%"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item style={{ marginRight: 30 }}>
                  <Typography className={classes.titleText}>
                    Probability of Sales Success
                  </Typography>
                  <Typography className={classes.subText}>
                    {successProbability === -1
                      ? "Not Set Yet"
                      : successProbability + "%"}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography className={classes.titleText}>
                  Is there an NDA for this project?
                </Typography>
                <MultipleChoice
                  title=""
                  onSelect={
                    props.editable
                      ? (idx: number) => {
                          setNDA(idx);
                        }
                      : () => {}
                  }
                  selectedIdx={nda}
                  options={["Yes - Completed", "Yes - In Process", "No"]}
                />
              </Grid>
              <Grid item style={{ marginLeft: 25 }}>
                {props.editable && !ndaLink.length && (
                  <>
                    <Typography className={classes.titleText}>NDA:</Typography>
                    <AddButton
                      size={25}
                      fontSize={15}
                      label="Attach NDA"
                      onClick={() => {}}
                      style={{ marginTop: 10 }}
                      file
                      accept={".pdf, .docx, .doc"}
                      onChange={onUpload}
                    />
                  </>
                )}
                {props.editable && ndaLink.length > 0 && (
                  <>
                    <Typography className={classes.titleText}>NDA:</Typography>
                    <a
                      className={classes.ndaLink}
                      href={ndaLink}
                      target="_blank"
                    >
                      <SvgIcon viewBox="0 0 35 35" className={classes.iconRoot}>
                        <PDFIcon />
                      </SvgIcon>
                      Review NDA
                    </a>
                  </>
                )}
                {!props.editable && ndaLink.length > 0 && (
                  <>
                    <Typography className={classes.titleText}>NDA:</Typography>
                    <a
                      className={classes.ndaLink}
                      href={ndaLink}
                      target="_blank"
                    >
                      <SvgIcon viewBox="0 0 35 35" className={classes.iconRoot}>
                        <PDFIcon />
                      </SvgIcon>
                      Review NDA
                    </a>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.titleText}>
                Additional Notes {"&"} Comments
              </Typography>
            </Grid>
            <Grid item>
              <CommentBox
                width="80%"
                messageTextFieldWidth="75%"
                className={classes.centerItem}
                comments={
                  props.notes
                    ? props.notes.map((note) => {
                        return {
                          self: userId === note.user.id,
                          message: note.body,
                          title: note.user.name,
                          date: note.created_at,
                        };
                      })
                    : []
                }
                editable={props.notesEditable}
                onNewMessage={props.onNewMessage}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
