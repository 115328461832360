import { makeStyles } from "@material-ui/core";
import NextButtonIcon from "../Images/next.svg";
import NextButtonIconHover from "../Images/next-hover.svg";
import { INextButtonProps } from "Interfaces/props/nextButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 34,
    height: 34,
    cursor: "pointer",
    backgroundImage: `url(${NextButtonIcon})`,
    "&:hover": {
      backgroundImage: `url(${NextButtonIconHover})`,
    },
  },
  disabledRoot: {
    width: 34,
    height: 34,
    opacity: "50%",
    cursor: "default",
    backgroundImage: `url(${NextButtonIcon})`,
  },
}));


const NextButton = (props: INextButtonProps) => {
  const classes = useStyles(props);
  let isReady = props.isReady;
  if (props.isReady === undefined) isReady = true;
  return (
    <div
      className={isReady ? classes.root : classes.disabledRoot}
      style={props.style}
      onClick={isReady ? props.onClick : undefined}
    />
  );
};

export default NextButton;
