import {Grid, makeStyles, SvgIcon, Typography} from "@material-ui/core";
import * as React from "react";
import { ReactComponent as BeakerEmpty } from "../Images/beaker.svg";
import { ReactComponent as BeakerLow } from "../Images/beaker-low.svg";
import { ReactComponent as BeakerHalf } from "../Images/beaker-half.svg";
import { ReactComponent as BeakerFull } from "../Images/beaker-full.svg";
import { Link } from "react-router-dom";
import { IProjectsTileProps } from "Interfaces/props/projectsTileProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: IProjectsTileProps) => (props.stage === "empty" ? 350 : 250),
    marginBottom: 20,
    cursor: "pointer",
  },
  iconRoot: {
    width: (props: IProjectsTileProps) =>
      props.stage === "completion" || props.stage === "completed_and_notified" ? 200 : 116,
    height: 116,
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: "#59cee7",
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
}));

export default function ProjectsTile(props: IProjectsTileProps) {
  const classes = useStyles(props);
  let svg = <></>;
  let phase = <></>;
  switch (props.stage) {
    case "plan":
    case "unclaimed":
      svg = <BeakerLow />;
      phase = <>Consultation Phase</>;
      break;
    case "technical_assessment":
      svg = <BeakerLow />;
      phase = <>Consultation Phase</>;
      break;
    case "completed_and_notified":
      svg = <BeakerFull />;
      phase = <>Complete</>;
      break;
    case "progress":
      svg = <BeakerHalf />;
      phase = <>Progress Phase</>;
      break;
    case "completion":
      svg = <BeakerFull />;
      phase = <>Complete</>;
      break;
    case "vp_approval":
    case "request_more_info":
    case "closed":
      svg = <BeakerHalf />;
      phase = <>Consultation Phase</>;
      break;
    case "commercial_assessment":
      if(props.is_submitted) {
        svg = <BeakerLow />;
        phase = <>Consultation Phase</>;
      } else {
        const editProduct = `/product-launcher/${props.id}`
        svg = <BeakerEmpty />;
        phase = (
          <>
            Looks like you haven’t finished this projects yet. Click{" "}
            <Link to={editProduct}>Here</Link> to finish!
          </>
        );
      }
      break;
    default:
      svg = <BeakerEmpty />;
      phase = (
        <>
          D-oh! Looks like you haven’t started any projects yet. Click{" "}
          <Link to="/product-launcher">Here</Link> to get started!
        </>
      );
      break;
  }
  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.root}
      onClick={props.onClick}
    >
      <Grid item style={{ width: "max-content", margin: "auto" }}>
        <SvgIcon
          className={classes.iconRoot}
          viewBox={(props.stage === "completion" || props.stage === "completed_and_notified") ? "0 0 214 104" : "0 0 106 106"}
        >
          {svg}
        </SvgIcon>
      </Grid>
      <Grid item>
        <Typography className={classes.titleText}>
          {props.name || "No Projects :("}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subText}>{phase}</Typography>
      </Grid>
    </Grid>
  );
}
