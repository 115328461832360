import { makeStyles, Typography } from "@material-ui/core";
import { DialogTextfieldProps } from "Interfaces/props/dialogTextfielProps";
import React, { useEffect } from "react";
import { asyncSetReady } from "../Utils/utils";

const useStyles = makeStyles((theme) => ({
  root: { padding: 10 },
  textareaRoot: {
    "border-radius": 15,
    border: "solid 2.5px #01366c",
    width: 290,
    height: 115,
    backgroundColor: "white",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  textarea: {
    resize: "none",
    background: "transparent",
    border: "0 none",
    width: 250,
    height: 93,
    outline: "none",
    margin: "auto",
    marginTop: 10,
    display: "block",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleText: {
    "font-family": "MuseoSlab",
    "font-size": "28px",
    "font-weight": 300,
    "font-stretch": "normal",
    "font-style": " normal",
    "line-height": 1.25,
    "letter-spacing": "1px",
    "text-align": "center",
    color: "#01366c",
    marginBottom: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

const DialogTextfield = (props: DialogTextfieldProps) => {
  const classes = useStyles();
  useEffect(() => {
    asyncSetReady(props);
    // eslint-disable-next-line
  }, []);
  const customeStyles = props.titleFontSize
    ? { fontSize: props.titleFontSize }
    : {};
  return (
    <div className={classes.root}>
      <Typography className={classes.titleText} style={customeStyles}>
        {props.title}
      </Typography>
      <div className={classes.textareaRoot}>
        <textarea
          className={classes.textarea}
          onChange={props.onChange}
          placeholder={props.placeholder}
          value={props.value}></textarea>
      </div>
    </div>
  );
};

export default DialogTextfield;
