import { Grid, makeStyles, Typography } from "@material-ui/core";
import { IRibbonProps } from "Interfaces/props/ribbonProps";
import * as React from "react";
import GreenBanner from "../Images/banner-green.svg";
import OrangeBanner from "../Images/banner-orange.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: (props: IRibbonProps) =>
      props.color === "orange" ? `url(${OrangeBanner})` : `url(${GreenBanner})`,
    width: (props: IRibbonProps) => (props.color === "orange" ? 360 : 280),
    height: 69,
  },
  mainText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: "#ffffff",
    paddingBottom: 10,
  },
}));


export default function Ribbon(props: IRibbonProps) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={props.className + " " + classes.root}
    >
      <Typography className={classes.mainText}>{props.text}</Typography>
    </Grid>
  );
}
