import { Grid, makeStyles, Typography } from "@material-ui/core";
import { IOptionProps } from "Interfaces/props/multipleChoiceOptionProps";
import * as React from "react";
import CircleCheckbox from "./CircleCheckbox";

const useStyles = makeStyles((theme) => ({
  optionRoot: {
    marginTop: 10,
    marginBottom: 10,
    cursor: "pointer",
    width: "fit-content",
    flexWrap: "nowrap",
  },
  question: {
    fontFamily: "MuseoSlab",
    "font-weight": 300,
    "font-size": "28px",
    "line-height": 1.2,
    "letter-spacing": "1px",
    "text-align": "center",
    color: "#01366c",
  },
  optionText: {
    maxWidth: (props: IOptionProps) =>
      props.maxWidth ? props.maxWidth : "250px",
    "font-family": "VarelaRound",
    "font-size": (props: IOptionProps) =>
      props.fontSize ? props.fontSize : "15px",
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.33,
    "letter-spacing": "normal",
    color: "#01366c",
    marginTop: "3px",
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: (props: IOptionProps) => (props.responsive ? 18 : undefined),
    },
  },
}));

export default function Option(props: IOptionProps) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      onClick={props.onSelect}
      className={classes.optionRoot}
      style={props.style}
    >
      <Grid item>
        <CircleCheckbox isSelected={props.isSelected}></CircleCheckbox>
      </Grid>
      <Grid item style={{marginBottom:'auto'}}>
        <Typography className={classes.optionText}>{props.label}</Typography>
      </Grid>
    </Grid>
  );
}
