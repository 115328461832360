import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Dialog from "./Dialog";
import SmallButton from "./SmallButton";
import DialogTextfield from './DialogTextfield';
import { IConfirmDialogProps } from "Interfaces/props/confirmDialogProps";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  subTitleText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
}));

export default function ConfirmDialog(props: IConfirmDialogProps) {
  const classes = useStyles();
  const [message, setMessage] = React.useState(""); 
  const handleSubmission = ()=>{
    props.onSubmit(message);
  }

  return (
    <Dialog {...props}>
      <Typography className={classes.titleText} style={{ marginTop: 15 }}>
        {props.titleText}
      </Typography>
      <Typography
        className={classes.subTitleText}
        style={{ marginTop: 15, width: 325, margin: "auto" }}
      >
        {props.subtext}
      </Typography>
      {props.showTextField &&
        (
          <DialogTextfield
            title=""
            value={message}
            onChange={(e)=>{
              setMessage(e.target.value);
            }}
            placeholder=""
          />
        )
      }
      <SmallButton
        color={props.submitButtonColor || "green"}
        style={{ marginTop: 25 }}
        onClick={handleSubmission}
      >
        {props.buttonText}
      </SmallButton>
      <SmallButton
        color="blue"
        style={{ marginTop: 5 }}
        onClick={props.handleClose}
      >
        {props.cancelButtonText || "CANCEL"}
      </SmallButton>
    </Dialog>
  );
}
