import { Grid, makeStyles } from "@material-ui/core";
import { IOrangeDialogBarProps } from "Interfaces/props/orangeDialogBoxProps";
import React from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";
import NextButton from "./NextButton";
import TipButton from "./TipButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  iconRoot: {
    width: 34,
    height: 34,
    cursor: "pointer",
  },
  middleIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    "-ms-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  hoverText: {
    fontFamily: "VarelaRound",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    marginTop: 25,
  },
}));

const OrangeDialogBar = (props: IOrangeDialogBarProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        {!props.isStart ? (
          <BackButton onClick={props.onBackward} />
        ) : (
          <div className={classes.iconRoot} style={{ cursor: "default" }} />
        )}
      </Grid>
      <Grid item style={{ position: "relative" }}>
        <TipButton
          onClick={() => {}}
          enableHover
          hoverContent={
            <p className={classes.hoverText}>
              <span style={{ fontSize: 15, lineHeight: 1.33 }}>Need help?</span>
              <br /> <br />
              Chat with Mrs. Rheology
              <br /> <br />
              <Link to="/chat">Chat Now</Link>
            </p>
          }
        />
      </Grid>
      <Grid item>
        {!props.isEnd ? (
          <NextButton onClick={props.onForward} isReady={props.isReady} />
        ) : (
          <div className={classes.iconRoot} style={{ cursor: "default" }} />
        )}
      </Grid>
    </Grid>
  );
};

export default OrangeDialogBar;
