import { makeStyles } from "@material-ui/core";
import Tip from "../Images/tip.png";
import TipHover from "../Images/tiphover.png";
import TipBubble from "../Images/tip-bubble.svg";
import { useState } from "react";
import { TipButtonProps } from "Interfaces/props/tipButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Tip})`,
    width: 24,
    height: 24,
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      backgroundImage: `url(${TipHover})`,
    },
  },
  hoverBubble: {
    backgroundImage: `url(${TipBubble})`,
    position: "absolute",
    width: 177,
    height: 145,
    margin: "auto",
    right: "-76px",
    top: "21px",
    cursor: "default",
    zIndex: 1002,
  },
}));

const TipButton = (props: TipButtonProps) => {
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  const toggleHover = () => {
    setHover(!hover);
  };

  return (
    <div
      className={classes.root}
      onClick={props.onClick}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      style={props.style}
    >
      {hover && props.enableHover ? (
        <div className={classes.hoverBubble}>{props.hoverContent}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TipButton;
