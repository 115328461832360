import { Grid } from "@material-ui/core";
import Ribbon from "Components/Ribbon";
import MessageTextField from "Components/MessageTextField";
import SmallButton from "Components/SmallButton";
import useStyles from "Views/Admin/styles";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import { projectApi } from "Utils/api/projectApi";
import { IApiState } from "Interfaces/apiState";
import { formatDateTimeForNotes } from "Utils/timeFormats";
import LogMessage from "../LogMessage";
import { useDispatch } from "react-redux";
import { addPublicNote } from "Redux/slices/projectsSlice";

const AddNotes = ({
  userId,
  projectId,
  moveToNextTab,
  hideSend,
  notes,
}: any) => {
  const classes = useStyles();
  const [placeholder, setPlaceHolder] = useState("Add any notes or comments about this project to send to the team.");
  const [apiState, setApiState] = useState<IApiState>({});
  const [emptyMessage, setEmptyMessage] = useState(false);
  const dispatch = useDispatch();

  const handleSubmission = (message: string) => {
    if (!message) {
      setEmptyMessage(true);
      return;
    }
    if (apiState.loading) {
      return;
    }
    setApiState({ loading: true, loaded: false, error: false });
    setPlaceHolder("Submitting.....");
    projectApi
      .addNotesApi({
        userId,
        projectId,
        body: message,
        isPrivate: false,
      })
      .then((response) => {
        setApiState({ loading: false, loaded: true, error: false });
        setPlaceHolder("Message added. Add another.....");
        dispatch(addPublicNote(response.data));
      })
      .catch(() => {
        setApiState({
          loading: false,
          loaded: false,
          error: true,
          errorMsg: "Something went wrong.Try again",
        });
        setPlaceHolder("Please try again.");
      });
  };

  const handlePublish = () => {
    if (apiState.loading) {
      return;
    }
    moveToNextTab(true);
  };
  return (
    <>
      <Ribbon
        className={classes.ribbonRoot}
        color="orange"
        text="The Customer Can See This!"
      />
      {notes
        ?.map(
          (note: {
            user: { id: string; name: string };
            body: string;
            created_at: string;
            id: string;
          }) => {
            return {
              self: userId === note.user.id,
              message: note.body,
              title: note.user.name,
              date: note.created_at,
              id: note.id,
            };
          }
        )
        .map(
          (note: {
            self: boolean;
            message: string;
            title: string;
            date: string;
            id: string;
          }) => {
            return (
              <LogMessage
                date={formatDateTimeForNotes(note.date)[0]}
                time={formatDateTimeForNotes(note.date)[1]}
                content={note.message}
                onDelete={() => {}}
                id={note.id}
                key={note.id}
                canDelete={false}
              />
            );
          }
        )}
      {apiState.error && (
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}>
          {apiState.errorMsg}
        </Typography>
      )}
      {apiState.loading && (
        <Typography className={classes.subHeaderText}>
          Submitting.....
        </Typography>
      )}
      <MessageTextField
        placeholder={placeholder}
        onSend={handleSubmission}
        className={classes.textfield}
        onChangeCallBack={() => {
          if (emptyMessage) {
            setEmptyMessage(false);
          }
        }}
        messageTextFieldWidth="75%"
      />
      {emptyMessage && (
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}>
          Cannot be empty!!
        </Typography>
      )}
      <Grid
        container
        direction="row"
        justify="flex-end"
        style={{ marginTop: 50 }}>
        {!hideSend && (
          <Grid item>
            <SmallButton color="green" onClick={handlePublish}>
              SEND
            </SmallButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AddNotes;
