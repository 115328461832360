import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../Utils/utils";
import { RootState } from "../store";
import { getUserGroup } from "./userSlice";
import { Project, ProjectState } from "Interfaces/project";

// Define the initial state using that type
const initialState: ProjectState = {
  projects: [],
  activeProject: {} as Project,
  saveCurrent: false,
};

export const fetchAllProjects = createAsyncThunk(
  "projects/fetchAllProjects",
  async (_x, thunkApi) => {
    const group = getUserGroup(thunkApi.getState() as any);
    //parse roles from state, set the projects url accordingly
    if (group === "Customer") {
      const response = await get("/customer/projects");
      return response.data.results;
    } else {
      //append all to the url string in the future
      const response = await get("/admin/projects");
      return response.data.results;
    }
  }
);

export const searchProjects = createAsyncThunk(
  "projects/searchProjects",
  async (x: { offset: number; query: string }, thunkApi) => {
    const group = getUserGroup(thunkApi.getState() as any);
    //parse roles from state, set the projects url accordingly
    if (group === "Customer") {
      const response = await get("/customer/projects", { params: x });
      // console.log(response.data);
      return response.data.results;
    } else {
      const response = await get("/admin/projects", { params: x });
      // console.log(response.data);
      return response.data.results;
    }
  }
);

export const fetchCustomersProjects = createAsyncThunk(
  "projects/fetchCustomersProjects",
  async (_x, thunkApi) => {
    thunkApi.getState();
    //parse roles from state, set the projects url accordingly
    const response = await get("/customer/projects");
    return response.data;
  }
);

export const fetchProject = createAsyncThunk(
  "projects/fetchProject",
  async (projectId: string) => {
    const response = await get("/admin/projects/" + projectId);
    return response.data;
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleSave: (state) => {
      state.saveCurrent = !state.saveCurrent;
    },
    addPublicMessage: (state, action) => {
      state.activeProject.public_messages = [
        ...state.activeProject.public_messages,
        action.payload,
      ];
    },
    updatePrivateMessage: (state, action) => {
      state.activeProject.private_notes = state.activeProject.private_notes.map(
        (note) => {
          if (note.id === action.payload.id) {
            return {
              ...note,
              body: action.payload.content,
            };
          }
          return note;
        }
      );
    },
    deletePrivateMessage: (state, action) => {
      state.activeProject.private_notes =
        state.activeProject.private_notes.filter((note) => {
          return note.id !== action.payload.id;
        });
    },
    addPrivateMessage: (state, action) => {
      state.activeProject.private_notes = [
        ...state.activeProject.private_notes,
        action.payload,
      ];
    },
    addPublicNote: (state, action) => {
      state.activeProject.public_notes = [
        ...state.activeProject.public_notes,
        action.payload,
      ];
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
      return state;
    },
    setActiveProject: (state, action) => {
      state.activeProject = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(fetchCustomersProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(searchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.activeProject = action.payload;
    });
  },
});

export const {
  toggleSave,
  addPublicMessage,
  updatePrivateMessage,
  deletePrivateMessage,
  addPrivateMessage,
  addPublicNote,
  setProjects,
} = projectsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getProjects = (state: RootState) => state.projects.projects;
export const getActiveProject = (state: RootState) =>
  state.projects.activeProject;
export const getSaveCurrent = (state: RootState) => state.projects.saveCurrent;

export default projectsSlice.reducer;
