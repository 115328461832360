import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Dialog from "./Dialog";
import SmallButton from "./SmallButton";
import { removeUser } from "Redux/slices/usersSlice";
import { useDispatch } from "react-redux";
import { userApi } from "Utils/api/userApi";
import { IDeleteUserDialogProps } from "Interfaces/props/deleteUserDialogProps";

const useStyles = makeStyles((theme) => ({
  headerText: {
    marginTop: 10,
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    marginTop: 15,
  },
}));

interface IApiStatus {
  error?: boolean;
  loading?: boolean;
  loaded?: boolean;
  errorMsg?: string;
}

export default function DeleteUserDialog(props: IDeleteUserDialogProps) {
  const classes = useStyles();
  const [apiStatus, setApiStatus] = React.useState<IApiStatus>({
    loading: false,
    loaded: false,
    error: false,
    errorMsg: "",
  });
  const dispatch = useDispatch();

  const handleFormSubmission = () => {
    setApiStatus({
      loading: true,
      loaded: false,
      error: false,
      errorMsg: "",
    });
    userApi
      .deleteUserApi(props.userToDelete)
      .then((response) => {
        setApiStatus({
          loading: false,
          loaded: true,
          error: false,
          errorMsg: "",
        });
        dispatch(removeUser(props.userToDelete));
      })
      .catch((err) => {
        const serverErrorMsg = err.response?.data[0];
        setApiStatus({
          loading: false,
          loaded: false,
          error: true,
          errorMsg: serverErrorMsg || "Something went wrong!!",
        });
      });
  };

  return (
    <Dialog open={props.open} handleClose={props.handleClose}>
      <Typography className={classes.headerText}>Delete user?</Typography>
      <Typography className={classes.subHeaderText}>
        Are you sure you want to delete this user?
      </Typography>
      {apiStatus.loading && (
        <Typography className={classes.subHeaderText}>Deleting.....</Typography>
      )}
      {apiStatus.error && (
        <Typography
          className={classes.subHeaderText}
          style={{ color: "#d0021b" }}
        >
          {apiStatus.errorMsg}
        </Typography>
      )}
      {apiStatus.loaded && (
        <Typography className={classes.subHeaderText}>
          User Deleted!!!!!
        </Typography>
      )}
      <SmallButton
        onClick={handleFormSubmission}
        color="red"
        style={{ marginTop: 35 }}
      >
        DELETE
      </SmallButton>
      <SmallButton
        onClick={props.handleClose}
        color="blue"
        style={{ marginTop: 10 }}
      >
        CANCEL
      </SmallButton>
    </Dialog>
  );
}
