import { makeStyles, Typography } from "@material-ui/core";
import { MultipleChoiceProps } from "Interfaces/props/dialogMultipleChoiceProps";
import React, { useEffect } from "react";
import { asyncSetReady } from "../Utils/utils";
import Option from "./MultipleChoiceOption";

const useStyles = makeStyles((theme) => ({
  question: {
    fontFamily: "MuseoSlab",
    "font-weight": 300,
    "font-size": "28px",
    "line-height": 1.2,
    "letter-spacing": "1px",
    "text-align": "center",
    color: "#01366c",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
}));

const MultipleChoice = (props: MultipleChoiceProps) => {
  const classes = useStyles();
  useEffect(() => {
    asyncSetReady(props);
    // eslint-disable-next-line
  }, []);

  const onSelect = (idx: number) => {
    if (props.required) {
      props.onSelect(idx);
    } else {
      //If this component has optional selections allows the user to unselect option
      if (props.selectedOption === idx) props.onSelect(-1);
      else props.onSelect(idx);
    }
    if (props.setReady) props.setReady(true, props.idx); //Set this component as ready, idx is passed down to keep track of what position this child is in the potentially multiple inputs on the slide
  };

  return (
    <div>
      <Typography className={classes.question}>{props.title}</Typography>
      {props.options
        ? props.options.map((option, idx) => {
            return (
              <Option
                key={idx}
                isSelected={props.selectedOption === idx}
                onSelect={() => {
                  onSelect(idx);
                }}
                label={option}
              />
            );
          })
        : null}
    </div>
  );
};

export default MultipleChoice;
