import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import ProjectsGrid from "../Components/ProjectsGrid";
import { get, headerCss, hideMobile, patch, showMobile } from "../Utils/utils";
import BlueColumnHeaders from "../Components/BlueColumnHeaders";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import {
  fetchCustomersProjects,
  fetchProject,
  getProjects} from "../Redux/slices/projectsSlice";
import {
  getPrivacyPolicyAccepted,
  getUserId,
} from "../Redux/slices/userSlice";
import AccountDetails from "../Components/AccountDetails";
import NewProjectButton from "../Components/NewProjectButton";
import { IUserInfo } from "Interfaces/user";
import { IAllProjectsViewProps } from "Interfaces/props/dashboardProps";


const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  bodyText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  panelRoot: { flex: 1, overflowY: "auto", marginTop: 25 },
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
  },
  rightPanel: { width: "50%", padding: 25 },
  createText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#01366c",
    marginRight: 10,
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  centeredTitleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: "#59cee7",
    marginTop: 40,
  },
  dateText: {
    fontFamily: "VarelaRound",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#f79730",
  },
  centeredSubText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#59cee7",
    marginTop: 10,
  },
  notesText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#01366c",
    width: 400,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  showMobile: showMobile(theme) as any,
  hideMobile: hideMobile(theme) as any,
  mobileHeaderText: {
    marginBottom: 25,
  },
  marginRoot: { marginTop: 25, marginBottom: 25 },
}));


const AllProjectsView = (props: IAllProjectsViewProps) => {
  const [mobileNav, setMobileNav] = React.useState(0);
  const [userProfile, setUserProfile] = React.useState<Partial<IUserInfo>>({});
  const classes = useStyles();
  const params = useParams();
  const projects = useAppSelector(getProjects);
  const isEmpty = (params as any).stage === "empty";

  const refreshCustomerProfile = async () => {
    let response = await get("/customers/me");
    setUserProfile(response.data);
  };
  const saveField = (dbField: any, newVal: string) => {
    const newProfile: any = {};
    if (dbField.includes(".")) {
      let dbFields: any = dbField.split(".");
      newProfile[dbFields[1]] = newVal;
    } else {
      newProfile[dbField] = newVal;
    }
    let results = patch("/customers/" + userProfile.id, newProfile).then(() => {
      refreshCustomerProfile();
    });
    console.log(results);
  };
  React.useEffect(() => {
    refreshCustomerProfile();
  }, []);

  let leftPanel = (
    <AccountDetails />
  );

  let rightPanel = (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        style={{ marginBottom: 25 }}
      >
        <Grid item style={{ width: 183 }} className={classes.hideMobile}></Grid>
        <Grid item>
          <Typography className={classes.subHeaderText}>My Projects</Typography>
        </Grid>
        <NewProjectButton onClick={() => {}} />
      </Grid>
      <Grid item>
        <ProjectsGrid
          projects={isEmpty ? [{ stage: "empty" }] : (projects as any)}
          onClick={props.onClick}
        />
      </Grid>
    </>
  );
  return (
    <Grid
      container
      className={classes.root}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item style={{ margin: "auto", marginBottom: 25 }}>
        <Typography className={classes.headerText}>
          My Profile & Projects
        </Typography>
      </Grid>
      <Grid container className={classes.showMobile}>
        <BlueColumnHeaders
          labels={["Account Details", "My Projects"]}
          selectedIdx={mobileNav}
          onClick={(idx: number) => {
            setMobileNav(idx);
          }}
          small
          className={classes.mobileHeaderText}
        />
        {mobileNav === 0 ? leftPanel : rightPanel}
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginBottom: 25 }}
        className={classes.hideMobile}
      >
        <Grid item className={classes.leftPanel}>
          {leftPanel}
        </Grid>
        <Grid item className={classes.rightPanel}>
          {rightPanel}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function Dashboard() {
  const isPrivacyPolicyAccepted = useAppSelector(getPrivacyPolicyAccepted);
  const [stage, setStage] = React.useState<number>(0);
  const [selectedProjectIdx, setSelectedProjectIdx] = React.useState(-1);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(getProjects);

  const viewProject = (selectedId: number) => {
    setSelectedProjectIdx(selectedId);
  };

  React.useEffect(() => {
    if (selectedProjectIdx < 0) return;
    let projectId = projects[selectedProjectIdx].id;
    dispatch(fetchProject(projectId)).then(() => {
      history.push(`/customer/projects/${projectId}`);
    });
  }, [dispatch, projects, selectedProjectIdx]);

  React.useEffect(() => {
    dispatch(fetchCustomersProjects());
  }, [dispatch]);

  React.useEffect(() => {
    if (isPrivacyPolicyAccepted) {
      setStage(1);
    }
  }, [dispatch, isPrivacyPolicyAccepted]);

  return <AllProjectsView onClick={viewProject} />;
}
