import { get, post } from 'Utils/utils';

const labApi = {
  createLabApi: (name: string) => {
    return post('/labs', {
      name: name,
    });
  },
  fetchLabsApi: () => get('/labs'),
};

export default labApi;
