import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { projectApi } from "Utils/api/projectApi";
import NarrowTextfield from "./NarrowTextfield";
import NarrowSelect from "./NarrowSelect";
import labApi from "../Utils/api/labApi";
import {
  ILab,
  ITechnicalAssessmentProps,
} from "Interfaces/props/technicalAssessmentProps";
import { IOptionType } from "Interfaces/props/narrowSelectProps";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  assessmentText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 35,
  },
}));

export default function TechnicalAssessment(props: ITechnicalAssessmentProps) {
  const [eta, setEta] = React.useState(props.eta !== undefined ? String(props.eta) : '');
  const [probability, setProbability] = React.useState(props.probability !== undefined ? String(props.probability) : '');
  const [labs, setLabs] = React.useState<ILab[]>([]);
  const [selectedLab, setSelectedLab] = React.useState<any>();
  const [updating, setUpdating] = React.useState(false);
  const classes = useStyles();
  const id = props.id;

  React.useEffect(() => {
    const labId = props.lab?.id || Number(localStorage.getItem("labId"));

    props.setCAErrors(
      !eta ||
      !eta.length ||
      !probability ||
      !eta.length ||
      !(labId && selectedLab && selectedLab.length)
    );
  }, [selectedLab, eta, probability, props]);

  React.useEffect(() => {
    // setEta(props.eta || -1);

    setEta(props.eta !== undefined ? String(props.eta) : '');
    setProbability(props.probability !== undefined ? String(props.probability) : '');
  }, [props.id, props.eta, props.probability]);

  React.useEffect(() => {
    labApi.fetchLabsApi().then((response) => {
      setLabs(response.data);
      const labId = localStorage.getItem("labId");
      const selectLab = response.data.filter(
        (lab: any) => String(lab.id) === String(labId)
      )

      setSelectedLab(
        selectLab
      );

    });

  }, []);
  // technical_assessment: {
  //   lab_eta_in_days: e !== -1 ? e : 0,
  //   success_probability: p !== -1 ? p : 0,
  // }

  const performUpdate = (field: string, value: number) => {
    setUpdating(true);
    projectApi
      .updateTechnical(id, {
        technical_assessment: {
          [field]: value
        },
      })
      .then(() => {
        props.updateProjectTA(field, value);
        setUpdating(false);
      });
  };

  const labOptions: IOptionType[] = labs.map((lab) => ({
    label: lab.name,
    value: lab.id,
    selected: lab.id === props.lab?.id,
  }));

  const removeNonNumeric = (string: string) => string.replace(/\D/g, '');

  return (
    <Grid direction="column">
      <Typography className={classes.assessmentText}>
        Technical Assessment by Lab
      </Typography>
      {updating && <Typography>Wait Updating...</Typography>}
      <Grid container direction="column">
        <Grid container direction="row">
          <Grid item style={{ marginRight: 30, width: "100%" }}>
            <Typography className={classes.titleText}>
              Lab {props.editable ? "(*)" : ""}
            </Typography>
            {props.editable ? (
              <NarrowSelect
                width="100%"
                style={{ marginTop: 10 }}
                options={labOptions}
                default="Choose one"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const _labId = event.target.value;
                  localStorage.setItem("labId", _labId);
                  const labIdValue = localStorage.getItem("labId");
                  if (labIdValue) {
                    console.log("labId value inside if statement " + labIdValue)
                    projectApi.updateProjectLab({
                      projectId: id,
                      labId: labIdValue,
                    });
                    setSelectedLab(
                      labs.filter(
                        (lab: any) => String(lab.id) === String(_labId)
                      )
                    );
                  }
                }}
              />
            ) : (
              <Typography className={classes.subText}>
                {selectedLab ? selectedLab[0]?.name : "Not selected"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.titleText}>
        Probability of Technical Success {props.editable ? "(*)" : ""}
      </Typography>
      {props.editable ? (
        <NarrowTextfield
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProbability(event.target.value);
          }}
          onBlur={() => {
            const asNumberString = removeNonNumeric(probability);
            if (!asNumberString.length) return;

            performUpdate('success_probability', Number(asNumberString));
            setProbability(asNumberString);
          }}
          value={probability}
        />
      ) : (
        <Typography className={classes.subText}>
          {props.probability === -1 || !props.probability
            ? "Not Yet Set"
            : props.probability + "%"}
        </Typography>
      )}
      <Typography className={classes.titleText}>
        Estimated Chemist Hours To Complete {props.editable ? "(*)" : ""}
      </Typography>
      {props.editable ? (
        <NarrowTextfield
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEta(event.target.value)
          }}
          value={eta}
          onBlur={() => {
            const asNumberString = removeNonNumeric(eta);
            if (!asNumberString.length) return;

            performUpdate('lab_eta_in_days', Number(asNumberString));
            setEta(asNumberString);
          }}
        />
      ) : (
        <Typography className={classes.subText}>
          {!props.eta
            ? "Not Yet Set"
            : props.eta + " Hours"}
        </Typography>
      )}
      <Typography className={classes.titleText}>Completed by:</Typography>
      <Typography className={classes.subText}>{props.chemist}</Typography>
    </Grid>
  );
}
