import { Grid } from "@material-ui/core";
import { IProjectsGridProps } from "Interfaces/props/projectGridProps";
import * as React from "react";
import ProjectsTile from "./ProjectsTile";

export default function ProjectsGrid(props: IProjectsGridProps) {
  React.useEffect(() => {
    console.log("projects", props.projects);
     props.projects.map((project) => {
       console.log("project stage", project.stage);
     }) 
  });
  return (
    <Grid container direction="row" justify="center">
      {props.projects.length === 0 ? (
        <ProjectsTile stage="empty" />
      ) : (
        props.projects.map((project, idx) => {
          return (
            <ProjectsTile
              key={project.id}
              {...project}
              onClick={() => {
                props.onClick(idx);
              }}
            />
          );
        })
      )}
    </Grid>
  );
}
