import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import * as React from "react";
// import { useDispatch } from "react-redux";
import AddButton from "../Components/AddButton";
import BlueColumnHeaders from "../Components/BlueColumnHeaders";
import CreateUserDialog from "../Components/CreateUserDialog";
import DeleteUserDialog from "../Components/DeleteUserDialog";
import { ReactComponent as AccountIcon } from "../Images/account-mini.svg";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import {
  getUsers,
  fetchAllUsers,
  getFetchUserApiStatus,
  clear,
  setUsers,
} from "../Redux/slices/usersSlice";
import { headerCss, hideMobile, showMobile } from "../Utils/utils";

const cell = { width: 237, height: 50 };

const useStyles = makeStyles((theme) => ({
  root: {
    ...(headerCss(theme) as any),
    width: " 100%",
  },
  columnHeader: {
    margin: "auto",
    marginTop: 25,
    width: 951,
  },
  tableBody: {
    margin: "auto",
    width: "max-content",
    overflow: "auto",
    paddingBottom: 25,
  },
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
    margin: "auto",
  },
  createText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#01366c",
    marginRight: 10,
  },
  leftCell: { ...cell, border: "1px dashed #01366c", borderLeft: "inherit" },
  middleCell: { ...cell, border: "1px dashed #01366c" },
  rightCell: { ...cell, border: "1px dashed #01366c", borderRight: "inherit" },
  cellText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
  mobileRowItem: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "auto",
    },
  },
  mobileTable: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
}));

export default function UserManagement() {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const users = useAppSelector(getUsers);
  const fetchUserApiStatus = useAppSelector(getFetchUserApiStatus);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = React.useState("");
  const [sortDir, setSortDir] = React.useState(1);

  const openCreate = () => {
    setCreateOpen(true);
  };

  const closeCreate = () => {
    setCreateOpen(false);
  };

  const openDelete = (userToDelete: string) => {
    setSelectedUser(userToDelete);
    setDeleteOpen(true);
  };

  const closeDelete = () => {
    setDeleteOpen(false);
  };

  React.useEffect(() => {
    dispatch(fetchAllUsers());
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <>
      {createOpen && (
        <CreateUserDialog
          open={createOpen}
          handleClose={closeCreate}
          onCreate={() => {}}
        />
      )}
      {deleteOpen && (
        <DeleteUserDialog
          open={deleteOpen}
          handleClose={closeDelete}
          userToDelete={selectedUser}
        />
      )}
      <Grid container className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ width: "95%", margin: "auto" }}>
          <Grid
            item
            style={{ width: 165 }}
            className={classes.mobileRowItem}></Grid>
          <Grid item className={classes.mobileRowItem}>
            <Typography className={classes.headerText}>Users</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ width: "max-content" }}
            className={classes.mobileRowItem}>
            <Typography className={classes.createText}>
              Create New User
            </Typography>
            <AddButton onClick={openCreate} style={{ width: "max-content" }} />
          </Grid>
        </Grid>
        <Grid item /*style={{ flex: 1 }}*/ className={classes.mobileTable}>
          <div
            style={{ paddingRight: 25, paddingLeft: 25, width: "max-content" }}>
            <BlueColumnHeaders
              labels={["Name", "Email", "Role", "Reports To"]}
              onClick={(idx) => {
                if (idx === 0) {
                  const sortedUser = [...users].sort((a, b) =>
                    a.name > b.name
                      ? sortDir
                      : b.name > a.name
                      ? -1 * sortDir
                      : 0
                  );
                  setSortDir(-1 * sortDir);
                  dispatch(setUsers(sortedUser));
                }
              }}
              className={classes.columnHeader}
            />
            <Grid container direction="column" className={classes.tableBody}>
              {fetchUserApiStatus?.loaded &&
                users.map((user, idx) => {
                  let isFirst = false;
                  let isLast = false;
                  switch (idx) {
                    case 0:
                      isFirst = true;
                      break;
                    case users.length - 1:
                      isLast = true;
                      break;
                    default:
                      break;
                  }
                  return (
                    <Grid
                      key={idx}
                      container
                      direction="row"
                      style={{ overflow: "visible" }}>
                      <Grid
                        item
                        className={classes.leftCell}
                        style={{
                          borderTop: isFirst ? "inherit" : undefined,
                          borderBottom: isLast ? "inherit" : undefined,
                          position: "relative",
                        }}
                        container
                        alignItems="center"
                        justify="center">
                        <SvgIcon
                          viewBox="0 0 20 20"
                          style={{ left: 0, position: "absolute" }}>
                          <AccountIcon />
                        </SvgIcon>
                        <Typography className={classes.cellText}>
                          {user.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.middleCell}
                        style={{
                          borderTop: isFirst ? "inherit" : undefined,
                          borderBottom: isLast ? "inherit" : undefined,
                          overflowX: "scroll",
                        }}
                        container
                        alignItems="center"
                        justify="center">
                        <Typography className={classes.cellText}>
                          {user.email}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={classes.middleCell}
                        style={{
                          borderTop: isFirst ? "inherit" : undefined,
                          borderBottom: isLast ? "inherit" : undefined,
                        }}
                        container
                        alignItems="center"
                        justify="center">
                        <Typography className={classes.cellText}>
                          {user.profile_type}
                        </Typography>
                      </Grid>
                      <Grid
                        className={classes.rightCell}
                        style={{
                          borderTop: isFirst ? "inherit" : undefined,
                          borderBottom: isLast ? "inherit" : undefined,
                          position: "relative",
                        }}
                        container
                        alignItems="center"
                        justify="center">
                        <Typography className={classes.cellText}>
                          {user.reports_to}
                        </Typography>
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            color: "#01366c",
                            cursor: "pointer",
                          }}
                          onClick={() => openDelete(user.id)}>
                          <DeleteOutline />
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
