import { Grid, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import SmallButton from "Components/SmallButton";
import TipButton from "Components/TipButton";
import NarrowTextfield from "Components/NarrowTextfield";
import AddButton from "Components/AddButton";
import Option from "Components/MultipleChoiceOption";
import { EditIcon } from "Views/Admin//Icons";
import BubbleMenu from "Views/Admin/BubbleMenu";
import TopHeader from "Views/Admin//TopHeader";
import useStyles from "Views/Admin/styles";
import fetchMilestones from "Utils/api/milestones/fetchMilestones";
import camelCaseKeys from "camelcase-keys";
import editMileStoneApi from "Utils/api/milestones/editMileStones";
import SaveButton from "Components/SaveButton";
import updateProjectStep from "Utils/api/updateProjectStep";
import { formatDate } from "Utils/utils";
import { IApiState } from "Interfaces/apiState";
import { Imilestone } from "Interfaces/milestone";
import { useDispatch } from "react-redux";
import { fetchProject } from "../../../Redux/slices/projectsSlice";

const ManageSubTasks = ({
  dialog: {
    setDialogOpen,
    setDialogSubTitle,
    setDialogTitle,
    setDialogButton,
    setDialogCancelButtonText,
    setDialogSubmitEvent,
    setDialogCancelEvent,
  },
  project,
  userName,
  moveToNextTab,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [milestones, setMilestones] = React.useState<Imilestone[]>([]);
  const [apiState, setApiState] = React.useState<IApiState>({});

  const [newTaskName, setNewTaskName] = React.useState("");
  const [newTaskCompletionDate, setNewTaskCompletionDate] =
    React.useState<Date | null>(null);

  const [editIdx, setEditIdx] = React.useState(-1);
  const [taskEditIdx, setTaskEditIdx] = React.useState(-1);
  const [mileStoneTaskIdx, setMileStoneTaskIdx] = React.useState(-1);

  const [taskEditDescription, setTaskEditDescription] = React.useState("");
  const [taskEditCompletionDate, setTaskEditCompletionDate] =
    React.useState("");
  const [editFormValidation, setEditFormValidation] = React.useState({
    date: "",
    name: "",
  });
  const [createFormValidation, setCreateFormValidation] = React.useState({
    date: "",
    name: "",
  });

  const createTask = (milestoneIdx: number) => {
    let validation = true;
    let validationError = { name: "", date: "" };
    if (!newTaskName) {
      validationError = { ...validationError, name: "Name is required!!" };
      validation = false;
    }
    if (!newTaskCompletionDate) {
      validationError = { ...validationError, date: "Date is required!!" };
      validation = false;
    }
    if (!validation) {
      setCreateFormValidation(validationError);
      return;
    }
    let currentTasks = [...(milestones[milestoneIdx].subTasks || [])];
    setApiState({ loading: true });
    editMileStoneApi({
      id: milestones[milestoneIdx].id,
      sub_tasks_updated: true,
      subTasks: [
        ...currentTasks,
        {
          description: newTaskName,
          isSubTask: true,
          isPublished: false,
          completionDate: newTaskCompletionDate,
          position: currentTasks.length,
          isComplete: false,
        },
      ],
    })
      .then((response) => {
        const updatedMileStones = milestones.map((value, index) => {
          if (index !== milestoneIdx) {
            return value;
          }
          const updatedMilesStone = camelCaseKeys(response.data);
          const subTasks = updatedMilesStone.subTasks?.map((value: any) =>
            camelCaseKeys(value)
          );
          return {
            ...updatedMilesStone,
            subTasks,
          };
        });
        setMilestones(updatedMileStones);
        setNewTaskName("");
        setNewTaskCompletionDate(null);
        setEditIdx(-1);
        setApiState({ loaded: true });
      })
      .catch(() => {
        setApiState({ error: true });
      });
  };

  const deleteTask = (milestoneIdx: number, subTaskIdx: number) => {
    const updatedTasks = milestones[milestoneIdx].subTasks?.filter(
      (subTask, index) => index !== subTaskIdx
    );

    editMileStoneApi({
      id: milestones[milestoneIdx].id,
      subTasks: updatedTasks,
      sub_tasks_updated: true,
    }).then((response) => {
      const updatedMileStones = milestones.map((value, index) => {
        if (index !== milestoneIdx) {
          return value;
        }
        const updatedMilesStone = camelCaseKeys(response.data);
        const subTasks = updatedMilesStone.subTasks?.map((value: any) =>
          camelCaseKeys(value)
        );
        return {
          ...updatedMilesStone,
          subTasks,
        };
      });
      setMilestones(updatedMileStones);
    });
  };

  const toggleMilestoneCompletion = (
    milestoneIdx: number,
    isComplete: boolean
  ) => {
    let updatedTasks;
    if (isComplete) {
      updatedTasks = milestones[milestoneIdx].subTasks?.map((subTask) => {
        return {
          ...subTask,
          isComplete: true,
        };
      });
    }
    editMileStoneApi({
      id: milestones[milestoneIdx].id,
      isComplete,
      subTasks: updatedTasks,
    }).then((response) => {
      const updatedMileStones = milestones.map((value, index) => {
        if (index !== milestoneIdx) {
          return value;
        }
        const updatedMilesStone = camelCaseKeys(response.data);
        const subTasks = updatedMilesStone.subTasks?.map((value: any) =>
          camelCaseKeys(value)
        );
        return {
          ...updatedMilesStone,
          subTasks,
        };
      });
      setMilestones(updatedMileStones);
    });
  };

  const handleEdit = (
    milestoneIdx: number,
    subTaskIdx: number,
    completionDateToUpdate: string,
    descriptionToUpdate: string,
    isComplete: boolean
  ) => {
    let validation = true;
    let validationError = { name: "", date: "" };
    if (!descriptionToUpdate) {
      validationError = { ...validationError, name: "Name is required!!" };
      validation = false;
    }
    if (!completionDateToUpdate) {
      validationError = { ...validationError, date: "Date is required!!" };
      validation = false;
    }
    if (!validation) {
      setEditFormValidation(validationError);
      return;
    }
    const updatedTasks = milestones[milestoneIdx].subTasks?.map(
      (subTask, index) => {
        if (index !== subTaskIdx) {
          return subTask;
        }
        return {
          ...subTask,
          completionDate: completionDateToUpdate,
          description: descriptionToUpdate,
          isComplete,
        };
      }
    );

    setApiState({ loading: true });
    editMileStoneApi({
      id: milestones[milestoneIdx].id,
      subTasks: updatedTasks,
      sub_tasks_updated: true,
    })
      .then((response) => {
        setApiState({ loaded: true });
        const updatedMileStones = milestones.map((value, index) => {
          if (index !== milestoneIdx) {
            return value;
          }
          const updatedMilesStone = camelCaseKeys(response.data);
          const subTasks = updatedMilesStone.subTasks?.map((value: any) =>
            camelCaseKeys(value)
          );
          return {
            ...updatedMilesStone,
            subTasks,
          };
        });
        setMilestones(updatedMileStones);
        setTaskEditIdx(-1);
        setMileStoneTaskIdx(-1);
        setTaskEditCompletionDate("");
        setTaskEditDescription("");
      })
      .catch(() => {
        setApiState({ error: true });
      });
  };

  const handlePublish = () => () => {
    updateProjectStep({
      projectId: project.id,
      stepsPublished: true,
    }).then(() => {
      setDialogOpen(false);
    });
  };

  const openListDialog = () => {
    setDialogOpen(true);
    setDialogTitle("Send List?");
    setDialogSubTitle(
      "Clicking send will email the project checklist to the customer."
    );
    setDialogButton("SEND");
    setDialogCancelButtonText("Do Not Send");
    setDialogSubmitEvent(handlePublish);
    setDialogCancelEvent(handleClose);
  };

  const handleComplete = () => () => {
    updateProjectStep({
      projectId: project.id,
      projectStatus: 3,
    }).then(() => {
      setDialogOpen(false);
      dispatch(fetchProject(project.id));
      moveToNextTab();
    });
  };
  const markAsCompleteDialog = () => {
    setDialogOpen(true);
    setDialogTitle("Mark as complete?");
    setDialogSubTitle("Clicking Ok will move the project to next phase.");
    setDialogButton("OK");
    setDialogCancelButtonText("Cancel");
    setDialogSubmitEvent(handleComplete);
    setDialogCancelEvent(handleClose);
  };

  const handleClose = () => () => {
    setDialogOpen(false);
  };

  React.useEffect(() => {
    fetchMilestones(project.id).then((response) => {
      const responseData = response.data.map((milestone: any) => {
        let updatedMilesStone = camelCaseKeys(milestone);
        const subTasks = updatedMilesStone.subTasks?.map((value: any) =>
          camelCaseKeys(value)
        );
        return {
          ...updatedMilesStone,
          subTasks,
        };
      });
      setMilestones(responseData);
    });
  }, []);

  const mileStonesCompleted = (): boolean => {
    let res = true;
    milestones.forEach((mileStone) => {
      if (!mileStone.isComplete) {
        res = false;
      } else {
        mileStone.subTasks?.forEach((subTask) => {
          if (!subTask.isComplete) {
            res = false;
          }
        });
      }
    });
    return res;
  };

  return (
    <>
      <TopHeader customerName={project.customer_name} userName={userName} />
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Typography className={classes.titleText}>
          Project Milestones
        </Typography>
        <TipButton onClick={() => {}} style={{ marginLeft: 15 }} />
        {apiState.error && (
          <Typography style={{ color: "#d0021b" }}>
            Something went wrong!!
          </Typography>
        )}
        {apiState.loading && <Typography>Submitting.....</Typography>}
      </Grid>
      {milestones.map((milestone, milestoneIdx) => {
        return (
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{ marginTop: 15 }}>
              <Grid item>
                <Option
                  label={milestone.description}
                  isSelected={milestone.isComplete}
                  onSelect={() => {
                    toggleMilestoneCompletion(
                      milestoneIdx,
                      !milestone.isComplete
                    );
                  }}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ width: "max-content" }}>
                <Typography className={classes.orangeText}>
                  {formatDate(milestone.completionDate)}
                </Typography>
                <EditIcon
                  onClick={() => {
                    if (milestoneIdx === editIdx) {
                      setEditIdx(-1);
                      return;
                    }
                    setEditIdx(milestoneIdx);
                  }}
                />
              </Grid>
            </Grid>
            {milestone.subTasks?.map((task, taskIdx) => {
              return (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{ paddingLeft: 25 }}>
                  {taskEditIdx === taskIdx &&
                  milestoneIdx === mileStoneTaskIdx ? (
                    <>
                      <Grid item>
                        <NarrowTextfield
                          width={"auto"}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (editFormValidation.name) {
                              setEditFormValidation({
                                name: "",
                                date: "",
                              });
                            }
                            setTaskEditDescription(event.target.value);
                          }}
                          value={taskEditDescription}
                          placeholder="Milestone Name"
                        />
                        <Typography style={{ color: "#d0021b" }}>
                          {editFormValidation.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" justify="flex-end">
                          <Grid item>
                            <TextField
                              id="editingdate"
                              label="End Date"
                              type="date"
                              defaultValue={taskEditCompletionDate}
                              onChange={(e) => {
                                if (editFormValidation.date) {
                                  setEditFormValidation({
                                    name: "",
                                    date: "",
                                  });
                                }
                                setTaskEditCompletionDate(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography style={{ color: "#d0021b" }}>
                              {editFormValidation.date}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              marginTop: "auto",
                            }}
                            onClick={() => {
                              handleEdit(
                                milestoneIdx,
                                taskIdx,
                                taskEditCompletionDate,
                                taskEditDescription,
                                task.isComplete
                              );
                            }}>
                            <SaveButton onClick={() => {}} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Option
                          label={task.description}
                          isSelected={task.isComplete}
                          onSelect={() => {
                            handleEdit(
                              milestoneIdx,
                              taskIdx,
                              task.completionDate,
                              task.description,
                              !task.isComplete
                            );
                          }}
                          fontSize={12}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" justify="flex-end">
                          <Typography className={classes.orangeText}>
                            {formatDate(task.completionDate)}
                          </Typography>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            style={{
                              width: "max-content",
                            }}>
                            <BubbleMenu
                              onEdit={() => {
                                setTaskEditDescription(task.description);
                                setTaskEditCompletionDate(
                                  task.completionDate.split("T")[0]
                                );
                                setTaskEditIdx(taskIdx);
                                setMileStoneTaskIdx(milestoneIdx);
                              }}
                              onDelete={() => deleteTask(milestoneIdx, taskIdx)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
            {editIdx === milestoneIdx ? (
              <>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{ marginTop: 15 }}>
                  <Grid item>
                    <NarrowTextfield
                      width={"auto"}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (createFormValidation.name) {
                          setCreateFormValidation({ name: "", date: "" });
                        }
                        setNewTaskName(event.target.value);
                      }}
                      value={newTaskName}
                      placeholder="Task Name"
                    />
                    <Typography style={{ color: "#d0021b" }}>
                      {createFormValidation.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="date"
                      label="End Date"
                      type="date"
                      //className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={newTaskCompletionDate}
                      onChange={(e) => {
                        if (createFormValidation.date) {
                          setCreateFormValidation({ name: "", date: "" });
                        }
                        setNewTaskCompletionDate(new Date(e.target.value));
                      }}
                    />
                    <Typography style={{ color: "#d0021b" }}>
                      {createFormValidation.date}
                    </Typography>
                  </Grid>
                </Grid>
                <AddButton
                  onClick={() => {
                    createTask(milestoneIdx);
                  }}
                  label="Add New Task"
                  style={{
                    marginTop: 10,
                    paddingLeft: 25,
                  }}
                  size={20}
                  fontSize={12}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        );
      })}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 100 }}>
        <Grid container justify="flex-end" style={{ marginLeft: "auto" }}>
          <SmallButton onClick={openListDialog} color="green">
            {mileStonesCompleted() ? "Update " : "PUBLISH"}
          </SmallButton>
          {mileStonesCompleted() && (
            <SmallButton color="blue" onClick={markAsCompleteDialog}>
              Complete
            </SmallButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ManageSubTasks;
