import { init } from "@livechat/customer-sdk";
import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import CaptionButton from "../Components/CaptionButton";
import ChatBox from "../Components/ChatBox";
import { ReactComponent as RocketIcon } from "../Images/rocket.svg";
import { useAppSelector } from "../Redux/hooks";
import { getUserEmail, getUserName } from "../Redux/slices/userSlice";
import { formatDate, headerCss } from "../Utils/utils";
import BackgroundVideo from "../Videos/msepiderma_loop.mp4";

let sdk: any;
const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "auto",
    width: "auto",
    minWidth: "100%",
    minHeight: "100%",
    backgroundColor: "white",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

let userArray: any[] = [];
let chatId: string, userId: string;

export default function Livechat() {
  const classes = useStyles();
  const customerName = useAppSelector(getUserName);
  const customerEmail = useAppSelector(getUserEmail);
  const [users, setUsers] = useState(Array());

  const [active, setActive] = useState(false);
  const [messages, setMessages] = useState(Array());
  const [loading, setLoading] = useState(true);
  const [hasChatId, setHasChatId] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let licenseId = process.env.REACT_APP_LIVECHAT_LICENSE_ID;
    let clientId = process.env.REACT_APP_LIVECHAT_CLIENT_ID;

    sdk = init({
      licenseId: licenseId ? parseInt(licenseId) : 0,
      clientId: clientId,
    });

    sdk.auth
      .getToken()
      .then((token: any) => {
        console.log("token: ", token);
      })
      .catch((error: any) => {
        console.log("getting token error ", error);
      });

    //Get the customer id from the sdk
    sdk.on("customer_id", (id: string) => {
      userId = id;
    });

    //Listen to the connected sdk event
    sdk.on("connected", (payload: Object) => {
      //Update the customer into with LiveChat
      sdk.updateCustomer({ name: customerName, email: customerEmail });

      //List all the chats if there are any
      sdk
        .listChats()
        .then((chatObj: any) => {
          const { chatsSummary, totalChats } = chatObj;
          setLoading(false);
          if (chatId) {
            return;
          }
          if (totalChats === 0) {
            return;
          }
          chatId = chatsSummary[0].id;
          setHasChatId(true);
          setActive(chatsSummary[0].active);
        })
        .catch((err: any) => {
          console.log("Erorr while listing chat history=====", err);
        });
    });

    //Listen to the user_data event and push users data into an array
    sdk.on("user_data", (user: any) => {
      userArray.push(user);
      if (userArray.length >= 2) {
        setUsers(userArray);
      }
    });

    //Listen for the incoming chat event and append messages to the existing array
    sdk.on("incoming_chat", ({ chat }: any) => {
      handleChatStart(chat);
    });

    //Listen to the chat deactivated event and show start new chat button
    sdk.on("chat_deactivated", () => {
      setActive(false);
    });

    //Listen to the disconnected event and show start new chat button
    sdk.on("disconnected", (reason: any) => {
      setActive(false);
    });

    //Listen for the incoming message event and append messages to the existing array
    sdk.on("incoming_event", ({ event }: any) => {
      if (!chatId || event.type !== "message") {
        return;
      }
      const author: any = userArray.filter(
        (obj) => obj.id === event.authorId
      )[0];
      let messageObj = getMessageObj(
        event.id,
        event.text,
        isAgent(author) ? false : true,
        author.name,
        getTime(event.createdAt)
      );
      setMessages((oldMessages) => [...oldMessages, messageObj]);
      scrollToBottom();
    });
  }, []);

  //Cleanup all the resources on unmount
  useEffect(() => {
    return () => {
      userArray = [];
      chatId = "";
      sdk.disconnect();
    };
  }, []);

  const getMessageObj = (
    id: string,
    message: string,
    self: boolean,
    title: string,
    date: string
  ) => {
    return {
      id,
      message,
      self,
      title,
      date,
    };
  };

  //Convert ISO Date string to the custom date format
  const getTime = (timeString: string) => formatDate(timeString);

  useEffect(() => {
    if (users.length >= 2 && hasChatId) {
      //Load chat history promise
      loadInitialHistory().then(() => {});
    }
  }, [hasChatId]);

  const loadInitialHistory = () => {
    let history: any = sdk.getChatHistory({ chatId });
    const loadLatestHistory = () =>
      loadHistory(chatId, history).then(() =>
        console.log("mylogs history loaded")
      );
    return loadLatestHistory()
      .catch(() => loadLatestHistory())
      .catch((error: any) => {
        console.log("here error", error);
      });
  };

  //Scroll to bottom when all the messages are loaded or new message is there
  const scrollToBottom = () => {
    let objDiv: any = document.getElementById("chatContainer");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  //Load message history
  const loadHistory = (chat: string, history: any) => {
    return new Promise((resolve, reject) => {
      history.next().then(
        (historyObj: any) => {
          const {
            value: { threads },
          } = historyObj;
          if (!threads) {
            return;
          }
          const messages: any = getMessagesFromThreads(threads);
          setMessages(messages);
          scrollToBottom();
          resolve({});
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  };

  //Filter through threads and return messages array
  const getMessagesFromThreads = (threads: [any]) =>
    threads
      .map(({ events }) => events || [])
      .reduce((acc, current) => [...acc, ...current], [])
      .filter((event: any) => event.type === "message")
      .map((event: any) => {
        const author: any = userArray.filter(
          (obj) => obj.id === event.authorId
        )[0];
        let messageObj = getMessageObj(
          event.id,
          event.text,
          isAgent(author) ? false : true,
          author.name,
          getTime(event.createdAt)
        );
        return messageObj;
      });

  //Check if the user is agent or the customer
  const isAgent = (user: any) => user.id !== userId;

  //Start the chat incase it is deactivated or a new chat
  const startChat = () => {
    const action = chatId ? sdk.activateChat : sdk.startChat;
    const payload = {
      chat: {
        ...(chatId && { id: chatId }),
      },
    };
    action(payload)
      .then(({ chat }: any) => {
        handleChatStart(chat);
        setActive(true);
      })
      .catch((e: Error) => {
        setError(true);
        console.log("e", e);
      });
  };

  const handleChatStart = (chat: any) => {
    chatId = chat.id;
    setActive(true);
    const messages = getMessagesFromThreads([chat.thread]);
    setMessages(messages);
    scrollToBottom();
  };

  //Send a new message
  const sendMessage = (chat: string, text: string) => {
    const messageId = `${Math.random() * 1000}`;
    const message = { customId: messageId, text, type: "message" };
    sdk.sendEvent({ chatId: chat, event: message }).then(
      (confirmedMessage: any) => {
        let messageObj = getMessageObj(
          messageId,
          text,
          true,
          customerName || "me",
          getTime(new Date().toISOString())
        );
        setMessages((oldMessages) => [...oldMessages, messageObj]);
        scrollToBottom();
      },
      (error: any) => {
        console.log("sendEvent", error);
      }
    );
  };

  return (
    <>
      <video
        className={classes.video}
        style={{ zIndex: -1, objectFit: "fill" }}
        loop
        autoPlay
        muted
      >
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
      <Grid container className={classes.root}>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <ChatBox
              startChat={() => startChat()}
              canMessage={active}
              loading={loading}
              onNewMessage={(message) => {
                sendMessage(chatId, message);
              }}
              title="Sales Chat"
              style={{ minHeight: 500 }}
              messages={messages}
              error={error}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Grid item style={{ paddingBottom: 25 }}>
            <CaptionButton
              title="Project Launcher"
              body="You are ready to initiate a lab project with Mr. Adhesion"
              icon={<RocketIcon />}
              to="/product-launcher"
              width={248}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
