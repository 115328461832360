import { makeStyles, Typography } from "@material-ui/core";
import { IInformationDialogProps } from "Interfaces/props/informationDialogProps";
import Dialog from "./Dialog";
import SmallButton from "./SmallButton";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  subTitleText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    margin: "35px 0 25px !important",
    color: "#01366c",
  },
}));

export default function InformationDialog(props: IInformationDialogProps) {
  const classes = useStyles();
  const buttonText = props.buttonText || props.error ? "OK" : "GOT IT!!";
  return (
    <Dialog {...props}>
      <Typography className={classes.titleText} style={{ marginTop: 15 }}>
        {props.titleText}
      </Typography>
      <Typography
        className={classes.subTitleText}
        style={{ marginTop: 15, width: 325, margin: "auto" }}
      >
        {props.subtext}
      </Typography>
      <SmallButton
        color={props.error ? "red" : "green"}
        style={{ marginTop: 25 }}
        onClick={props.handleClose}
      >
        {buttonText}
      </SmallButton>
    </Dialog>
  );
}
