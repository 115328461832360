import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { projectsSlice } from "./slices/projectsSlice";
import { userSlice } from "./slices/userSlice";
import { usersSlice } from "./slices/usersSlice";
// ...

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    users: usersSlice.reducer,
    projects: projectsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware()
  .prepend(
  )
  .concat()
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
