import { Grid } from "@material-ui/core";
import * as React from "react";
import BlueHeaderTile from "./BlueHeaderTile";
import { IBlueColumnHeadersProps } from "Interfaces/props/blueColumnHeader";

export default function BlueColumnHeaders(props: IBlueColumnHeadersProps) {
  //const classes = useStyles();
  const disabledIdx = props.disabledIdx || [];
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      className={props.className}>
      {props.labels.map((label, idx) => {
        let type: "start" | "middle" | "end" = "middle";
        switch (idx) {
          case 0:
            type = "start";
            break;
          case props.labels.length - 1:
            type = "end";
            break;
          default:
            type = "middle";
            break;
        }
        return (
          <BlueHeaderTile
            key={idx}
            type={type}
            label={label}
            small={props.small}
            large={props.large}
            onClick={() => {
              props.onClick(idx);
            }}
            selected={props.selectedIdx === idx}
            disabled={disabledIdx.includes(idx)}
          />
        );
      })}
    </Grid>
  );
}
