import { makeStyles, Typography } from "@material-ui/core";
import { ILeaveDialogProps } from "Interfaces/props/leaveDialogProps";
import * as React from "react";
import Dialog from "./Dialog";
import LargeButton from "./LargeButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 10,
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    marginTop: 10,
  },
  buttonRoot: {
    margin: "auto",
  },
}));

export default function LeaveDialog(props: ILeaveDialogProps) {
  const classes = useStyles();
  return (
    <Dialog open={props.open} handleClose={props.handleClose}>
      <Typography className={classes.headerText}>Oops!</Typography>
      <Typography className={classes.subHeaderText} style={{ maxWidth: 350 }}>
        You haven't completed the questions for our chemists to help you.
      </Typography>
      <LargeButton
        style={{ marginTop: 25 }}
        className={classes.buttonRoot}
        text="COMPLETE PROJECT"
        color="green"
        onClick={props.handleConfirm}
      />
      <LargeButton
        style={{ marginTop: 25 }}
        className={classes.buttonRoot}
        text="SAVE FOR LATER"
        color="blue"
        onClick={props.handleClose}
      />
      <Typography
        className={classes.headerText}
        style={{ marginTop: 50, maxWidth: 400 }}
      >
        Not sure about something? We can help!
      </Typography>
      <a href="/chat">
        <LargeButton
          style={{ marginTop: 25 }}
          className={classes.buttonRoot}
          text="LET'S CHAT"
          color="green"
          onClick={props.handleClose}
        />
      </a>
    </Dialog>
  );
}
