import { SvgIcon } from "@material-ui/core";
import * as React from "react";
import { ReactComponent as EditImage } from "Images/edit-small.svg";
import { ReactComponent as DeleteImage } from "Images/delete-small.svg";

interface IconProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

export const EditIcon = (props: IconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 13 13"
      onClick={props.onClick}
      style={{
        marginLeft: 5,
        marginRight: 5,
        cursor: "pointer",
        ...props.style,
      }}
    >
      <EditImage />
    </SvgIcon>
  );
};

export const DeleteIcon = (props: IconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 13 14"
      onClick={props.onClick}
      style={{
        marginLeft: 5,
        marginRight: 5,
        cursor: "pointer",
        ...props.style,
      }}
    >
      <DeleteImage />
    </SvgIcon>
  );
};
