import { makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import ProductTileBlue from "../Images/product-tile-blue.svg";
import LargeButton from "./LargeButton";
import { ReactComponent as MaterialIcon } from "../Images/material-icon.svg";
import { ReactComponent as FormulaIcon } from "../Images/formula-icon.svg";
import { ReactComponent as AddToProjectIcon } from "../Images/add-to-project.svg";
import { ReactComponent as EnvelopeIcon } from "../Images/envelope.svg";
import { IBlueTileProps } from "Interfaces/props/blueTileProps";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${ProductTileBlue})`,
    width: "364px",
    height: "479px",
    margin: 25,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down(450)]: {
      width: 370,
      backgroundSize: "cover",
      height: 453,
    },
    [theme.breakpoints.down(400)]: {
      width: 350,
      height: 403,
    },
    [theme.breakpoints.down(350)]: {
      width: 300,
      height: 360,
    },
  },
  iconRoot: {
    margin: "auto",
    display: "block",
    width: 50,
    height: 50,
    marginTop: 25,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  mainText: {
    "font-family": "VarelaRound",
    "font-size": "15px",
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.33,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    paddingRight: 25,
    paddingLeft: 25,
  },
  actionContainer: {
    margin: "auto",
    width: "fit-content",
  },
  mobileButton: {
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
}));

export default function BlueTile(props: IBlueTileProps) {
  const classes = useStyle();

  let Icon = <></>;
  let captionPrefix = "";
  let actions = <></>;
  let viewbox = "0 0 50 50";

  switch (props.type) {
    case "formula":
      Icon = <FormulaIcon />;
      captionPrefix = "Application";
      actions = (
        <div className={classes.actionContainer}>
          <LargeButton
            text="ADD TO PROJECT"
            color="orange"
            icon={<AddToProjectIcon viewBox="0 0 20 20" />}
            className={classes.mobileButton}
            onClick={props.onClickPrimary}
          />
        </div>
      );
      viewbox = "0 0 54 54";
      break;
    case "material":
      Icon = <MaterialIcon />;
      captionPrefix = "Category";
      actions = (
        <div className={classes.actionContainer}>
          <LargeButton
            text="REQUEST SAMPLE"
            color="green"
            icon={<EnvelopeIcon viewBox="0 0 22 17" />}
            className={classes.mobileButton}
            onClick={props.onClickPrimary}
          />
          <LargeButton
            text="ADD TO PROJECT"
            color="orange"
            icon={<AddToProjectIcon viewBox="0 0 20 20" />}
            className={classes.mobileButton}
            onClick={props.onClickSecondary}
          />
        </div>
      );
      viewbox = "0 0 54 54";
      break;
    case "regulatory":
      Icon = <FormulaIcon />;
      captionPrefix = "";
      actions = (
        <div className={classes.actionContainer}>
          <LargeButton
            text="View Documents"
            color="green"
            icon={<AddToProjectIcon viewBox="0 0 20 20" />}
            className={classes.mobileButton}
            onClick={props.onClickPrimary}
          />
        </div>
      );
      viewbox = "0 0 54 54";
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <SvgIcon className={classes.iconRoot} viewBox={viewbox}>
        {Icon}
      </SvgIcon>
      <Typography className={classes.titleText}>{props.title}</Typography>
      <Typography className={classes.mainText}>
        {props.caption ? captionPrefix + ": " + props.caption : ""}
      </Typography>
      <br />
      <Typography
        className={classes.mainText}
        style={props.caption ? undefined : { marginTop: 200 }}
      >
        {props.description}
      </Typography>
      {actions}
    </div>
  );
}
