import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, Route, useLocation } from "react-router";

import { IMessage } from "Interfaces/message";
import { ISteps } from "Interfaces/step";

import BackButton from "../Components/BackButton";
import BlueColumnHeaders from "../Components/BlueColumnHeaders";
import ChatBox from "../Components/ChatBox";
import { InfoPanel } from "../Components/InfoPanel";
import NewProjectButton from "../Components/NewProjectButton";
import WebSocketWrapper from "../Components/WebSocketWrapper";
import { ReactComponent as BeakerFull } from "../Images/beaker-full.svg";
import { ReactComponent as BeakerHalf } from "../Images/beaker-half.svg";
import { ReactComponent as BeakerLow } from "../Images/beaker-low.svg";
import { ReactComponent as PDFIcon } from "../Images/pdf.svg";
import { useAppSelector } from "../Redux/hooks";
import {
  addPublicMessage,
  fetchProject,
  getActiveProject,
} from "../Redux/slices/projectsSlice";
import { getUserId } from "../Redux/slices/userSlice";
import { projectApi } from "../Utils/api/projectApi";
import { formatDate, headerCss, hideMobile, showMobile } from "../Utils/utils";
import LogMessage from "./Admin/LogMessage";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  showMobile: showMobile(theme) as any,
  hideMobile: hideMobile(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
  },
  rightPanel: { width: "50%", padding: 25 },
  bodyText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  dateText: {
    fontFamily: "VarelaRound",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#f79730",
  },
  centeredTitleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: "#59cee7",
    marginTop: 40,
  },
  centeredSubText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#59cee7",
    marginTop: 10,
  },
  notesText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#01366c",
    width: 400,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  mobileHeaderText: {
    marginBottom: 25,
  },
  marginRoot: { marginTop: 25, marginBottom: 25 },

  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  iconRoot: {
    width: 35,
    height: 35,
  },
  attachmentList: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  attachmentLink: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    marginRight: "15px",
  },
}));

export default function ProjectDetails() {
  const [mobileNav, setMobileNav] = React.useState(0);
  const project = useAppSelector(getActiveProject);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params: any = useParams();
  const [customerMessages, setCustomerMessages] = React.useState<IMessage[]>(
    []
  );
  const [chemistNotes, setChemistNotes] = React.useState<IMessage[]>([]);
  const [nextSteps, setNextSteps] = React.useState<ISteps[]>([]);
  const userId = useAppSelector(getUserId);

  React.useEffect(() => {
    const mappedNotes: IMessage[] =
      project.public_notes?.map((note) => ({
        id: note.id,
        message: note.body as string,
        self: note.user.id === userId,
        date: note.created_at as string,
        title: note.user.name as string,
      })) || [];

    setChemistNotes(mappedNotes);

    const mappedMessages: IMessage[] =
      project.public_messages?.map((val: any) => ({
        message: val.body as string,
        self: val.user.id === userId,
        date: val.created_at as string,
        title: val.user.name as string,
      })) || [];
    console.log("mapped message======", mappedMessages);
    setCustomerMessages(mappedMessages);
  }, [project, userId]);

  React.useEffect(() => {
    const mappedSteps =
      project.ordered_steps?.map((step) => ({
        id: step.id,
        description: step.description,
        is_complete: step.is_complete,
        completion_date: formatDate(step.completed_at),
      })) || [];
    setNextSteps(mappedSteps);
  }, [project]);

  React.useEffect(() => {
    if (!Object.keys(project).length) {
      dispatch(fetchProject(params.projectId));
    }
  }, [dispatch]);

  const classes = useStyles();
  let viewBox = project.stage === "completion" || project.stage === "completed_and_notified" ? "0 0 214 104" : "0 0 106 106";
  let icon = <></>;
  let phase = <></>;
  let steps = nextSteps.map((step: ISteps) => {
    if (step.is_complete) {
      return (
        <li key={step.id}>
          <s>{step.description}</s> (Completed on {step.completion_date})
        </li>
      );
    } else {
      return <li key={step.id}>{step.description}</li>;
    }
  });

  let notes = chemistNotes.map((note: IMessage) => {
    return (
      <LogMessage
        key={note.id}
        date={formatDate(note.date)}
        time=""
        content={note.message}
        canDelete={false}
        id={note.id}
        title={note.title}
      />
    );
  });
  console.log('date', project.stage);

  switch (project.stage) {
    case "unclaimed":
    case "technical_assessment":
    case "commercial_assessment":
    case "request_more_info":
    case "vp_approval":
    case "plan":
      icon = <BeakerLow />;
      phase = <>Consultation Phase</>;
      break;
    case "progress":
      icon = <BeakerHalf />;
      phase = <>Progress Phase</>;
      break;
    case "completed_and_notified":
      icon = <BeakerFull />;
      phase = <>Complete</>;      
      break;
    case "completion":
      icon = <BeakerFull />;
      phase = <>Complete</>;
      break;
    default:
      break;
  }

  const onRecivingMessage = (data: any) => {
    console.log("message received ", data.message);
    dispatch(addPublicMessage(data.message));
  };

  const createPublicMessage = (message: string) => {
    console.log("message created on public ", message);
    if (userId) {
      projectApi.addMessageApi({
        body: message,
        projectId: project.id || "",
        isPrivate: false,
        userId: userId,
      });
    }
  };

  const wsParams = {
    projectId: project.id || "",
  };

  const renderAttachments = () => {
    if (!project.attachments.length) {
      return <></>;
    } else {
      let mappedAttachments = project.attachments
        ?.filter((attachment) => attachment.attachment_type === "Project")
        .map((attachment, index) => {
          return {
            id: attachment.id,
            filename:
              attachment.document_file_name || `Attachment ${index + 1}`,
            url: attachment.document_url,
          };
        })
        .map((attachment) => {
          return (
            <a
              key={attachment.id}
              className={classes.attachmentLink}
              href={attachment.url}
              target="_blank">
              <SvgIcon viewBox="0 0 35 35" className={classes.iconRoot}>
                <PDFIcon />
              </SvgIcon>
              {attachment.filename}
            </a>
          );
        });
      return (
        <>
          <Typography className={classes.centeredSubText}>Attachments</Typography>
          <div className={classes.attachmentList}>{mappedAttachments}</div>
        </>
      );
    }
  };

  let leftElement = (
    <>
      <Grid item className={classes.marginRoot}>
        <BlueColumnHeaders
          labels={["Information", "Chat"]}
          onClick={(item) => {
            if(item === 1) history.push(`/customer/projects/${project.id}/notes`)
            else history.push(`/customer/projects/${project.id}`)
          }}
          selectedIdx={location.pathname === `/customer/projects/${project.id}/notes` ? 1 : 0}
          small
        />
      </Grid>
      <Route path="/customer/projects/:projectId/notes" exact>
        <ChatBox
          canMessage={true}
          messages={customerMessages}
          title="Chat"
          onNewMessage={createPublicMessage}
          alignCenter={true}
        />
      </Route>
      <Route path="/customer/projects/:projectId" exact>
        <InfoPanel canEdit={false} />
      </Route>
    </>
  );
  let rightElement = (
    <>
      <Grid item>
        <Typography className={classes.subHeaderText}>Status</Typography>
      </Grid>
      <Grid
        item
        style={{ width: 200, height: 200, margin: "auto", marginTop: 25 }}
      >
        <SvgIcon viewBox={viewBox} style={{ width: 200, height: 200 }}>
          {icon}
        </SvgIcon>
      </Grid>
      <Grid item>
        <Typography className={classes.centeredTitleText}>{phase}</Typography>
        <Typography className={classes.dateText}>
          updated {project.updated_at}
        </Typography>
      </Grid>
      <Grid item>
        {renderAttachments()}
      </Grid>
      <Grid item>
        <Typography className={classes.centeredSubText}>
          {project.stage === "plan" ? "Lab User Notes:" : "Chemist’s Notes:"}
        </Typography>
        {notes}
        {project.stage === "progress" && (
          <>
            <Typography className={classes.centeredSubText}>
              Next Steps:
            </Typography>
            <ul>{steps}</ul>
          </>
        )}
      </Grid>
    </>
  );

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ height: "max-content" }}
        justify="space-between"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ width: "max-content" }}
        >
          <Grid item>
            <BackButton
              onClick={() => {
                history.push("/customer/dashboard");
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.bodyText} style={{ marginLeft: 10 }}>
              Dashboard
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.headerText}>{project.name}</Typography>
        </Grid>
        <Grid item className={classes.hideMobile}>
          <NewProjectButton onClick={() => {}} />
        </Grid>
      </Grid>
      <WebSocketWrapper
        channel="MessagesChannel"
        onMessageRecived={onRecivingMessage}
        params={wsParams}
      >
        <>
          <Grid container direction="column" className={classes.showMobile}>
            <BlueColumnHeaders
              labels={["Project", "Status"]}
              selectedIdx={mobileNav}
              onClick={(idx: number) => {
                setMobileNav(idx);
              }}
              small
              className={classes.marginRoot}
            />
            {mobileNav === 0 ? leftElement : rightElement}
          </Grid>
          <Grid
            container
            direction="row"
            style={{ flex: 1, marginBottom: 35, marginTop: 25 }}
            className={classes.hideMobile}
          >
            <Grid item className={classes.leftPanel}>
              {leftElement}
            </Grid>
            <Grid item className={classes.rightPanel}>
              {rightElement}
            </Grid>
          </Grid>
        </>
      </WebSocketWrapper>
    </Grid>
  );
}
