import { Grid, makeStyles, Typography } from "@material-ui/core";
import BlueColumnHeaders from "../../Components/BlueColumnHeaders";
import NavText from "../../Components/NavText";
import SearchBar from "../../Components/SearchBar";
import TSRTile from "../../Components/TSRTile";
import React, { useMemo } from "react";
import { headerCss, hideMobile, showMobile } from "../../Utils/utils";
import { useAppSelector } from "../../Redux/hooks";
import {
  fetchAllProjects,
  fetchProject,
  getProjects,
  searchProjects,
} from "../../Redux/slices/projectsSlice";
import { getUserGroup, getUserId } from "../../Redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Project } from "Interfaces/project";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
    marginBottom: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
  },
  buttonText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    marginLeft: 10,
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  assessmentText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 35,
  },
  textField: { marginTop: 15 },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
      padding: 5,
    },
  },
  searchBar: {
    margin: "auto",
    marginTop: 26,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MOBILE_LABELS = [
  "Information",
  "Application",
  "Customer Chat",
  "Internal Chat",
];

const INITIAL_LABELS = ["Open", "Approved", "More Info Required", "Rejected",];

const labelToLprStatus = (label: string) => {
  return label
    .split(" ")
    .map((val) => val.toLowerCase())
    .join("_");
};

export default function AdminLPRList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const projects = useAppSelector(getProjects);
  const userRole = useAppSelector(getUserGroup);
  const userId = useAppSelector(getUserId);

  const [searchText, setSearchText] = React.useState<string>("");
  const classes = useStyles();
  const [firstColumn, setFirstColumn] = React.useState(-1);
  const [phaseFilter, setPhaseFilter] = React.useState("");

  const filterFunction = (project: Project) => {
    let projectStatus = project.project_status;
    switch(phaseFilter) {
      case 'open':
        return !project.is_approved && projectStatus !== 'closed';
      case 'more_info_required':
        return projectStatus === 'request_more_info';
      case 'approved':
        return project.is_approved;
      case 'rejected':
        return projectStatus === 'closed';
      default: return false;
    };
  }

  const filteredProjects =
    phaseFilter === ""
      ? projects
      : projects.filter((project) => filterFunction(project));

  console.log(projects, "projects")

  React.useEffect(() => {
    if (searchText === "") dispatch(fetchAllProjects());
    else dispatch(searchProjects({ query: searchText, offset: 0 }));
  }, [dispatch, searchText]);

  const selectProject = (project: Project) => {
    dispatch(fetchProject(project.id));
    history.push(`/admin/lpr/${project.id}`);
    console.log("project ", project);
  };

  const handleClickOnColumnHeader = (idx: number) => {
    if (idx === firstColumn) {
      setFirstColumn(-1);
      setPhaseFilter("");
    } else {
      setFirstColumn(idx);
      setPhaseFilter(labelToLprStatus(INITIAL_LABELS[idx]));
    }
  };

  const isTSR = useMemo(
    () => userRole === "TechnicalSalesRepresentative",
    [userRole]
  );

  return (
    <Grid className={classes.root}>
      <Grid item>
        <Typography className={classes.headerText}>LPRs</Typography>
      </Grid>
      <Grid item>
        <div className={classes.hideMobile}>
          <BlueColumnHeaders
            labels={INITIAL_LABELS}
            onClick={(idx: number) => {
              handleClickOnColumnHeader(idx);
            }}
            small
            selectedIdx={firstColumn}
          />
        </div>
        <div className={classes.showMobile}>
          <Grid
            container
            direction="row"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            {INITIAL_LABELS.map((label, idx) => {
              return (
                <NavText
                  key={idx}
                  text={label}
                  onClick={() => {
                    handleClickOnColumnHeader(idx);
                  }}
                  isActive={firstColumn === idx}
                />
              );
            })}
          </Grid>
        </div>
      </Grid>
      <Grid item>
        <SearchBar
          placeholder="Search"
          centered
          className={classes.searchBar}
          onChange={setSearchText}
        />
      </Grid>
      <Grid container direction="row" justify="center">
        {filteredProjects
          .filter((project) => {
            if (!isTSR) return true;
            return userId === project.tsr_id;
          })
          .map((project, idx) => (
            <TSRTile
              key={idx}
              {...project}
              ARP={project.annual_revenue_potential || 0}
              onClick={() => {
                console.log("project: ", project);
                selectProject(project);
              }}
              isTSR={isTSR}
            />
          ))}
      </Grid>
    </Grid>
  );
}
