import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as AccountIcon } from "../Images/account.svg";
import { ReactComponent as LauncherIcon } from "../Images/launcher.svg";
import { ReactComponent as ChatIcon } from "../Images/chat-icon.svg";
import { ReactComponent as ContactIcon } from "../Images/contact.svg";
import { ReactComponent as LogoutIcon } from "../Images/logout.svg";
import { ReactComponent as UsersIcon } from "../Images/users.svg";
import { ReactComponent as DocIcon } from "../Images/doc.svg";
import { Link, useLocation } from "react-router-dom";
import {  useAppSelector, useAppDispatch } from "../Redux/hooks";
import {
  getAuthState,
  login,
  logout,
  getUserFormError,
  getUserGroup,
} from "../Redux/slices/userSlice";
import LoginBubble from "../Images/login-bubble.png";
import NarrowTextfield from "./NarrowTextfield";
import SmallButton from "./SmallButton";
import InformationDialog from "Components/InformationDialog";
import { userApi } from "Utils/api/userApi";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";
import  { AppDispatch } from "Redux/store";

const tiles = [
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <AccountIcon />
      </SvgIcon>
    ),
    label: "Projects & Profile",
    to: "/customer/dashboard",
    area: ["frontend", "backend"],
    roles: ["Customer"],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <LauncherIcon />
      </SvgIcon>
    ),
    label: "Project Launcher",
    to: "/product-launcher",
    area: ["frontend", "backend"],
    roles: ["Customer"],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <DocIcon />
      </SvgIcon>
    ),
    label: "LPRs",
    to: "/admin/lpr",
    area: ["backend"],
    roles: [
      "TechnicalSalesRepresentative",
      "LabWorker",
      "LabManager",
      "VicePresident",
      "Admin",
    ],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <LauncherIcon />
      </SvgIcon>
    ),
    label: "Projects",
    to: "/admin/projects",
    area: ["backend"],
    roles: [
      "LabWorker",
      "LabManager",
      "VicePresident",
      "Admin",
    ],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <ChatIcon />
      </SvgIcon>
    ),
    label: "Sales Chat",
    to: "/chat",
    area: ["frontend", "backend"],
    roles: ["Customer"],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <ContactIcon />
      </SvgIcon>
    ),
    label: "Contact",
    to: "/contact-us",
    area: ["frontend", "backend"],
    roles: ["Customer"],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <UsersIcon />
      </SvgIcon>
    ),
    label: "Users",
    to: "/admin/users",
    area: ["backend"],
    roles: ["VicePresident"],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <AccountIcon />
      </SvgIcon>
    ),
    label: "Account",
    to: "/admin/account",
    area: ["frontend", "backend"],
    roles: [
      "TechnicalSalesRepresentative",
      "LabWorker",
      "LabManager",
      "VicePresident",
      "Admin",
    ],
  },
  {
    icon: (
      <SvgIcon viewBox="0 0 37 37">
        <LogoutIcon />
      </SvgIcon>
    ),
    label: "Log Out",
    to: "/login",
    area: ["frontend", "backend"],
    roles: [],
  },
];

const useStyles = makeStyles((theme) => ({
  tileRoot: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
  },
  iconRoot: {
    width: 37,
    height: 37,
    cursor: "pointer",
    margin: "auto",
    color: "yellow",
    [theme.breakpoints.down("sm")]: {
      width: 27,
      height: 27,
    },
  },
  labelText: {
    "font-family": "Lato",
    "font-size": "12px",
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.4,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    width: 60,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  SelectedlabelText: {
    "font-family": "Lato",
    "font-size": "13.50px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.4,
    "letter-spacing": "normal",
    "marginRight": "5px",
    "text-align": "center",
    color: "#01366c",
    width: 60,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  loginBubble: {
    backgroundImage: `url(${LoginBubble})`,
    width: "265px",
    height: "218px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    zIndex: 100,
    justifyContent: "center",
    top: "100%",
    right: "-125%",
    paddingTop: 10,
  },
  textField: { width: "80%", marginTop: 10 },
}));

const TopNav = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const isAuthed = useAppSelector(getAuthState);
  const loginStateError = useAppSelector(getUserFormError);
  let userRole: any = useAppSelector(getUserGroup);
  const [openLogin, setOpenLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [informationDialog, setInformationDialog] = useState(false);
  const [informationDialogHeading, setInformationDialogHeading] = useState("");
  const [informationDialogText, setInformationDialogText] = useState("");
  const [informationDialogError, setInformationDialogError] = useState(false);
  const [isBackend, setIsBackend] = useState(false);
  if (isAuthed && !userRole) {
    let token: any = localStorage.getItem("access_token");
    if (token) {
      let userToken: any = jwt_decode(token);
      userRole = userToken.profile_type;
    }
  }

  useEffect(() => {
    let path = location.pathname;
    setIsBackend(path.includes("/admin") || path.includes("/customer"));
  }, [location]);

  let filteredTiles = tiles
    .filter((tile) => tile.roles.includes(userRole) || tile.roles.length === 0)
    .filter((tile) =>
      isBackend ? tile.area.includes("backend") : tile.area.includes("frontend")
    );

  const handleForgotPassword = () => {
    userApi
      .forgotPasswordApi(email)
      .then(() => {
        setInformationDialogHeading("Success!");
        setInformationDialogText(
          `We sent an Email to ${email} with your password. \nDon't forgot to check your SPAM folder! If it doesn't show up, please contact us`
        );
        setInformationDialogError(false);
        setInformationDialog(true);
      })
      .catch((error) => {
        setInformationDialogHeading("OOPS!!");
        setInformationDialogText(
          error.response?.data?.message ||
            "Something went wrong.Don't worry we are looking into it!"
        );
        setInformationDialogError(true);
        setInformationDialog(true);
      });
  };

  useEffect(() => {
    if (loginStateError?.login) {
      setInformationDialogHeading("Invalid Email or password");
      setInformationDialogText("Please try again");
      setInformationDialogError(false);
      setInformationDialog(true);
    }
  }, [loginStateError]);

  if (!isAuthed)
    return (
      <Grid container direction="row" justify="flex-end">
        <style>
          {`input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}`}
        </style>
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.tileRoot}
          style={{ width: "max-content", position: "relative" }}>
          <InformationDialog
            open={informationDialog}
            handleClose={() => setInformationDialog(false)}
            titleText={informationDialogHeading}
            subtext={informationDialogText}
            error={informationDialogError}
          />
          <SvgIcon
            viewBox="0 0 37 37"
            className={classes.iconRoot}
            onClick={() => {
              setOpenLogin(!openLogin);
            }}>
            <AccountIcon />
          </SvgIcon>
          <Typography className={classes.labelText}>LOGIN</Typography>
          {openLogin ? (
            <ClickAwayListener
              onClickAway={() => {
                setOpenLogin(false);
              }}>
              <div className={classes.loginBubble}>
                <NarrowTextfield
                  placeholder="EMAIL ADDRESS"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  className={classes.textField}
                />
                <NarrowTextfield
                  placeholder="PASSWORD"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  isPassword
                  className={classes.textField}
                />
                <Typography
                  onClick={() => {
                    history.push("/forgot-password");
                  }}
                  style={{
                    width: "80%",
                    textAlign: "left",
                    fontFamily: "'montserratbold'",
                    fontSize: "11px",
                    letterSpacing: "0.3px",
                    color: "#01366c",
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginTop: 5,
                  }}>
                  Forgot Password?
                </Typography>
                <SmallButton
                  color="green"
                  onClick={() => {
                    dispatch(login({ email, password, account_name: "" }));
                  }}
                  style={{ marginTop: 15 }}>
                  LOGIN
                </SmallButton>
              </div>
            </ClickAwayListener>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.tileRoot}
          style={{ width: "max-content" }}>
          <Link to={"/contact-us"}>
            <SvgIcon viewBox="0 0 37 37" className={classes.iconRoot}>
              <ContactIcon />
            </SvgIcon>
            <Typography className={classes.labelText}>CONTACT</Typography>
          </Link>
        </Grid>
      </Grid>
    );

  return (
    <Grid container direction="row" justify="flex-end">
      {filteredTiles.map((tile) => {
        let onClick = undefined;
        if (tile.label === "Log Out")
          onClick = () => {
            dispatch(logout());
            history.push("/login");
          };
        let icon = React.cloneElement(tile.icon, {
          
          onClick: onClick,
        });
        return (
          <Link
            to={tile.to}
            key={tile.label}
            style={{ textDecoration: "none" }}
            onClick={tile.label === "Log Out" ? onClick : undefined}>
            
            {location.pathname.includes(tile.to) ? 
              <>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  className={classes.tileRoot}>
                  {icon}
                </Grid>
                <Typography className={classes.SelectedlabelText}>{tile.label.toUpperCase()}</Typography> 
              </> :
              <>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  className={classes.tileRoot}>
                  {icon}
                </Grid>
                <Typography className={classes.labelText}>{tile.label.toUpperCase()}</Typography> 
              </>
            }
          </Link>
        );
      })}
    </Grid>
  );
};

export default TopNav;
