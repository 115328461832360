import { ThemeOptions } from "@material-ui/core";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { CSSProperties } from "react";
import { Project } from "Interfaces/project";
import actionCable from "actioncable";

export const asyncSetReady = (props: any) => {
  if (!props.required && props.setReady) props.setReady(true, props.idx);
};

export const headerCss = (theme: ThemeOptions): CSSProperties => {
  if (!theme.breakpoints || !theme.breakpoints.down) return {};
  return {
    marginTop: "100px",
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 70,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "80px",
    },
  };
};

export const showMobile = (theme: ThemeOptions) => {
  if (!theme.breakpoints || !theme.breakpoints.down) return;

  return {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inherit",
    },
  };
};

export const hideMobile = (theme: ThemeOptions) => {
  if (!theme.breakpoints || !theme.breakpoints.down) return;

  return {
    display: "inherit",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  };
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getNDAValue = (activeProject: Partial<Project>) => {
  return activeProject.commercial_assessment?.nda_required &&
    activeProject.commercial_assessment.nda
    ? 0
    : activeProject.commercial_assessment?.nda_required
    ? 1
    : 2;
};

const makeConfig = (config?: AxiosRequestConfig) => ({
  ...config,
  headers: {
    ...config?.headers,
    Authorization: localStorage.getItem("access_token"),
  },
});

// Accepts the array and key
export const groupBy = (array: Array<any>, key: string) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatDate = (completionDate: string | Date) => {
  const endDate =
    completionDate instanceof Date ? completionDate : new Date(completionDate);
  return `${
    months[endDate.getMonth()]
  } ${endDate.getDate()} ${endDate.getFullYear()}`;
};

export const patch = (url: string, data: any, config?: AxiosRequestConfig) =>
  axios.patch(
    `${process.env.REACT_APP_API_URL}` + url,
    data,
    makeConfig(config)
  );

export const post = (url: string, data: any, config?: AxiosRequestConfig) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}` + url,
    data,
    makeConfig(config)
  );

export const get = (url: string, config?: AxiosRequestConfig) =>
  axios.get(`${process.env.REACT_APP_API_URL}` + url, makeConfig(config));

export const AxiosDelete = (url: string, config?: AxiosRequestConfig) =>
  axios.delete(`${process.env.REACT_APP_API_URL}` + url, makeConfig(config));

export const actionCableConsumer = (token: string) =>
  actionCable.createConsumer(`${process.env.REACT_APP_WS_BASE}?token=${token}`);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
