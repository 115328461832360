import { makeStyles } from "@material-ui/core";
import { headerCss, hideMobile, showMobile } from "Utils/utils";
import TipBubble from "Images/mini-menu.svg";

const cell = { width: 237, height: 50 };

const useStyles = makeStyles((theme) => ({
  root: {
    ...(headerCss(theme) as any),
    [theme.breakpoints.down("lg")]: { width: "100%" },
  },
  tableBody: {
    margin: "auto",
    width: "max-content",
    overflow: "auto",
    paddingBottom: 25,
    [theme.breakpoints.down("lg")]: {
      paddingRight: 5,
      paddingLeft: 5,
    },
  },
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginBottom: 25,
  },
  leftCell: { ...cell, border: "1px dashed #01366c", borderLeft: "inherit" },
  middleCell: { ...cell, border: "1px dashed #01366c" },
  rightCell: {
    ...cell,
    border: "1px dashed #01366c",
    borderRight: "inherit",
  },
  cellText: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#878787",
  },
  yellowCellText: {
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#f79730",
  },
  panelRoot: { flex: 1, overflowY: "auto", marginTop: 25 },
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
  },
  rightPanel: { width: "50%", padding: 25 },
  ribbonRoot: { margin: "auto", marginTop: 20 },
  textfield: { marginTop: 20 },
  blueText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
  },
  orangeText: {
    fontFamily: "VarelaRound",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    color: "#f79730",
  },
  mainText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
  bubbleMenuRoot: { position: "relative", marginLeft: 10 },
  hoverBubble: {
    backgroundImage: `url(${TipBubble})`,
    position: "absolute",
    width: 138,
    height: 124,
    margin: "auto",
    right: "0px",
    cursor: "default",
    zIndex: 100,
  },
  dotButton: {
    cursor: "pointer",
  },
  mangeMilesStonesToolTip: {
    marginTop: "25px",
    marginLeft: "27px",
    maxWidth: "145px",
    maxHeight: "97px",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: 0,
    color: "#01366c",
    fontFamily: "VarelaRound",
  },
  searchBar: {
    margin: "auto",
    marginBottom: 50,
    //width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconRoot: {
    width: 35,
    height: 35,
  },
  ndaLink: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
