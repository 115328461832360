import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { CaptionButtonProps } from "Interfaces/props/captionButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: CaptionButtonProps) => props.width,
  },
  captionRoot: {
    opacity: 0.75,
    "border-radius": "25px",
    border: "solid 2.5px #01366c",
    "background-color": "#ffffff",
    zIndex: 5,
  },
  iconRoot: {
    height: 53,
    width: 53,
    "background-color": (props: CaptionButtonProps) =>
      props.color === "blue" ? "#59cee7" : "#f79730",
    "border-radius": " 50%",
    border: "2.5px solid #01366c",
    margin: "auto",
    marginBottom: -10,
    zIndex: 10,
    position: "relative",
    cursor: "pointer",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    display: "block",
  },
  titleText: {
    "font-family": "Lato",
    "font-size": " 18px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.11,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    marginTop: 16,
  },
  bodyText: {
    "font-family": "Lato",
    "font-size": "17px",
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.18,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    marginTop: 4,
    marginBottom: 16,
    paddingRight: 16,
    paddingLeft: 16,
  },
}));


const CaptionButton = (props: CaptionButtonProps) => {
  const classes = useStyles(props);
  const injectedIcon = React.cloneElement(props.icon as any, {
    className: classes.icon,
  });
  return (
    <Link to={props.to || "/404"} style={{ textDecoration: "none" }}>
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.root}
        style={props.style}
      >
        <Grid item className={classes.iconRoot}>
          {injectedIcon}
        </Grid>
        <Grid item className={classes.captionRoot}>
          <Typography className={classes.titleText}>{props.title}</Typography>
          <Typography className={classes.bodyText}>{props.body}</Typography>
        </Grid>
      </Grid>{" "}
    </Link>
  );
};

export default CaptionButton;
