import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  loader: {
    border: "10px solid #f3f3f3",
    "border-radius": "50%",
    "border-top": "10px solid #3498db",
    width: 60,
    height: 60,
    "-webkit-animation": "$spin 2s linear infinite" /* Safari */,
    animation: "$spin 2s linear infinite",
  },
  "@-webkit-keyframes spin": {
    "0%": { "-webkit-transform": "rotate(0deg)" },
    "100%": { "-webkit-transform": "rotate(360deg)" },
  },

  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

export default function Loader() {
  const classes = useStyles();
  return <div className={classes.loader}></div>;
}
