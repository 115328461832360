import { IUserInfo } from "Interfaces/user";
import { post, get } from "Utils/utils";

export const userApi = {
  createUser: (user: IUserInfo) => post("/users/create_profile", { user }),
  deleteUserApi: (userId: string) => post(`/users/${userId}/delete_user`, {}),
  forgotPasswordApi: (email: string) =>
    post("/users/reset_password", { user: { email } }),
  fetchTsrs: () => get("/admin/projects/tsrs"),
};
