import { makeStyles } from "@material-ui/core";
import BackButtonIcon from "../Images/back.svg";
import BackButtonIconHover from "../Images/back-hover.svg";
import { BackButtonProps } from "Interfaces/props/backButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 34,
    height: 34,
    cursor: "pointer",
    backgroundImage: `url(${BackButtonIcon})`,
    "&:hover": {
      backgroundImage: `url(${BackButtonIconHover})`,
    },
  },
}));

const BackButton = (props: BackButtonProps) => {
  const classes = useStyles();
  return <div className={classes.root} onClick={props.onClick} />;
};

export default BackButton;
