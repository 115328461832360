import { Grid, SvgIcon, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import SmallButton from "Components/SmallButton";
import TipButton from "Components/TipButton";
import NarrowTextfield from "Components/NarrowTextfield";
import AddButton from "Components/AddButton";
import BubbleMenu from "Views/Admin/BubbleMenu";
import TopHeader from "Views/Admin/TopHeader";
import useStyles from "Views/Admin/styles";
import createMilestoneApi from "Utils/api/milestones/createMilestones";
import fetchMilestones from "Utils/api/milestones/fetchMilestones";
import deleteMilestone from "Utils/api/milestones/deleteMilestones";
import editingMileStoneApi from "Utils/api/milestones/editMileStones";
import SaveButton from "Components/SaveButton";
import camelCaseKeys from "camelcase-keys";
import updateProjectStep from "Utils/api/updateProjectStep";
import { IApiState } from "Interfaces/apiState";
import { Imilestone } from "Interfaces/milestone";
import { toBase64 } from "Utils/utils";
import { ReactComponent as PDFIcon } from "Images/pdf.svg";
import { DeleteIcon } from "Views/Admin/Icons";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const formatDates = (completionDate: string, createdAt: string) => {
  const startDate = new Date(createdAt);
  const endDate = new Date(completionDate);
  if (endDate.getFullYear() !== startDate.getFullYear()) {
    return `${startDate.getFullYear()} ${
      months[startDate.getMonth()]
    } ${startDate.getDate()} - ${endDate.getFullYear()} ${
      months[endDate.getMonth()]
    } ${endDate.getDate()}`;
  }
  if (endDate.getMonth() !== startDate.getMonth()) {
    return `${months[startDate.getMonth()]} ${startDate.getDate()} - ${
      months[endDate.getMonth()]
    } ${endDate.getDate()} ${startDate.getFullYear()}`;
  }
  return `${
    months[startDate.getMonth()]
  } ${startDate.getDate()}-${endDate.getDate()} ${startDate.getFullYear()}`;
};

const tooltipText =
  "Identify the project milestones using the handy + button, Click publish once you are done to send the update to customer.";
const ManageMilestones = ({
  dialog: {
    setDialogOpen,
    setDialogSubTitle,
    setDialogTitle,
    setDialogButton,
    setDialogCancelButtonText,
    setDialogSubmitEvent,
    setDialogCancelEvent,
  },
  project,
  moveToNextTab,
  userName,
  enabledProjectPhases,
}: any) => {
  const classes = useStyles();
  const [editingMileStone, setEditingMileStone] =
    React.useState<Imilestone | null>(null);
  const [newMilestoneName, setNewMilestoneName] = React.useState("");
  const [newMilestoneCompletionDate, setNewMilestoneCompletionDate] =
    React.useState<Date | null>(null);

  const [milestones, setMilestones] = React.useState<Imilestone[]>([]);
  const [apiState, setApiState] = React.useState<IApiState>({});
  const [editFormValidation, setEditFormValidation] = React.useState({
    date: "",
    name: "",
  });
  const [createFormValidation, setCreateFormValidation] = React.useState({
    date: "",
    name: "",
  });
  const [attachments, setAttachments] = React.useState<any>([]);
  const [updateNewAttachments, setUpdateNewAttachments] = React.useState<any>(
    []
  );
  const [fileUploaderLabel, setFileUploaderLabel] = React.useState<
    string | undefined
  >();
  const [editFileUploaderLabel, setEditFileUploaderLabel] = React.useState<
    string | undefined
  >();
  const [attachmentsToDelete, setAttachmentsToDelete] = React.useState<any>([]);

  const onFileAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files).map(async (file) => {
      return {
        filename: file.name,
        content_type: file.type,
        file: await toBase64(file),
      };
    });
    Promise.all(files).then((encodedFiles) => {
      setAttachments([...attachments, ...encodedFiles]);
    });
    setFileUploaderLabel(event.target.files[0].name);
    event.target.value = "";
  };

  const onEditFileAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files).map(async (file) => {
      return {
        filename: file.name,
        content_type: file.type,
        file: await toBase64(file),
      };
    });
    Promise.all(files).then((encodedFiles) => {
      setUpdateNewAttachments([...attachments, ...encodedFiles]);
    });
    setEditFileUploaderLabel(event.target.files[0].name);
    event.target.value = "";
  };

  React.useEffect(() => {
    fetchMilestones(project.id).then((response) => {
      const responseData = response.data.map((milestone: any) => {
        return camelCaseKeys(milestone);
      });
      setMilestones(responseData);
    });
  }, []);

  const createMilestone = () => {
    let validation = true;
    let validationError = { name: "", date: "" };
    if (!newMilestoneName) {
      validationError = { ...validationError, name: "Name is required!!" };
      validation = false;
    }
    if (!newMilestoneCompletionDate) {
      validationError = { ...validationError, date: "Date is required!!" };
      validation = false;
    }
    if (!validation) {
      setCreateFormValidation(validationError);
      return;
    }
    setApiState({ loading: true });
    createMilestoneApi({
      projectId: project.id,
      isComplete: false,
      description: newMilestoneName,
      completionDate: newMilestoneCompletionDate,
      position: milestones.length,
      attachments: attachments,
    })
      .then((response: { data: any }) => {
        setApiState({ loaded: true });
        setMilestones([...milestones, camelCaseKeys(response.data)]);
        setAttachments([]);
        setNewMilestoneName("");
        setFileUploaderLabel("");
      })
      .catch(() => {
        setApiState({ error: true });
      });
  };
  const openMilestoneDialog = () => {
    setDialogOpen(true);
    setDialogTitle("Send Milestones?");
    setDialogSubTitle(
      "Clicking send will email the list to the customer and initiate the Progress Phase."
    );
    setDialogButton("SEND");
    setDialogCancelButtonText("Do Not Send");
    setDialogSubmitEvent(handlePublish);
    setDialogCancelEvent(handleCancel);
  };

  const openDeleteModal = (idToDelete: string) => {
    const handleDelete = () => () => {
      deleteMilestone(idToDelete).then(() => {
        const updatedMilestones = milestones.filter(
          (obj) => obj.id !== idToDelete
        );
        setMilestones(updatedMilestones);
        setDialogOpen(false);
      });
    };
    setDialogOpen(true);
    setDialogTitle("Delete Milestone");
    setDialogSubTitle("Are you sure you want to delete milestone?");
    setDialogButton("Delete");
    setDialogCancelButtonText("Cancel");
    setDialogSubmitEvent(handleDelete);
    setDialogCancelEvent(handleClose);
  };

  const handleEdit = () => {
    let validation = true;
    let validationError = { name: "", date: "" };
    if (!editingMileStone?.description) {
      validationError = { ...validationError, name: "Name is required!!" };
      validation = false;
    }
    if (!editingMileStone?.completionDate) {
      validationError = { ...validationError, date: "Date is required!!" };
      validation = false;
    }
    if (!validation) {
      setEditFormValidation(validationError);
      return;
    }
    setApiState({ loading: true });
    editingMileStoneApi({
      ...editingMileStone,
      attachments: updateNewAttachments,
      attachment_to_delete: attachmentsToDelete,
    })
      .then((response) => {
        setApiState({ loaded: true });
        const updatedMileStonse = milestones.map((obj) => {
          if (obj.id !== editingMileStone?.id) return obj;
          return camelCaseKeys(response.data);
        });
        setMilestones(updatedMileStonse);
        setEditingMileStone(null);
      })
      .catch(() => {
        setApiState({ error: true });
      });
  };

  const handlePublish = () => async () => {
    if (newMilestoneName && newMilestoneCompletionDate) {
      await createMilestoneApi({
        projectId: project.id,
        isComplete: false,
        description: newMilestoneName,
        completionDate: newMilestoneCompletionDate,
        position: milestones.length,
        attachments: attachments,
      });
    }
    await updateProjectStep({
      projectId: project.id,
      projectStatus: 2,
      stepsPublished: true,
      noticeType: "plan_no_call",
    });
    setDialogOpen(false);
    moveToNextTab();
  };

  const handleCancel = () => () => {
    updateProjectStep({
      projectId: project.id,
      projectStatus: 2,
      stepsPublished: false,
    }).then(() => {
      setDialogOpen(false);
      moveToNextTab();
    });
  };

  const handleClose = () => () => {
    setDialogOpen(false);
  };

  return (
    <>
      <TopHeader customerName={project.customer_name} userName={userName} />
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Typography className={classes.titleText}>
          Project Milestones
        </Typography>
        <TipButton
          onClick={() => {}}
          style={{ marginLeft: 15 }}
          enableHover
          hoverContent={
            <p className={classes.mangeMilesStonesToolTip}>{tooltipText}</p>
          }
        />
        {apiState.error && (
          <Typography style={{ color: "#d0021b" }}>
            Something went wrong!!
          </Typography>
        )}
        {apiState.loading && <Typography>Submitting.....</Typography>}
      </Grid>
      {milestones.map((milestone, idx) => {
        return (
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ marginTop: 15 }}
            key={milestone.id}>
            {editingMileStone?.id === milestone.id ? (
              <>
                <Grid container>
                  <Grid item>
                    <NarrowTextfield
                      width={"auto"}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setEditingMileStone({
                          ...editingMileStone,
                          description: event.target.value,
                        });
                      }}
                      value={editingMileStone.description}
                      placeholder="Milestone Name"
                    />
                    <Typography style={{ color: "#d0021b" }}>
                      {editFormValidation.name}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Grid container direction="row" justify="flex-end">
                      <Grid item>
                        <TextField
                          id="editingdate"
                          label="End Date"
                          type="date"
                          defaultValue={editingMileStone.completionDate}
                          onChange={(e) => {
                            setEditingMileStone({
                              ...editingMileStone,
                              completionDate: e.target.value,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Typography style={{ color: "#d0021b" }}>
                          {editFormValidation.date}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ marginTop: "auto" }}
                        onClick={() => {
                          handleEdit();
                        }}>
                        <SaveButton onClick={() => {}} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {editingMileStone.attachments?.map((attachment) => {
                      if (attachmentsToDelete.includes(attachment.id)) {
                        return <></>;
                      }
                      return (
                        <div style={{ display: "flex" }}>
                          <a
                            className={classes.ndaLink}
                            href={attachment.document_url}
                            target="_blank">
                            <SvgIcon
                              viewBox="0 0 35 35"
                              className={classes.iconRoot}>
                              <PDFIcon />
                            </SvgIcon>
                            {attachment.document_file_name}
                          </a>
                          <DeleteIcon
                            style={{ width: 17, height: 17 }}
                            onClick={() => {
                              setAttachmentsToDelete([
                                ...attachmentsToDelete,
                                attachment.id,
                              ]);
                            }}
                          />
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid container>
                    <AddButton
                      label={editFileUploaderLabel || "Add Attachment"}
                      onClick={() => {}}
                      style={{ marginTop: 10 }}
                      size={20}
                      fontSize={12}
                      file
                      accept={
                        ".pdf, tiff|image/*, .csv, .jpeg, .gif, .png, .xls, .xlsx, .ppt, .xlsx, .pptx, .docx, .doc"
                      }
                      onChange={onEditFileAttach}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Typography className={classes.subText}>
                    • {milestone.description}
                  </Typography>
                  {milestone.attachments?.map((attachment) => {
                    return (
                      <div>
                        <a
                          className={classes.ndaLink}
                          href={attachment.document_url}
                          target="_blank">
                          <SvgIcon
                            viewBox="0 0 35 35"
                            className={classes.iconRoot}>
                            <PDFIcon />
                          </SvgIcon>
                          {attachment.document_file_name}
                        </a>
                      </div>
                    );
                  })}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{ width: "max-content" }}>
                  <Typography className={classes.orangeText}>
                    {formatDates(milestone.completionDate, milestone.createdAt)}
                  </Typography>
                  <BubbleMenu
                    onEdit={() => {
                      setEditingMileStone(milestone);
                    }}
                    onDelete={() => {
                      openDeleteModal(milestone.id);
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
      { enabledProjectPhases.includes(project.stage) && 
        project.stage !== 'completion' && 
        project.stage !== 'completed_and_notified' &&
        <>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: 15 }}>
            <Grid item>
              <NarrowTextfield
                width={"auto"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (createFormValidation.name) {
                    setCreateFormValidation({ ...createFormValidation, name: "" });
                  }
                  setNewMilestoneName(event.target.value);
                }}
                value={newMilestoneName}
                placeholder="Milestone Name"
              />
              <Typography style={{ color: "#d0021b" }}>
                {createFormValidation.name}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="date"
                label="End Date"
                type="date"
                defaultValue={newMilestoneCompletionDate}
                onChange={(e) => {
                  if (createFormValidation.date) {
                    setCreateFormValidation({ ...createFormValidation, date: "" });
                  }
                  setNewMilestoneCompletionDate(new Date(e.target.value));
                }}
                //className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography style={{ color: "#d0021b" }}>
                {createFormValidation.date}
              </Typography>
            </Grid>
          </Grid>
          <AddButton
            label={fileUploaderLabel || "Add Attachment"}
            onClick={() => {}}
            style={{ marginTop: 10 }}
            size={20}
            fontSize={12}
            file
            accept={
              ".pdf, tiff|image/*, .csv, .jpeg, .gif, .png, .xls, .xlsx, .ppt, .xlsx, .pptx, .docx, .doc"
            }
            onChange={onFileAttach}
          />
          <AddButton
            onClick={createMilestone}
            label="Add New Milestone"
            style={{ marginTop: 10 }}
          />
        </>
      }
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 100 }}>
        <Grid item>
          <SmallButton onClick={openMilestoneDialog} color="green">
            PUBLISH
          </SmallButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageMilestones;
