import { useEffect, useState } from "react";
import snakecaseKeys from "snakecase-keys";

import { IWebSocketProps } from "Interfaces/props/webSocketWrapperProps";
import { useAppSelector } from "Redux/hooks";
import { getWebSocketToken } from "Redux/slices/userSlice";
import { actionCableConsumer } from "Utils/utils";

const WebSocket: React.FC<IWebSocketProps> = (props) => {
  const { children } = props;
  const webSocketoken = useAppSelector(getWebSocketToken);
  const { channel, params = {}, onMessageRecived } = props;
  const [receivedMessage, setReceivedMessage] = useState();
  useEffect(() => {
    actionCableConsumer(webSocketoken).subscriptions.create(
      {
        channel,
        ...snakecaseKeys(params),
        token: localStorage.getItem("access_token"),
        params: {
          channel,
          ...snakecaseKeys(params),
          token: localStorage.getItem("access_token"),
        },
      },
      {
        received: (data) => {
          setReceivedMessage(data)
        },
      }
    );
  }, [webSocketoken, channel, params]);

  useEffect(() => {
    const timer:ReturnType<typeof setTimeout> = setTimeout(() => {
      receivedMessage && onMessageRecived(receivedMessage)
    }, 200)
    return () => {
      clearTimeout(timer);
    }
  }, [receivedMessage])
  return <> {children}</>;
};

export default WebSocket;
