import { Grid, Typography } from "@material-ui/core";
import * as React from "react";

import AddButton from "Components/AddButton";
import NarrowTextfield from "Components/NarrowTextfield";
import Ribbon from "Components/Ribbon";
import SmallButton from "Components/SmallButton";
import DeleteButton from "Components/DeleteButton";
import { IApiState } from "Interfaces/apiState";
import { projectApi } from "Utils/api/projectApi";
import emailValidator from "Utils/emailValidator";
import { toBase64 } from "Utils/utils";
import useStyles from "Views/Admin/styles";
import TopHeader from "Views/Admin/TopHeader";

//status:approved
const CompletionMail = ({
  dialog: {
    setDialogOpen,
    setDialogSubTitle,
    setDialogTitle,
    setDialogButton,
    setDialogCancelButtonText,
    setDialogSubmitEvent,
    setDialogCancelEvent,
  },
  project,
  userName,
  moveToNextTab,
}: any) => {
  const [emailBody, setEmailBody] = React.useState({
    body: "",
    subject: "",
    ccEmails: "",
  });
  const [formValidation, setFormValidation] = React.useState({
    cc: "",
    subject: "",
    body: "",
  });
  const [apiState, setApiState] = React.useState<IApiState>({});
  const [attachments, setAttachments] = React.useState<any>([]);
  const [fileUploaderLabel, setFileUploaderLabel] = React.useState<
    string | undefined
  >();

  const classes = useStyles();

  const openCompletionDialog = () => {
    if (!validateForm()) {
      return;
    }
    setDialogOpen(true);
    setDialogTitle("Woo-Hoo!");
    setDialogSubTitle(
      "Looks like all of your tasks are done! Are you ready to complete this project?"
    );
    setDialogButton("I'm Ready");
    setDialogCancelButtonText(null);
    setDialogSubmitEvent(handlePublish);
    setDialogCancelEvent(handleClose);
  };

  const handleClose = () => () => {
    setDialogOpen(false);
  };

  const validateCCEMails = (emails: string) => {
    const emailArr = emails
      .split(";")
      .map((val: string) => val.trim())
      .filter((val) => val);
    return emailArr.filter((email: string) => !emailValidator(email)).length;
  };
  const handlePublish = () => () => {
    setApiState({ loading: true });
    projectApi
      .publishProject({
        projectId: project.id,
        id: project.id,
        status: "completion",
        ...emailBody,
        attachments: attachments,
      })
      .then(() => {
        setApiState({ loaded: true });
        setDialogOpen(false);
        moveToNextTab();
      })
      .catch((error) => {
        setApiState({ error: true, errorMsg: error.response?.data[0] });
        setDialogOpen(false);
      });
  };
  const validateForm = () => {
    let res = true;
    let validations = { body: "", cc: "", subject: "" };
    if (!emailBody.body.trim()) {
      validations = { ...validations, body: "Body cannot be empty!!" };
      res = false;
    }
    if (emailBody.ccEmails.trim() && validateCCEMails(emailBody.ccEmails)) {
      validations = {
        ...validations,
        cc: "Emails are not of correct format!!",
      };
      res = false;
    }
    if (!emailBody.subject) {
      validations = { ...validations, subject: "Subject cannot be empty!!" };
      res = false;
    }
    setFormValidation(validations);
    return res;
  };

  const clearValidation = () => {
    setFormValidation({
      cc: "",
      body: "",
      subject: "",
    });
  };

  const onAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files).map(async (file) => {
      return {
        filename: file.name,
        content_type: file.type,
        file: await toBase64(file),
      };
    });
    Promise.all(files).then((encodedFiles) => {
      setAttachments([...attachments, ...encodedFiles]);
    });
    setFileUploaderLabel(event.target.files[0].name);
    event.target.value = "";
  };

  return (
    <>
      {project.stage === "completed_and_notified" ? (
        <Ribbon
          text="Project Complete!"
          color="green"
          className={classes.ribbonRoot}
        />
      ) : (
        <>
          <TopHeader customerName={project.customer_name} userName={userName} />
          {apiState.error && (
            <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography style={{ color: "#d0021b" }}>
                {apiState.errorMsg || "Something went wrong!!"}
              </Typography>
            </Grid>
          )}
          {apiState.loading && (
            <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography>Submitting.....</Typography>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography
                className={classes.subText}
                style={{ marginRight: 5 }}
              >
                CCA:
              </Typography>
            </Grid>
            <Grid item>
              <NarrowTextfield
                onChange={(e) => {
                  if (formValidation.cc) {
                    clearValidation();
                  }
                  setEmailBody({ ...emailBody, ccEmails: e.target.value });
                }}
                width={275}
                placeholder="Emails separated by ;"
                value={emailBody.ccEmails}
              />

              <Typography style={{ color: "#d0021b" }}>
                {formValidation.cc}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <Grid item>
              <Typography
                className={classes.subText}
                style={{ marginRight: 5 }}
              >
                Subject:
              </Typography>
            </Grid>
            <Grid item>
              <NarrowTextfield
                onChange={(e) => {
                  if (formValidation.subject) {
                    clearValidation();
                  }
                  setEmailBody({ ...emailBody, subject: e.target.value });
                }}
                width={275}
                placeholder="Project Complete"
              />
              <Typography style={{ color: "#d0021b" }}>
                {formValidation.subject}
              </Typography>
            </Grid>
          </Grid>
          <NarrowTextfield
            multiline
            height={500}
            onChange={(e) => {
              if (formValidation.body) {
                clearValidation();
              }
              setEmailBody({ ...emailBody, body: e.target.value });
            }}
            placeholder="Enter email body here..."
            style={{ marginTop: 15, height: 100 }}
            responsive
          />
          <Typography style={{ color: "#d0021b" }}>
            {formValidation.body}
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: 15 }}
          >
            <Grid item>
              <AddButton
                label={fileUploaderLabel || "Add Attachment"}
                onClick={() => {}}
                file
                accept={
                  ".pdf, tiff|image/*, .csv, .jpeg, .gif, .png, .xls, .xlsx, .ppt, .xlsx, .pptx, .docx, .doc"
                }
                onChange={onAddFile}
              />
            </Grid>
            {attachments.length ? (
              <Grid item>
                <DeleteButton
                  onClick={() => {
                    setAttachments([]);
                    setFileUploaderLabel("Add Attachment");
                  }}
                />
              </Grid>
            ) : null}
            <Grid item>
              <SmallButton color="green" onClick={openCompletionDialog}>
                SEND
              </SmallButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default CompletionMail;
