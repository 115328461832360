import {Grid, makeStyles, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import AddButton from "./AddButton";
import * as React from "react";
import {headerCss, hideMobile, showMobile} from "../Utils/utils";
import { NewProjectButtonProps } from "Interfaces/props/newProjectButtonProps";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  createText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#01366c",
    marginRight: 10,
  },
  showMobile: showMobile(theme) as any,
  hideMobile: hideMobile(theme) as any,
  mobileHeaderText: {
    marginBottom: 25,
  },
  marginRoot: { marginTop: 25, marginBottom: 25 },
}));

const NewProjectButton = (props: NewProjectButtonProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{ width: "max-content" }}
    >
      <Grid item>
        <Typography className={classes.createText}>
          <Link to="/product-launcher">Create New Project </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Link to="/product-launcher">
          <AddButton onClick={props.onClick} />
        </Link>
      </Grid>
    </Grid>
  );
};

export default NewProjectButton;