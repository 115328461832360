import { useState } from "react";
import { getNDAValue, post } from "Utils/utils";
import { InfoPanel } from "Components/InfoPanel";
import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import BlueColumnHeaders from "Components/BlueColumnHeaders";
import Ribbon from "Components/Ribbon";
import MessageTextField from "Components/MessageTextField";
import CommercialAssessment from "Components/CommercialAssessment";
import TechnicalAssessment from "Components/TechnicalAssessment";
import ConfirmDialog from "Components/ConfirmDialog";
import NavText from "Components/NavText";
import useStyles from "./styles";
import LogMessage from "./LogMessage";
import AddNotes from "./RightPanel/AddNotes";
import { useAppSelector } from "Redux/hooks";
import { getUserGroup, getUserId, getUserName } from "Redux/slices/userSlice";
import ManageMilestones from "./RightPanel/ManageMilestones";
import ManageSubTasks from "./RightPanel/ManageSubTasks";
import CompletionMail from "./RightPanel/CompletionMail";
import {
  fetchProject,
  getActiveProject,
} from "../../Redux/slices/projectsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { addPrivateMessage } from "Redux/slices/projectsSlice";
import { formatDateTimeForNotes } from "Utils/timeFormats";
import SmallButton from "Components/SmallButton";
import { useHistory } from "react-router";
import LargeButton from "Components/LargeButton";
import LPRDialog from "Components/LPRDialog";
import { projectApi } from "Utils/api/projectApi";

const rightPanels = ["Notes", "Plan", "Progress", "Completion"];

let rightPanelsMarker: { [property: string]: any };
rightPanelsMarker = {
  plan: 2,
  progress: 3,
  completion: 4,
  completed_and_notified: 4,
};

const enabledProjectPhases = [
  "unclaimed",
  "plan",
  "progress",
  "completion",
  "completed_and_notified",
];

const canEditCA = ["TechnicalSalesRepresentative"];
const canEditTA = ["LabWorker", "LabManager"];

export default function AdminProjectDetails() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const project = useAppSelector(getActiveProject);
  const userId = useAppSelector(getUserId);
  const userName = useAppSelector(getUserName);
  const userRole: string = useAppSelector(getUserGroup) || "";

  const [leftPanel, setLeftPanel] = React.useState(0);
  const [rightPanel, setRightPanel] = React.useState(0);
  const [mobilePanel, setMobilePanel] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [lprDialogOpen, setLprDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogSubTitle, setDialogSubTitle] = React.useState("");
  const [dialogButton, setDialogButton] = React.useState("");
  const [dialogSubmitEvent, setDialogSubmitEvent] = React.useState<any>();
  const [dialogCancelEvent, setDialogCancelEvent] = React.useState<any>();
  const [caErrors, setCAErrors] = React.useState<Function>(() => {
    if (localStorage.getItem("labId")) {
      return false;
    }
    return true;
  });
  const [showCAErrors, setShowCAErrors] = React.useState<boolean>(false);
  const history = useHistory();

  const [placeholder, setPlaceHolder] = useState(
    "Add any notes or comments about this project to send to the team."
  );
  const [emptyMessage, setEmptyMessage] = useState(false);

  const [dialogCancelButtonText, setDialogCancelButtonText] = React.useState<
    string | null
  >(null);
  const classes = useStyles();

  const dialogChildProps = {
    setDialogOpen,
    setDialogSubTitle,
    setDialogTitle,
    setDialogButton,
    setDialogCancelButtonText,
    setDialogSubmitEvent,
    setDialogCancelEvent,
  };
  let leftElement = <></>;
  let rightElement = <></>;

  const technicalAssessmentEditable =
    canEditTA.includes(userRole) &&
    ["technical_assessment", "vp_approval"].includes(
      project?.stage || "vp_approval"
    );
  const commercialAssessmentEditable = canEditCA.includes(userRole);

  React.useEffect(() => {
    if (!Object.keys(project).length) {
      dispatch(fetchProject(params.projectId));
    }
  }, [dispatch]);

  const onNewMessage = (body: string) => {
    if (!body) {
      setEmptyMessage(true);
      return;
    }
    post("/notes/", {
      note: {
        body,
        project_id: project.id,
        is_private: true,
        user_id: userId,
      },
    }).then((response) => {
      dispatch(addPrivateMessage(response.data));
    });
  };

  const moveToNextTab = (setToProjectStatus?: boolean) => {
    if (!enabledProjectPhases.includes(project.stage)) {
      return;
    }
    if (setToProjectStatus) {
      if (project.stage === "plan") {
        setRightPanel(1);
      } else if (project.stage === "progress") {
        setRightPanel(2);
      } else {
        setRightPanel(3);
      }
      return;
    }
    if (rightPanel <= 3) {
      setRightPanel(rightPanel + 1);
    }
  };

  const rightPanelDisabledIdx = () => {
    if (!enabledProjectPhases.includes(project.stage)) {
      return [1, 2, 3];
    }
    if (rightPanel !== -1) {
      const currentProjectStage: string = project.stage;
      console.log(currentProjectStage);
      let result = [0, 1, 2, 3];
      result = result.filter(
        (val) => val > rightPanelsMarker[currentProjectStage]
      );
      return result;
    }
    return [];
  };

  const formatDate = (date: Date) => {
    const formatDate = new Date(date);
    return (
      formatDate.getFullYear() +
      "/" +
      (formatDate.getMonth() + 1) +
      "/" +
      formatDate.getDate()
    );
  };

  switch (leftPanel) {
    case 0:
      leftElement = (
        <>
          <Typography className={classes.titleText}>Customer</Typography>
          <Typography className={classes.subText}>{project.contact}</Typography>

          <Typography className={classes.titleText}>Kick-Off Date</Typography>
          <Typography className={classes.subText}>
            {formatDate(project.submitted_at)}
          </Typography>

          <Typography className={classes.titleText}>
            Assigned Chemist
          </Typography>
          <Typography className={classes.subText}>{project.chemist}</Typography>

          <Typography className={classes.titleText}>Contact</Typography>
          <Typography className={classes.subText}>
            {project.customer_name}
          </Typography>
          <Typography className={classes.titleText}>TSR</Typography>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={classes.subText}>{project.tsr}</Typography>
            </Grid>
            <Grid
              item
              style={{ marginLeft: "auto", marginRight: "auto" }}
              onClick={() => {
                history.push(`/admin/assign-tsr/${project.id}`);
              }}
            >
              {/*<SmallButton color="blue" children="CHANGE TSR"></SmallButton>*/}
            </Grid>
          </Grid>

          <CommercialAssessment
            editable={commercialAssessmentEditable}
            id={project.id}
            nda={getNDAValue(project) || -1}
            ndaLink={project.commercial_assessment?.nda}
            saleEta={project.commercial_assessment?.sales_eta_in_days}
            customerEta={project.commercial_assessment?.customer_eta_in_days}
            arp={project.commercial_assessment?.annual_revenue_potential}
            notes={project.private_notes}
            successProbability={
              project.commercial_assessment?.success_probability
            }
            products={project.commercial_assessment?.lines}
            apiState={{ loaded: true }}
            setCAErrors={() => {}}
          />
          <TechnicalAssessment
            editable={technicalAssessmentEditable}
            id={project.id}
            eta={project.technical_assessment?.lab_eta_in_days}
            lab={project.lab}
            probability={project.technical_assessment?.success_probability}
            chemist={project.chemist}
            setCAErrors={setCAErrors}
            updateProjectTA={() => {}}
          />
          <Typography className={classes.subHeaderText}>Application</Typography>
          <InfoPanel project={project} canEdit={false} />
        </>
      );
      break;
    case 1:
      leftElement = (
        <>
          <Ribbon
            className={classes.ribbonRoot}
            color="green"
            text="For Azelis Eyes Only!"
          />
          {project.private_notes
            .map(
              (note: {
                user: { id: string; name: string };
                body: string;
                created_at: string;
                id: string;
              }) => {
                return {
                  self: userId === note.user.id,
                  message: note.body,
                  title: note.user.name,
                  date: note.created_at,
                  id: note.id,
                };
              }
            )
            .map(
              (note: {
                self: boolean;
                message: string;
                title: string;
                date: string;
                id: string;
              }) => {
                return (
                  <LogMessage
                    date={formatDateTimeForNotes(note.date)[0]}
                    time={formatDateTimeForNotes(note.date)[1]}
                    content={note.message}
                    onDelete={() => {}}
                    id={note.id}
                    key={note.id}
                    canDelete={note.self}
                  />
                );
              }
            )}
          <MessageTextField
            placeholder={placeholder}
            onSend={onNewMessage}
            className={classes.textfield}
            onChangeCallBack={() => {
              if (emptyMessage) {
                setEmptyMessage(false);
              }
            }}
          />
          {emptyMessage && (
            <Typography
              className={classes.subHeaderText}
              style={{ color: "#d0021b" }}
            >
              Cannot be empty!!
            </Typography>
          )}
        </>
      );
      break;
    default:
      break;
  }
  switch (rightPanel) {
    case 0:
      rightElement = (
        <AddNotes
          userId={userId}
          projectId={project.id}
          moveToNextTab={moveToNextTab}
          hideSend={!enabledProjectPhases.includes(project.stage)}
          notes={project.public_notes}
        />
      );
      break;
    case 1:
      rightElement = (
        <ManageMilestones
          dialog={dialogChildProps}
          project={project}
          moveToNextTab={moveToNextTab}
          enabledProjectPhases={enabledProjectPhases}
          userName={userName}
        />
      );
      break;
    case 2:
      rightElement = (
        <ManageSubTasks
          dialog={dialogChildProps}
          project={project}
          moveToNextTab={moveToNextTab}
          userName={userName}
        />
      );
      break;
    case 3:
      rightElement = (
        <CompletionMail
          dialog={dialogChildProps}
          project={project}
          moveToNextTab={moveToNextTab}
          userName={userName}
        />
      );
      break;
    default:
      break;
  }

  const editable = userRole === "TechnicalSalesRepresentative";
  const title = project.name;

  const markCompleteSubmission = () => {
    const projectId = project?.id || "";
    if (canEditTA.includes(userRole)) {
      projectApi.updateTechnical(projectId, {
        technical_assessment: {
          is_submitted: true,
        },
      });
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <ConfirmDialog
        open={dialogOpen}
        handleClose={dialogCancelEvent}
        titleText={dialogTitle}
        subtext={dialogSubTitle}
        buttonText={dialogButton}
        cancelButtonText={dialogCancelButtonText}
        onSubmit={dialogSubmitEvent}
      />
      <LPRDialog
        isOpen={lprDialogOpen}
        handleClose={(goBack: boolean) => {
          setLprDialogOpen(false);
          if (goBack) {
            history.push("/admin/project");
          }
        }}
        isUser={!editable}
        productName={title}
        onSubmit={markCompleteSubmission}
      />
      <Grid item className={classes.hideMobile}>
        <Typography className={classes.headerText}>{project.name}</Typography>
      </Grid>
      <Grid container className={classes.showMobile}>
        <Grid item>
          <Typography className={classes.headerText}>{project.name}</Typography>
        </Grid>
        <Grid item style={{ margin: "auto", marginTop: 25, marginBottom: 25 }}>
          <BlueColumnHeaders
            small
            labels={["Reference", "Progress"]}
            selectedIdx={mobilePanel}
            onClick={(idx: number) => {
              setMobilePanel(idx);
            }}
          />
        </Grid>
        {mobilePanel === 0 ? (
          <Grid item style={{ width: "100%" }}>
            <Grid container direction="row">
              {["LPR", "Project", "Notes"].map((label, idx) => {
                return (
                  <NavText
                    text={label}
                    onClick={() => {
                      setLeftPanel(idx);
                    }}
                    isActive={leftPanel === idx}
                  />
                );
              })}
            </Grid>
            {leftElement}
          </Grid>
        ) : (
          <Grid item style={{ width: "100%" }}>
            <Grid container direction="row">
              {rightPanels.map((label, idx) => {
                return (
                  <NavText
                    text={label}
                    onClick={() => {}}
                    isActive={rightPanel === idx}
                  />
                );
              })}{" "}
            </Grid>
            {rightElement}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        className={classes.panelRoot + " " + classes.hideMobile}
        style={{ paddingBottom: 25 }}
      >
        <Grid item className={classes.leftPanel}>
          <Typography className={classes.subHeaderText}>Reference</Typography>
          <BlueColumnHeaders
            small
            labels={["Project", "Notes"]}
            onClick={setLeftPanel}
            selectedIdx={leftPanel}
          />
          {leftElement}
        </Grid>
        <Grid item className={classes.rightPanel}>
          <Typography className={classes.subHeaderText}>Progress</Typography>
          <BlueColumnHeaders
            small
            labels={rightPanels}
            onClick={(idx) => {
              const currentProjectStage: keyof typeof rightPanelsMarker =
                project.stage;
              if (idx <= rightPanelsMarker[currentProjectStage]) {
                setRightPanel(idx);
              }
            }}
            selectedIdx={rightPanel}
            disabledIdx={rightPanelDisabledIdx()}
          />
          {rightElement}
        </Grid>
        <Grid item style={{ margin: "auto", marginTop: 15, marginBottom: 15 }}>
          {showCAErrors && (
            <div style={{ color: "red" }}>
              Please enter all of the required fields to mark as complete(*).
            </div>
          )}
          {(commercialAssessmentEditable || technicalAssessmentEditable) && (
            <LargeButton
              color="green"
              text="MARK COMPLETE"
              onClick={() => {
                console.log("here", caErrors);
                if (caErrors) {
                  setShowCAErrors(true);
                  return;
                } else if (showCAErrors) {
                  setShowCAErrors(false);
                }
                setLprDialogOpen(true);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
