import { Grid, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import Dialog from 'Components/Dialog';
import LargeButton from 'Components/LargeButton';
import { ILPRDialogProps } from 'Interfaces/props/lprSuccessDialogProps';

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'MuseoSlab',
    fontSize: '28px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#01366c',
  },
  subTitleText: {
    fontFamily: 'MuseoSlab',
    fontSize: '15px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#01366c',
    margin: 'auto',
    width: 325,
    marginTop: 20,
  },
  textFieldLabel: {
    fontFamily: 'MuseoSlab',
    fontSize: '19px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.32',
    letterSpacing: '0.24px',
    color: '#003570',
  },
}));

export default function LPRSuccessDialog(props: ILPRDialogProps) {
  const classes = useStyles();

  return (
    <Dialog open={props.isOpen} handleClose={props.handleClose}>
      <Grid container direction='column'>
        <Typography className={classes.titleText}>Success!</Typography>
        <Typography className={classes.subTitleText}>
          {props.successMessage}
        </Typography>
        <LargeButton
          color='blue'
          text='AWESOME'
          style={{ margin: 'auto', marginTop: 15 }}
          onClick={props.handleClose}
        />
      </Grid>
    </Dialog>
  );
}
