import { Grid, makeStyles } from "@material-ui/core";
import { ISearchBarProps } from "Interfaces/props/searchBarProps";
import * as React from "react";
import { ReactComponent as SearchIcon } from "../Images/magnifying-glass.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: ISearchBarProps) => props.width || 500,
    padding: "8px 42px 8px 24px",
    "border-radius": "100px",
    border: "solid 2.5px #01366c",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "-webkit-box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
  },
  content: {
    margin: (props: ISearchBarProps) => (props.centered ? "auto" : undefined),
    width: (props: ISearchBarProps) =>
      props.centered ? "max-content" : undefined,
  },
  input: {
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    outline: "none",
    fontSize: 28,
    height: 34,
    width: (props: ISearchBarProps) =>
      props.centered && props.placeholder
        ? 16 * (props.placeholder.length + 2)
        : undefined,
    textOverflow: "ellipsis",
    "&::placeholder": {
      "font-family": "MuseoSlab",
      "font-size": "28px",
      "font-weight": 300,
      "font-stretch": "normal",
      "font-style": "normal",
      "line-height": 1.25,
      "letter-spacing": "1px",
      color: "#01366c",
      textOverflow: "ellipsis",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  iconRoot: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
let searchInterval: ReturnType<typeof setInterval>;

export default function SearchBar(props: ISearchBarProps) {
  const classes = useStyles(props);
  return (
    <Grid
      container
      className={classes.root + " " + props.className}
      style={props.style}
    >
      <Grid container direction="row" className={classes.content}>
        <Grid item className={classes.iconRoot}>
          <SearchIcon />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <input
            placeholder={props.placeholder}
            className={classes.input}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              clearInterval(searchInterval);
              const string = event.target.value;
              searchInterval = setInterval(() => {
                if (props.onChange) props.onChange(string);
                clearInterval(searchInterval);
              }, props.debounceTime || 1000);
            }}
          ></input>
        </Grid>
      </Grid>
    </Grid>
  );
}
