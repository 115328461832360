import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Option from "../Components/MultipleChoiceOption";
import NarrowTextfield from "../Components/NarrowTextfield";
import SmallButton from "../Components/SmallButton";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import {
  login,
  signUpCustomer,
  getUserFormError, resetPassword,
} from "../Redux/slices/userSlice";
import { headerCss, post } from "../Utils/utils";
import emailValidator from "../Utils/emailValidator";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import {useHistory, useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    width: "975px",
    height: "65px",
    fontFamily: "'museo-slab'",
    fontWeight: 100,
    fontSize: "54px",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  subHeaderText: {
    width: "750px",
    height: "100px",
    fontFamily: "'varela_roundregular'",
    fontSize: "19px",
    lineHeight: "1.3",
    letterSpacing: "0.2px",
    textAlign: "center",
    color: "#01366c",
    marginTop: "50px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textFieldError: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    color: "#FFFFFF",
    backgroundColor: "#d0021b",
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const apiError = useAppSelector(getUserFormError);
  const dispatch = useAppDispatch();
  const { token } = useParams<{ token: string}>();
  const history = useHistory();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [resetComplete, setResetComplete] = React.useState(false);
  const [fieldErrors, setFieldErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [showFieldErrors, setShowFieldErrors] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState("");
  React.useEffect(() => {
    if (apiError?.signup) {
      setFieldErrors({
        email: apiError?.signup.msg[0],
      });
      setShowFieldErrors(true);
    }
  }, [apiError]);

  const getFieldValue = (fieldType: string) => {
    if (fieldErrors[fieldType] && showFieldErrors) {
      return fieldErrors[fieldType];
    }
    if (fieldType === "password") return password;
  };

  const getClass = (fieldType: string) => {
    if (showFieldErrors && fieldErrors[fieldType]) {
      return classes.textFieldError;
    }
    return classes.textField;
  };

  const validateFormFields = () => {
    let result = true;
    let evaluateFieldErrors: { [key: string]: string } = {
      password: "",
    };
    if (!password || password.length < 6) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        password: "Password too short",
      };
    } else if (password !== confirmPassword) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        password: "Passwords do not match",
      };
    }
    setFieldErrors(evaluateFieldErrors);
    return result;
  };

  const handleFieldFocus = () => {
    if (showFieldErrors) {
      setShowFieldErrors(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}>
      <Grid item>
        <Typography className={classes.headerText}>
          Reset Password
        </Typography>
      </Grid>
      <Grid item>
        {!resetComplete && (
          <Typography className={classes.subHeaderText}>
          Create a new password:
        </Typography>
        )}
        {resetComplete && (
          <>
            <Typography className={classes.subHeaderText}>
              Your password was successfully reset.
            </Typography>
          </>
        )}
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        {!resetComplete && (
          <>
            <NarrowTextfield
              placeholder="PASSWORD"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              className={getClass("password")}
              onFocus={handleFieldFocus}
              value={getFieldValue("password")}
              isPassword={!showFieldErrors}
            />
            <NarrowTextfield
              placeholder="CONFIRM PASSWORD"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value);
              }}
              value={confirmPassword}
              className={classes.textField}
              onFocus={handleFieldFocus}
              isPassword
            />
            {apiErrors && (
            <p style={{textAlign: 'center'}}>
              {apiErrors}.<br />
              If you continue to have issues, contact your Azelis Representative.
            </p>
            )}
            <SmallButton
              color="green"
              onClick={() => {
                if (!validateFormFields()) {
                  setShowFieldErrors(true);
                  return;
                }
                dispatch(resetPassword({ password: password, token: token })).then((response) => {
                  console.log(response);
                  if(response.payload.status === 'ok'){
                    setResetComplete(true);
                  }else {
                    setApiErrors(response.payload.message);
                  }
                })
              }}>
              Reset Password
            </SmallButton>
          </>
        )}
        {resetComplete && (
          <SmallButton
            color="green"
            onClick={() => {history.push('/login')}}>
            Back to login
          </SmallButton>
        )}
      </Grid>
    </Grid>
  );
}
