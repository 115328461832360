import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import RoundedRight from "../Images/rounded-left.svg";
import RoundedRightHover from "../Images/rounded-left-hover.svg";
import Rectangle from "../Images/rectangle.svg";
import RectangleHover from "../Images/rectangle-hover.svg";
import RoundedLeft from "../Images/rounded-right.svg";
import RoundedLeftHover from "../Images/rounded-right-hover.svg";

import RoundedRightSmall from "../Images/rounded-right-small.svg";
import RoundedRightHoverSmall from "../Images/rounded-right-small-hover.svg";
import RectangleSmall from "../Images/rectangle-small.svg";
import RectangleHoverSmall from "../Images/rectangle-small-hover.svg";
import RoundedLeftSmall from "../Images/rounded-left-small.svg";
import RoundedLeftHoverSmall from "../Images/rounded-left-small-hover.svg";

import RectangleSmallDisabled from "../Images/rectangle-small-disabled.svg";
import RoundedRightSmallDisabled from "../Images/rounded-right-small-disabled.svg";

import RoundedRightLarge from "../Images/rounded-right-large.svg";
import RoundedLeftLargeHover from "../Images/rounded-left-large-hover.svg";
import { IBlueHeaderTileProps } from "Interfaces/props/blueHeaderTileProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    height: 54,
    cursor: "pointer",
    marginRight: (props: IBlueHeaderTileProps) =>
      props.type === "end" ? undefined : -3,
  },
  largeRoot: {
    width: 476,
    height: 54,
    cursor: "pointer",
    marginRight: (props: IBlueHeaderTileProps) =>
      props.type === "end" ? undefined : -3,
  },
  smallRoot: {
    width: 145,
    height: 54,
    cursor: "pointer",
    padding: 5,

    marginRight: (props: IBlueHeaderTileProps) =>
      props.type === "end" ? undefined : -3,
  },
  rightRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) =>
      props.selected ? `url(${RoundedRightHover})` : `url(${RoundedRight})`,
    "&:hover": {
      backgroundImage: `url(${RoundedRightHover})`,
    },
  },
  mainRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) =>
      props.selected ? `url(${RectangleHover})` : `url(${Rectangle})`,
    "&:hover": {
      backgroundImage: `url(${RectangleHover})`,
    },
  },
  leftRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) =>
      props.selected ? `url(${RoundedLeftHover})` : `url(${RoundedLeft})`,
    "&:hover": {
      backgroundImage: `url(${RoundedLeftHover})`,
    },
  },
  smallRightRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) => {
      if (props.selected) {
        return `url(${RoundedRightHoverSmall})`;
      }
      if (props.disabled) {
        return `url(${RoundedRightSmallDisabled})`;
      }
      return `url(${RoundedRightSmall})`;
    },
    "&:hover": {
      backgroundImage: (props: IBlueHeaderTileProps) => {
        if (props.disabled) {
          return `url(${RoundedRightSmallDisabled})`;
        }
        return `url(${RoundedRightHoverSmall})`;
      },
    },
  },
  smallMainRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) => {
      if (props.selected) {
        return `url(${RectangleHoverSmall})`;
      }
      if (props.disabled) {
        return `url(${RectangleSmallDisabled})`;
      }
      return `url(${RectangleSmall})`;
    },
    "&:hover": {
      backgroundImage: (props: IBlueHeaderTileProps) => {
        if (props.disabled) {
          return `url(${RectangleSmallDisabled})`;
        }
        return `url(${RectangleHoverSmall})`;
      },
    },
  },
  smallLeftRoot: {
    backgroundImage: (props: IBlueHeaderTileProps) =>
      props.selected
        ? `url(${RoundedLeftHoverSmall})`
        : `url(${RoundedLeftSmall})`,
    "&:hover": {
      backgroundImage: `url(${RoundedLeftHoverSmall})`,
    },
  },
  text: {
    fontFamily: "VarelaRound",
    fontSize: (props: IBlueHeaderTileProps) => (props.small ? 17 : 19),
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: (props: IBlueHeaderTileProps) =>
      props.disabled ? "#01366c" : "#ffffff",
  },
  roundedRightLarge: {
    backgroundImage: `url(${RoundedRightLarge})`,
  },
  roundedLeftLarge: {
    backgroundImage: `url(${RoundedLeftLargeHover})`,
  },
}));

export default function BlueHeaderTile(props: IBlueHeaderTileProps) {
  const classes = useStyles(props);
  let pictureClass = "";
  let rootClass = props.large
    ? classes.largeRoot
    : props.small
    ? classes.smallRoot
    : classes.root;
  switch (props.type) {
    case "start":
      pictureClass = props.large
        ? classes.roundedLeftLarge
        : props.small
        ? classes.smallLeftRoot
        : classes.leftRoot;
      break;
    case "middle":
      pictureClass = props.small ? classes.smallMainRoot : classes.mainRoot;
      break;
    case "end":
      pictureClass = props.large
        ? classes.roundedRightLarge
        : props.small
        ? classes.smallRightRoot
        : classes.rightRoot;
      break;
    default:
      break;
  }
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={pictureClass + " " + rootClass}
      onClick={props.onClick}>
      <Typography style={{ display: "block" }} className={classes.text}>
        {props.label}
      </Typography>
    </Grid>
  );
}
