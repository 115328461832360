import { makeStyles } from "@material-ui/core";
import { ICommentBoxProps } from "Interfaces/props/commentBoxProps";
import * as React from "react";
import Message from "./Message";
import MessageTextField from "./MessageTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: ICommentBoxProps) => props.width || 500,
    minHeight: 300,
    //padding: "8px 16px 7px 15px"
    paddingLeft: 25,
    paddingTop: 5,
    paddingRight: 25,
    paddingBottom: 20,
    "border-radius": "50px",
    border: "solid 2.5px #01366c",
    backgroundColor: "rgba(0,0,0,0.05)",
    "-webkit-box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export default function CommentBox(props: ICommentBoxProps) {
  const classes = useStyles(props);
  return (
    <div className={classes.root + " " + props.className}>
      {props.comments.length === 0 ? <div /> : <></>}
      {props.comments.map((comment, idx) => {
        return (
          <div key={idx}>
            <br />
            <Message {...comment} self={false} />
          </div>
        );
      })}
      {props.editable ? (
        <div>
          <br />
          <br />
          <MessageTextField
            onSend={props.onNewMessage || console.log}
            messageTextFieldWidth={props.messageTextFieldWidth}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
