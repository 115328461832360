import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import GreenButton from "../Images/green-button-large.svg";
import GreenButtonHover from "../Images/green-button-large-hover.svg";
import OrangeButton from "../Images/orange-button.svg";
import OrangeButtonHover from "../Images/orange-button-hover.svg";
import BlueButton from "../Images/large-blue-button.svg";
import BlueButtonHover from "../Images/large-blue-button-hover.svg";
import { ILargeButtonProps } from "Interfaces/props/largeButtonProps";

const baseCss = {
  width: "250px",
  height: "50px",
  display: "flex",
  margin: 25,
  backgroundRepeat: "no-repeat",
};

const useStyles = makeStyles((theme) => ({
  greenRoot: {
    ...baseCss,
    backgroundImage: `url(${GreenButton})`,
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${GreenButtonHover})`,
    },
  },
  disabledGreenRoot: {
    ...baseCss,
    backgroundImage: `url(${GreenButton})`,
    opacity: 0.5,
  },
  orangeRoot: {
    ...baseCss,
    backgroundImage: `url(${OrangeButton})`,
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${OrangeButtonHover})`,
    },
  },
  disabledOrangeRoot: {
    ...baseCss,
    backgroundImage: `url(${OrangeButton})`,
    opacity: 0.5,
  },
  blueRoot: {
    ...baseCss,
    backgroundImage: `url(${BlueButton})`,
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${BlueButtonHover})`,
    },
  },
  disabledBlueRoot: {
    ...baseCss,
    backgroundImage: `url(${BlueButton})`,
    opacity: 0.5,
  },
  buttonText: {
    "font-family": "MontserratBold",
    "font-size": "14px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    "letter-spacing": "1.5px",
    "text-align": "center",
    color: "#01366c",
  },
  iconRoot: {
    marginRight: 5,
  },
}));

export default function LargeButton(props: ILargeButtonProps) {
  const classes = useStyles();
  let disabledClass = "";
  let rootClass = "";
  switch (props.color) {
    case "orange":
      disabledClass = classes.disabledOrangeRoot;
      rootClass = classes.orangeRoot;
      break;
    case "green":
      disabledClass = classes.disabledGreenRoot;
      rootClass = classes.greenRoot;
      break;
    case "blue":
      disabledClass = classes.disabledBlueRoot;
      rootClass = classes.blueRoot;
      break;
    default:
      break;
  }
  let icon = <></>;
  if (props.icon)
    icon = React.cloneElement(props.icon as any, {
      className: classes.iconRoot,
    });
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={
        (props.disabled ? disabledClass : rootClass) + " " + props.className
      }
      onClick={props.onClick}
      style={props.style}
    >
      {icon}
      <Typography className={classes.buttonText}>{props.text}</Typography>
    </Grid>
  );
}
