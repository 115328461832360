import { Grid, makeStyles, Typography } from "@material-ui/core";
import { INavTextProps } from "Interfaces/props/navTextProps";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  active: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
    textDecoration: "underline",
  },
  inactive: {
    opacity: "0.5",
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.3",
    letterSpacing: "0.19px",
    textAlign: "center",
    color: "#01366c",
  },
}));

export default function NavText(props: INavTextProps) {
  const classes = useStyles();
  return (
    <Grid item style={{ width: "max-content", margin: "auto" }}>
      <Typography
        className={props.isActive ? classes.active : classes.inactive}
        onClick={props.onClick}
        style={{ padding: 12 }}
      >
        {props.text}
      </Typography>
    </Grid>
  );
}
