import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { get, post } from "../../Utils/utils";
import { RootState } from "../store";
import jwt_decode from "jwt-decode";
import { UserState, ILoginInfo, ISignupInfo, IUserInfo} from "Interfaces/user";

// Define the initial state using that type
const initialState: UserState = {
  profile_type: '',
  isAuthed: false,
  apiError: null,
  websocket_token: ''
};

export const login = createAsyncThunk(
  "user/login",
  async (loginInfo: ILoginInfo) => {
    loginInfo.account_name = undefined;
    if (loginInfo.jwt) {
      return jwt_decode(loginInfo.jwt);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/user_token`,
        { auth: loginInfo }
      );
      localStorage.setItem("access_token", response.data.jwt);
      return jwt_decode(response.data.jwt);
    } catch (e) {
      return {};
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signup",
  async (signupInfo: ISignupInfo) => {
    try {
      const response = await post("/users", signupInfo);
      return response.data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
);

export const signUpCustomer = createAsyncThunk(
  "user/signupcustomers",
  async (signupInfo: ISignupInfo) => {
    try {
      const response = await post("/customers", signupInfo);
      localStorage.setItem("access_token", response.data.jwt);
      return response.data;
    } catch (e) {
      console.error(e);
      return {
        error: {
          code: e.response.status,
          msg: e.response.data || ["Something went wrong!!"],
        },
      };
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/password/forgot",
  async (email: string) => {
    try {
      const response = await post("/passwords/forgot", {email: email});
      return response.data;
    } catch (e) {
      console.error(e);
      return {
        error: {
          code: e.response.status,
          msg: e.response.data || ["Something went wrong!!"],
        },
      };
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/password/reset",
  async (resetPasswordFields: {password: string, token: string}) => {
    try {
      const response = await post("/passwords/reset", resetPasswordFields);
      return response.data;
    } catch (e) {
      console.error(e);
      return {
        error: {
          code: e.response.status,
          msg: e.response.data || ["Something went wrong!!"],
        },
      };
    }
  }
);

export const createUser = createAsyncThunk(
  "user/create",
  async (userInfo: IUserInfo) => {
    try {
      const response = await post("/users/create_profile", userInfo);
      return response.data;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
);

export const getCurrentUsersInfo = createAsyncThunk(
  "user/profile",
  async () => {
    try {
      const response = await get("/customers/me");
      return response.data;
    } catch (e) {
      console.error(e);
      return {
        error: {
          code: e.response.status,
          msg: e.response.data || ["Something went wrong!!"],
        },
      };
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthed = false;
      localStorage.removeItem("access_token");
      localStorage.removeItem("privacy_policy_accepted");
    },
    acceptPrivacyPolicy: (state) => {
      state.privacy_policy_accepted = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { payload }: any = action;
      if (!Object.keys(payload).length) {
        state.isAuthed = false;
        state.apiError = { login: "Invalid Email or password" };
        return state;
      }
      return {
        ...payload,
        isAuthed: true,
        apiError: null,
        name: payload.name,
        email: payload.email,
      };
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isAuthed = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {});
    builder.addCase(getCurrentUsersInfo.fulfilled, (state, action) => {
      const { payload } = action;
      state.isAuthed = true;
      state.profile = payload;
      return state;
    });
    builder.addCase(signUpCustomer.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload.error) {
        state.isAuthed = true;
        state.apiError = null;
      } else {
        state.apiError = { signup: payload.error };
      }
    });
  },
});

export const { logout, acceptPrivacyPolicy } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAuthState = (state: RootState) => state.user.isAuthed;
export const getUserId = (state: RootState) => state.user.id;
export const getUserProfile = (state: RootState) => state.user;
export const getUserName = (state: RootState) => state.user.name;
export const getUserEmail = (state: RootState) => state.user.email;
export const getUserGroup = (state: RootState) => state.user.profile_type;
export const getUserFormError = (state: RootState) => state.user.apiError;
export const getWebSocketToken = (state: RootState) => state.user.websocket_token;
export const getPrivacyPolicyAccepted = (state: RootState) =>
  state.user.privacy_policy_accepted ||
  localStorage.getItem("privacy_policy_accepted");

export default userSlice.reducer;
