import { makeStyles, Typography } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useParams } from "react-router";

import { IUserInfo } from "Interfaces/user";

import { useAppSelector } from "../Redux/hooks";
import { getAuthState, getUserGroup } from "../Redux/slices/userSlice";
import { get, headerCss, patch } from "../Utils/utils";
import ModifiableLineItem from "./ModifiableLineItem";
import MultipleAddressInput from "./MultipleAddressInput";
import MultipleChoice from "./MultipleChoice";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
  },
  accountDetailRow: {
    width: "50%",
    marginLeft: "15px",
    marginRight: "15px",
  },
}));

const roleOptions = [
  { label: "Vice President", value: "VicePresident" },
  { label: "Lab Worker", value: "LabWorker" },
  { label: "Lab Manager", value: "LabManager" },
  {
    label: "Technical Sales Representative",
    value: "TechnicalSalesRepresentative",
  },
  { label: "Customer", value: "Customer" },
  { label: "Admin", value: "Admin" },
];

export default function AccountDetails() {
  const params = useParams();
  const classes = useStyles();
  const [userProfile, setUserProfile] = React.useState<any>({});
  const isAuthed = useAppSelector(getAuthState);
  let userRole = useAppSelector(getUserGroup);

  if (isAuthed && !userRole) {
    let token: any = localStorage.getItem("access_token");
    if (token) {
      let userToken: any = jwt_decode(token);
      userRole = userToken.profile_type;
    }
  }
  const patchUrl =
    userRole === "Customer"
      ? "/customers/" + userProfile.id
      : "/admins/" + userProfile.id;
  const profileUrl = userRole === "Customer" ? "/customers/me" : "/admins/me";

  React.useEffect(() => {
    refreshProfile();
  }, []);

  const refreshProfile = async () => {
    let response = await get(profileUrl);
    console.log(response.data, 'User role')
    userRole === "Customer"
      ? setUserProfile({
        ...response.data.user,
        addresses: response.data.addresses
      })
      : setUserProfile(response.data);
  };
  const saveField = (dbField: any, newVal: any) => {
    const newProfile: any = { user: {} };
    let address = {};
    if (dbField.includes(".")) {
      let dbFields: any = dbField.split(".");
      if (userRole === "Customer") {
        if (dbFields[1] === "address") {
          address = newVal;
          patch(patchUrl, address).then(() => {
            refreshProfile();
          });
          return;
        } else {
          newProfile[dbFields[1]] = newVal.text;
        }
      } else {
        newProfile.user[dbFields[1]] = newVal.text;
      }
    } else {
      if (userRole === "Customer") {
        newProfile[dbField] = newVal.text;
      } else {
        newProfile.user[dbField] = newVal.text;
      }
    }
    patch(patchUrl, newProfile).then(() => {
      refreshProfile();
    });
  };
  const updateContactMethod = (idx: number, dbField: any) => {
    const newProfile: any = {};
    let dbFields: any = dbField.split(".");
    newProfile[dbFields[1]] = idx === 0 ? "email" : "phone";
    patch(patchUrl, newProfile).then(() => {
      refreshProfile();
    });
  };
  const subtitle = () => {
    let subtitle = "";
    switch (userRole) {
      case "Customer":
        return userProfile?.user?.profile?.job_title
          ? userProfile?.user?.profile.job_title
          : "No Title Provided";
        break;
      default:
        if (typeof userRole === "undefined") return "No role provided";
        // @ts-ignore
        return roleOptions.find((role) => userRole == role.value).label;
        break;
    }
  };

  const customerFields = () => {
    return userRole === "Customer" ? (
      <>
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title="Company"
          subtitle={
            userProfile?.profile?.company
              ? userProfile?.profile.company
              : "No Company Provided"
          }
          editEnabled={true}
          width={"50%"}
          dbField={"profile.company"}
          onEdit={() => {}}
          onSave={saveField}
        />
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title="Phone Number"
          subtitle={
            userProfile?.profile?.phone
              ? userProfile?.profile?.phone
              : "No phone provided"
          }
          editEnabled={true}
          width={"50%"}
          dbField={"profile.phone"}
          onEdit={() => {}}
          onSave={saveField}
        />
        <br />
        <MultipleAddressInput
          address={userProfile?.addresses}
          isEditEnabled={true}
          onSave={saveField}
          dbField={"profile.address"}
        />
        <MultipleChoice
          title="Preferred Contact Method"
          onSelect={updateContactMethod}
          dbField={"profile.preferred_contact_method"}
          selectedIdx={
            userProfile?.user?.profile?.preferred_contact_method === "email"
              ? 0
              : 1
          }
          options={["Email", "Phone"]}
        />
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Typography className={classes.subHeaderText}>Account Details</Typography>
      <>
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title="Name"
          subtitle={userProfile?.name ? userProfile.name : ""}
          editEnabled={true}
          width={"50%"}
          dbField={"name"}
          onEdit={() => {}}
          onSave={saveField}
        />
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title={userRole === "Customer" ? "Job Title" : "Role"}
          subtitle={subtitle()}
          editEnabled={userRole === "Customer"}
          width={"50%"}
          dbField={"profile.job_title"}
          onEdit={() => {}}
          onSave={saveField}
        />
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title="Email Address"
          subtitle={userProfile?.email ? userProfile.email : ""}
          editEnabled={true}
          width={"50%"}
          dbField={"profile.email"}
          onEdit={() => {}}
          onSave={saveField}
        />
        <ModifiableLineItem
          className={classes.accountDetailRow}
          title="Password"
          editEnabled={true}
          width={"50%"}
          subtitle="***********"
          dbField={"password"}
          onEdit={() => {}}
          onSave={saveField}
        />
        {customerFields()}
      </>
    </>
  );
}
