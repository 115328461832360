import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactComponent as RightArrowIcon } from "../Images/right-arrow.svg";
import { IBreadcrumbsProps } from "Interfaces/props/breadCrumbsProps";

const useStyles = makeStyles((theme) => ({
  trailText: {
    fontFamily: "MontserratBold",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.47px",
    color: "#01366c",
    textDecoration: "underline",
  },
  currentText: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.47px",
    color: "#01366c",
  },
  svgRoot: {
    width: 9,
    height: 15,
    marginRight: 5,
    marginLeft: 5,
  },
}));

export default function Breadcrumbs(props: IBreadcrumbsProps) {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
      {props.crumbs.map((crumb, idx) => {
        if (idx === props.crumbs.length - 1)
          return (
            <Grid item>
              <Typography className={classes.currentText}>{crumb}</Typography>
            </Grid>
          );
        return (
          <>
            <Grid
              item
              onClick={() => {
                props.onClick(idx);
              }}
              style={{ cursor: "pointer" }}
            >
              <Typography className={classes.trailText}>{crumb}</Typography>
            </Grid>
            <Grid item style={{ height: 15 }}>
              <SvgIcon viewBox="0 0 9 15" className={classes.svgRoot}>
                <RightArrowIcon />
              </SvgIcon>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
