import { Grid, SvgIcon, Typography } from '@material-ui/core';
import * as React from 'react';
import { ReactComponent as ThreeDots } from 'Images/options.svg';
import useStyles from './styles';
import { EditIcon, DeleteIcon } from './Icons';
import { BubbleMenuProps } from "Interfaces/props/bubbleMenuProps";


const BubbleMenu = (props: BubbleMenuProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();
  const onEdit = () => {
    if (props.closeOnAction) {
      setOpen(false);
    }
    props.onEdit();
  };

  const onDelete = () => {
    if (props.closeOnAction) {
      setOpen(false);
    }
    props.onDelete();
  };

  return (
    <Grid item className={classes.bubbleMenuRoot}>
      <SvgIcon
        viewBox='0 0 15 4'
        className={classes.dotButton}
        onClick={() => {
          setOpen(!isOpen);
        }}>
        <ThreeDots />
      </SvgIcon>
      {isOpen ? (
        <div className={classes.hoverBubble}>
          <Grid
            container
            direction='column'
            style={{ marginTop: 35, marginLeft: 15 }}>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              style={{ cursor: 'pointer' }}
              onClick={onEdit}>
              <EditIcon style={{ width: 17, height: 17 }} onClick={() => {}} />
              <Typography className={classes.mainText}>Edit</Typography>
            </Grid>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              style={{ marginTop: 10, cursor: 'pointer' }}
              onClick={onDelete}>
              <DeleteIcon
                style={{ width: 17, height: 17 }}
                onClick={() => {}}
              />
              <Typography className={classes.mainText}>Delete</Typography>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default BubbleMenu;
