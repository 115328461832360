import { post } from "Utils/utils";
import snakecaseKeys from "snakecase-keys";

interface IStep {
  projectId: string;
  isComplete?: boolean;
  description?: string;
  isPublished?: boolean;
  completionDate?: Date | null;
  position?: Number;
  attachments?: any;
  subTasks?: {
    projectId?: string;
    isComplete?: boolean;
    description?: string;
    isPublished?: boolean;
    completionDate?: Date;
    position?: Number;
    isSubTask?: boolean;

    attachmentId?: string;
    attachmentName?: string;
  }[];
}
const createMileStone = (step: IStep) => {
  const payload = snakecaseKeys(step);
  return post("/admin/steps", payload);
};

export default createMileStone;
