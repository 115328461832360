import { makeStyles, Typography } from "@material-ui/core";
import { ProgressBarTileProps } from "Interfaces/props/ProgressBarTileProps";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: ProgressBarTileProps) => props.width,
    borderTopLeftRadius: (props: ProgressBarTileProps) =>
      props.isFirst ? "40px" : "",
    borderBottomLeftRadius: (props: ProgressBarTileProps) =>
      props.isFirst ? "40px" : "",
    borderTopRightRadius: (props: ProgressBarTileProps) =>
      props.isLast ? "40px" : "",
    borderBottomRightRadius: (props: ProgressBarTileProps) =>
      props.isLast ? "40px" : "",
    height: 56,
    borderRight: (props: ProgressBarTileProps) =>
      props.isLast ? "" : "solid 2.5px #01366c",
    boxSizing: "border-box",
    display: "table-cell",
    verticalAlign: "middle",
    position: "relative",
    cursor: "pointer",
  },
  pastColor: { "background-color": "#0bd1eb" },
  currentColor: { "background-color": " #96f065" },
  futureColor: { "background-color": "rgba(255, 255, 255, 0.75)" },
  solidWhite: { backgroundColor: "white" },
  stageText: {
    "font-family": " Lato",
    "font-size": "16px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.17,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    paddingRight: 10,
    paddingLeft: 10,
  },
  arrowRoot: {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    border: "solid 2.5px #01366c",
    position: "absolute",
    right: 0,
    top: "50%",
    "-webkit-transform": "translateY(-50%)",
    "-ms-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
    marginRight: "-14px",
    zIndex: 7,
    display: (props: ProgressBarTileProps) => (props.isLast ? "none" : ""),
  },
  arrowIcon: {
    border: "solid black",
    "border-width": "0 3px 3px 0",
    display: "inline-block",
    padding: "4px",
    transform: "rotate(-45deg)",
    "-webkit-transform": "rotate(-45deg)",
    margin: "auto",
    marginTop: 3,
    marginLeft: 2,
  },
}));

const ProgressBarTile = (props: ProgressBarTileProps) => {
  const classes = useStyles(props);
  let colorClass = "";
  let arrowClass = "";
  switch (props.stage) {
    case "past":
      colorClass = classes.pastColor;
      arrowClass = colorClass;
      break;
    case "current":
      colorClass = classes.currentColor;
      arrowClass = colorClass;
      break;
    case "future":
      colorClass = classes.futureColor;
      arrowClass = classes.solidWhite;
      break;
    default:
      break;
  }
  return (
    <div className={classes.root + " " + colorClass} onClick={props.onClick}>
      <Typography className={classes.stageText}>{props.label}</Typography>
      <div className={classes.arrowRoot + " " + arrowClass}>
        <div className={classes.arrowIcon}></div>
      </div>
    </div>
  );
};

export default ProgressBarTile;
