import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";

import { INarrowTextfieldProps } from "Interfaces/props/narrowTextFieldProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: INarrowTextfieldProps) => props.width || 500,
    //padding: "8px 16px 7px 15px",
    paddingLeft: 15,
    paddingTop: (props: INarrowTextfieldProps) => (props.multiline ? 15 : 5),
    paddingRight: (props: INarrowTextfieldProps) => (props.multiline ? 5 : 0),
    "border-radius": "100px",
    border: "solid 2.5px #01366c",
    backgroundColor: "white",
    "-webkit-box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 4px 0px 4px rgba(0,0,0,0.2)",
    [theme.breakpoints.down("xs")]: {
      width: (props: INarrowTextfieldProps) =>
        props.responsive ? "100%" : undefined,
      borderRadius: (props: INarrowTextfieldProps) =>
        props.responsive ? "50px" : undefined,
    },
  },
  content: {
    margin: (props: INarrowTextfieldProps) =>
      props.centered ? "auto" : undefined,
    width: (props: INarrowTextfieldProps) => (props.centered ? 500 : undefined),
  },
  input: {
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    outline: "none",
    fontSize: 15,
    height: (props: INarrowTextfieldProps) => props.height || 24,
    width: "100%",
    textOverflow: "ellipsis",
    "&::placeholder": {
      fontFamily: "VarelaRound",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "normal",
      color: "#01366c",
    },
  },
  textarea: {
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
  textWrapper: {
    marginTop: "auto",
    fontSize: "x-large",
  },
  styledText: {
    border: 'none',
    borderRadius: '20px',
    height: '95%',
    width: '97%',
    paddingLeft: '20px',
    resize: 'none',
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
   
    '&:focus': {
      outline: 'none',
    },
    "&::placeholder": {
      fontFamily: "VarelaRound",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "normal",
      color: "#01366c",
    },
  }
}));

export default function NarrowTextfield(props: INarrowTextfieldProps) {
  const classes = useStyles(props);
  return (
    <>
      <Grid
        container
        className={classes.root + " " + props.className}
        style={props.style}
      >
        <Grid container direction="row" className={classes.content}>
          <Grid item style={{ flexGrow: 1 }}>
            {props.multiline && (
              <textarea
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                className={classes.styledText}
              ></textarea>
            )}
            {!props.multiline && (
              <input
                placeholder={props.placeholder}
                className={classes.input}
                value={props.value}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                type={
                  props.isPassword
                    ? "password"
                    : props.type
                    ? `${props.type}`
                    : undefined
                }
                onFocus={props.onFocus}
                onBlur={props.onBlur}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.textToPrepend && (
        <Grid item className={classes.textWrapper}>
          {props.textToPrepend}
        </Grid>
      )}
    </>
  );
}
