import React, { useState, useEffect } from "react";
import { Grid, makeStyles, SvgIcon, Typography, Box } from "@material-ui/core";
import NarrowTextfield from "./NarrowTextfield";
import SaveButton from "./SaveButton";
import { ReactComponent as EditIcon } from "../Images/edit.svg";
import { IAddress } from "Interfaces/address";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    marginTop: 15,
    marginLeft: 7,
    marginBottom: 15,
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    maxWidth: "85%",
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  editableRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  iconRoot: { cursor: "pointer" },
}));

export default function MultipleAddressInput(props: any) {
  const classes = useStyles(props);
  const { address, isEditEnabled } = props;

  const [isEditable, setIsEditable] = useState(false);
  const [addressData, setAddressData] = useState<IAddress>({
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "us",
  });

  useEffect(() => {
    if (address && address.length > 0) {
      setAddressData({
        line_1: address[0].line_1,
        line_2: address[0].line_2,
        city: address[0].city,
        state: address[0].state,
        zip_code: address[0].zip_code,
        country: address[0].country,
      });
    }
  }, [address]);

  const onChange = (value: string, name: string) => {
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  const handleClick = () => {
    if (isEditable) {
      setIsEditable(false);
      props.onSave(props.dbField, addressData);
    } else {
      setIsEditable(true);
    }
  };

  const editEnabled = () => {
    return isEditEnabled ? (
      <Box
        style={{
          marginTop: isEditable ? "auto" : "",
          cursor: "pointer",
          marginRight: "-15px",
        }}
        onClick={handleClick}
      >
        {isEditable ? (
          <SaveButton onClick={handleClick} />
        ) : (
          <SvgIcon viewBox="0 0 24 24">
            <EditIcon />
          </SvgIcon>
        )}
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} md={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.titleText}>Address</Typography>
          {editEnabled()}
        </Box>
      </Grid>
      {!isEditable && (
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {addressData.line_1 +
                " " +
                addressData.line_2 +
                " " +
                addressData.city +
                " " +
                addressData.state +
                " " +
                addressData.zip_code}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {isEditable && (
          <NarrowTextfield
            onChange={(e: any) => onChange(e.target.value, "line_1")}
            placeholder="street address"
            width="100%"
            value={addressData.line_1}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        {isEditable && (
          <NarrowTextfield
            onChange={(e: any) => onChange(e.target.value, "line_2")}
            placeholder="suite/po"
            width="100%"
            value={addressData.line_2}
          />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        {isEditable && (
          <NarrowTextfield
            onChange={(e: any) => onChange(e.target.value, "city")}
            placeholder="city"
            width="100%"
            value={addressData.city}
          />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        {isEditable && (
          <NarrowTextfield
            onChange={(e: any) => onChange(e.target.value, "state")}
            placeholder="state"
            width="100%"
            value={addressData.state}
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        {isEditable && (
          <NarrowTextfield
            onChange={(e: any) => onChange(e.target.value, "zip_code")}
            placeholder="zipcode"
            width="100%"
            value={addressData.zip_code}
          />
        )}
      </Grid>
    </Grid>
  );
}
