import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Option from "../Components/MultipleChoiceOption";
import NarrowTextfield from "../Components/NarrowTextfield";
import SmallButton from "../Components/SmallButton";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import {
  login,
  signUpCustomer,
  getUserFormError, forgotPassword,
} from "../Redux/slices/userSlice";
import { headerCss, post } from "../Utils/utils";
import emailValidator from "../Utils/emailValidator";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    width: "975px",
    height: "65px",
    fontFamily: "'museo-slab'",
    fontWeight: 100,
    fontSize: "54px",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  subHeaderText: {
    width: "750px",
    height: "100px",
    fontFamily: "'varela_roundregular'",
    fontSize: "19px",
    lineHeight: "1.3",
    letterSpacing: "0.2px",
    textAlign: "center",
    color: "#01366c",
    marginTop: "50px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textFieldError: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    color: "#FFFFFF",
    backgroundColor: "#d0021b",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const apiError = useAppSelector(getUserFormError);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [fieldErrors, setFieldErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [showFieldErrors, setShowFieldErrors] = React.useState(false);

  React.useEffect(() => {
    const jwt = localStorage.getItem("access_token");
    if (jwt) dispatch(login({ jwt }));
  }, [dispatch]);

  React.useEffect(() => {
    if (apiError?.signup) {
      setFieldErrors({
        email: apiError?.signup.msg[0],
      });
      setShowFieldErrors(true);
    }
  }, [apiError]);

  const getFieldValue = (fieldType: string) => {
    if (fieldErrors[fieldType] && showFieldErrors) {
      return fieldErrors[fieldType];
    }
    if (fieldType === "email") return email;
  };

  const getClass = (fieldType: string) => {
    if (showFieldErrors && fieldErrors[fieldType]) {
      return classes.textFieldError;
    }
    return classes.textField;
  };

  const validateFormFields = () => {
    let result = true;
    let evaluateFieldErrors: { [key: string]: string } = {
      email: "",
    };
    if (!email) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        email: "Cannot be empty",
      };
    } else if (!emailValidator(email)) {
      result = false;
      evaluateFieldErrors = {
        ...evaluateFieldErrors,
        email: "Not valid email",
      };
    }
    setFieldErrors(evaluateFieldErrors);
    return result;
  };
  const handleFieldFocus = () => {
    if (showFieldErrors) {
      setShowFieldErrors(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <Typography className={classes.headerText}>
          Forgot Password
        </Typography>
      </Grid>
      <Grid item>
        {!isSubmitted && (
          <Typography className={classes.subHeaderText}>
            Enter your email address below and we'll send instructions for how to regain access to your account.
          </Typography>
        )}
        {isSubmitted && (
          <Typography className={classes.subHeaderText}>
            Check your email for the instructions we just sent you.
          </Typography>
        )}
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        {!isSubmitted && (
          <>
            <NarrowTextfield
              placeholder="EMAIL ADDRESS"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              className={getClass("email")}
              value={getFieldValue("email")}
              onFocus={handleFieldFocus}
            />
              <SmallButton
              color="green"
              onClick={() => {
                if (!validateFormFields()) {
                  setShowFieldErrors(true);
                  return;
                }
                dispatch(forgotPassword(email));
                setIsSubmitted(true);
              }}
            >
              SEND
            </SmallButton>
          </>
        )}
        {isSubmitted && (
          <SmallButton
            color="green"
            onClick={() => {history.push('/login')}}>
            Back to Login
          </SmallButton>
        )}
      </Grid>
    </Grid>
  );
}
