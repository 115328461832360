import * as React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../Redux/hooks";
import {
  getAuthState,
  getUserGroup,
  login,
} from "../../../Redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { IGuardProps } from "Interfaces/props/guardProps";

const AuthGuard: React.FC<IGuardProps> = ({ children, routeType }) => {
  console.log("AuthGuard");
  const dispatch = useDispatch();
  const isAuth = useAppSelector(getAuthState);
  const profileType = useAppSelector(getUserGroup);
  const hasToken = !!localStorage.getItem("access_token");
  const history = useHistory();

  React.useEffect(() => {
    const jwt = localStorage.getItem("access_token");
    if (jwt) dispatch(login({ jwt }));
  }, [dispatch, hasToken]);

  if (!isAuth && !hasToken) {
    return <Redirect to="/login" />;
  }
  if (isAuth) {
    if (routeType === "Admin" && profileType === "Customer") {
      history.push("/");
    }
    if (routeType === "Customer" && profileType !== "Customer") {
      history.push("/");
    }
    return <>{children}</>;
  }
  return <></>;
};

export default AuthGuard;
