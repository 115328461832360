import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import TopNav from "../Components/TopNav";
import Logo from "../Images/logo.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    paddingRight: 20,
    paddingLeft: 20,
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  logo: {
    //marginTop: 30,
    //marginLeft: 50,
    backgroundImage: `url(${Logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 80,
    height: 53,
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Link to="/">
        <Grid
          item
          style={{ textAlign: "center" }}
          className={classes.logo}
        ></Grid>
      </Link>
      <Grid item style={{ flex: 1 }}>
        <TopNav />
      </Grid>
    </Grid>
  );
};

export default Header;
