import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import Option from "../Components/MultipleChoiceOption";
import NarrowTextfield from "../Components/NarrowTextfield";
import SmallButton from "../Components/SmallButton";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import {
  login,
  signUpCustomer,
  getUserFormError,
} from "../Redux/slices/userSlice";
import { headerCss, post } from "../Utils/utils";
import emailValidator from "../Utils/emailValidator";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    width: "975px",
    height: "65px",
    fontFamily: "'museo-slab'",
    fontWeight: 100,
    fontSize: "54px",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  subHeaderText: {
    width: "750px",
    height: "100px",
    fontFamily: "'varela_roundregular'",
    fontSize: "19px",
    lineHeight: "1.3",
    letterSpacing: "0.2px",
    textAlign: "center",
    color: "#01366c",
    marginTop: "50px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textFieldError: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    color: "#FFFFFF",
    backgroundColor: "#d0021b",
  },
}));

export default function Login() {
  const classes = useStyles();
  const apiError = useAppSelector(getUserFormError);
  const dispatch = useAppDispatch();
  const [confidentiality, setConfidentiality] = React.useState(false);
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [nav, setNav] = React.useState(0);
  const [fieldErrors, setFieldErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [showFieldErrors, setShowFieldErrors] = React.useState(false);

  React.useEffect(() => {
    const jwt = localStorage.getItem("access_token");
    if (jwt) dispatch(login({ jwt }));
  }, [dispatch]);

  React.useEffect(() => {
    if (apiError?.signup) {
      setFieldErrors({
        email: apiError?.signup.msg[0],
      });
      setShowFieldErrors(true);
      setNav(0);
    }
  }, [apiError]);

  const getFieldValue = (fieldType: string) => {
    if (fieldErrors[fieldType] && showFieldErrors) {
      return fieldErrors[fieldType];
    }
    if (fieldType === "name") return name;
    if (fieldType === "companyName") return companyName;
    if (fieldType === "email") return email;
    if (fieldType === "password") return password;
  };

  const getClass = (fieldType: string) => {
    if (showFieldErrors && fieldErrors[fieldType]) {
      return classes.textFieldError;
    }
    return classes.textField;
  };

  const validateFormFields = () => {
    let result = true;
    let evaluateFieldErrors: { [key: string]: string } = {
      name: "",
      companyName: "",
      email: "",
      password: "",
    };
    if (nav === 0) {
      if (!name) {
        result = false;
        evaluateFieldErrors = { name: "Cannot be empty" };
      }
      if (!companyName) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          companyName: "Cannot be empty",
        };
      }
      if (!email) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          email: "Cannot be empty",
        };
      } else if (!emailValidator(email)) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          email: "Not valid email",
        };
      }
      if (!confidentiality) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          confidentiality: "Not selected",
        };
      }
    } else {
      if (!password || password.length < 6) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          password: "Password too short",
        };
      } else if (password !== confirmPassword) {
        result = false;
        evaluateFieldErrors = {
          ...evaluateFieldErrors,
          password: "Passwords do not match",
        };
      }
    }
    setFieldErrors(evaluateFieldErrors);
    return result;
  };
  const handleFieldFocus = () => {
    if (showFieldErrors) {
      setShowFieldErrors(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <Typography className={classes.headerText}>
          Formulate Online. Accomplish More.
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subHeaderText}>
          Formulate and launch performance-based innovations fast.
        </Typography>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        {nav === 0 ? (
          <>
            <NarrowTextfield
              placeholder="NAME"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              value={getFieldValue("name")}
              className={getClass("name")}
              onFocus={handleFieldFocus}
            />
            <NarrowTextfield
              placeholder="COMPANY NAME"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyName(event.target.value);
              }}
              className={getClass("companyName")}
              value={getFieldValue("companyName")}
              onFocus={handleFieldFocus}
            />
            <NarrowTextfield
              placeholder="EMAIL ADDRESS"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              className={getClass("email")}
              value={getFieldValue("email")}
              onFocus={handleFieldFocus}
            />
            <Option
              label="Accept Confidentiality Agreement."
              onSelect={() => {
                handleFieldFocus();
                setConfidentiality(!confidentiality);
              }}
              isSelected={confidentiality}
            />
            {showFieldErrors && fieldErrors.confidentiality && (
              <Typography style={{ color: "#d0021b" }}>
                Please accept confidentiality agreement.
              </Typography>
            )}
            <SmallButton
              color="red"
              onClick={() => {
                if (!validateFormFields()) {
                  setShowFieldErrors(true);
                  return;
                }
                if (!confidentiality) return;
                setNav(1);
              }}
            >
              NEXT
            </SmallButton>
          </>
        ) : (
          <>
            <NarrowTextfield
              placeholder="PASSWORD"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              className={getClass("password")}
              onFocus={handleFieldFocus}
              value={getFieldValue("password")}
              isPassword={!showFieldErrors}
            />
            <NarrowTextfield
              placeholder="CONFIRM PASSWORD"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value);
              }}
              value={confirmPassword}
              className={classes.textField}
              onFocus={handleFieldFocus}
              isPassword
            />
            <SmallButton
              color="red"
              onClick={() => {
                if (!validateFormFields()) {
                  setShowFieldErrors(true);
                  return;
                }
                dispatch(
                  signUpCustomer({
                    name,
                    email,
                    company: companyName,
                    password,
                  })
                );
              }}
            >
              SUBMIT
            </SmallButton>
          </>
        )}
      </Grid>
      <GoogleReCaptcha
        onVerify={(token) => {
          console.log(token);
          post("/verify_recaptcha", { token });
        }}
      />
    </Grid>
  );
}
