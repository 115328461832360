import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { IMessageProps } from "Interfaces/props/messageProps";
import formatTimeForChat from "Utils/timeFormats";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: (props: IMessageProps) => (props.self ? "right" : "left"),
    marginTop: 5,
    color: "#0bd1eb",
  },
  dateText: {
    fontFamily: "VarelaRound",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    color: "#f79730",
    textAlign: (props: IMessageProps) => (props.self ? "right" : "left"),
  },
  messageText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    textAlign: (props: IMessageProps) => (props.self ? "right" : "left"),
    marginBottom: 5,
  },
}));

export default function Message(props: IMessageProps) {
  const classes = useStyles(props);
  return (
    <Grid container direction='column' justify='flex-start'>
      <Grid item>
        <Typography className={classes.titleText}>{props.title}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.dateText}>
          {formatTimeForChat(props.date)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.messageText}>{props.message}</Typography>
      </Grid>
    </Grid>
  );
}
