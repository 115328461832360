import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactComponent as EditIcon } from "../Images/edit.svg";
import SaveButton from "./SaveButton";
import Option from "./MultipleChoiceOption";
import MultipleChoice from "./MultipleChoice";
import NarrowTextfield from "./NarrowTextfield";
import { IModifiableMultipleChoiceProps } from "Interfaces/props/modifiableMultipleChoiceProps";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: 45,
    width: (props: IModifiableMultipleChoiceProps) => props.width || "100%",
    marginTop: 15,
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    maxWidth: "85%"
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  editableRow : {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%"
  },
  optionsContainer : {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%"
  },
  conditionalContainer: {
    marginLeft: "35px"
  },
  iconRoot: {
    cursor: "pointer",
    marginTop: "unset !important"
  },
}));

export default function ModifiableMultipleChoice(props: IModifiableMultipleChoiceProps) {
  const [isEditable, setIsEditable] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(props.subtitle);
  const [otherText, setOtherText] = React.useState(props.otherText || "");
  const [conditionalOptions, setConditionalOptions] = React.useState<any[]>([])
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState<number>(props.selectedIdx);
  const [selectedConditionalOptionIndex, setSelectedConditionalOptionIndex] = React.useState(props.selectedConditionalIdx || -1);

  const classes = useStyles(props);
  const wrap = props.wrap || "nowrap"
  const answers = props.options;
  const isConditional = props.isConditional;
  const hasOther = props.hasOther;
  const isEditEnabled = props.editEnabled;
  const conditionalAnswers = props.conditionalOptions || [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(event.target.value);
  };

  React.useEffect(() => {
    if(!props.conditionalOptions) return;
    let conditionalOptionIndex = answers[selectedOptionIndex];
    setConditionalOptions(props.conditionalOptions[conditionalOptionIndex]);
  }, [selectedOptionIndex]);

  const handleClick = () => {
    if (isEditable) {
      let updatedAnswer: any = {
        text: answers[selectedOptionIndex]
      };
      if(!isConditional && !hasOther) {
        setInputValue(answers[selectedOptionIndex]);
      }else if(hasOther) {
        if(selectedOptionIndex === answers.length - 1) {
          setInputValue(otherText.length > 0 ? `${answers[selectedOptionIndex]}, ${otherText}` : `${answers[selectedOptionIndex]}` );
          updatedAnswer.conditional_answers = otherText;
        }else {
          setInputValue(`${answers[selectedOptionIndex]}`);
          updatedAnswer.conditional_answers = "";
        }
      } else {
        setInputValue(`${answers[selectedOptionIndex]}, ${conditionalAnswers[answers[selectedOptionIndex]][selectedConditionalOptionIndex]}`);
        updatedAnswer.conditional_answers = conditionalAnswers[answers[selectedOptionIndex]][selectedConditionalOptionIndex];
      }
      props.onSave(props.dbField, updatedAnswer);
    }
    setIsEditable(!isEditable);
  };

  const onOptionSelect = (index: number) => {
    if(selectedOptionIndex !== index) setSelectedConditionalOptionIndex(0);
    setSelectedOptionIndex(index);
  };
  const onConditionalSelect = (index: number) => {
    setSelectedConditionalOptionIndex(index);
  };


  const editEnabled = () => {
    return isEditEnabled ?
    (
      <Grid item className={classes.iconRoot} onClick={() => handleClick()} style={{marginTop: isEditable ? 'auto': ''}}>
        {isEditable ? (
          <SaveButton onClick={() => {}} />
        ) : (
          <SvgIcon viewBox="0 0 24 24">
            <EditIcon />
          </SvgIcon>
        )}
      </Grid>
    ) :
      (
        <></>
      )
  };

  const renderOptions = () => {
    const renderedOptions = answers.map((label, index) => {
      return (
        <Grid container direction={"column"} alignItems={undefined}>
          <Option
            key={index}
            label={label}
            style={{marginRight: 25}}
            isSelected={selectedOptionIndex === index}
            onSelect={() => { onOptionSelect(index) }}
          />
          {isConditional && selectedOptionIndex === index && (
            <Grid item className={classes.conditionalContainer}>
              <MultipleChoice
                title={''}
                onSelect={(conditionalIndex) => {
                  onConditionalSelect(conditionalIndex)
                }}
                selectedIdx={selectedConditionalOptionIndex}
                options={conditionalOptions}
              />
            </Grid>
          )}
          {hasOther && answers[index] === "Other" && selectedOptionIndex === index && (
            <Grid item className={classes.conditionalContainer}>
              <NarrowTextfield
                onChange={handleChange}
                defaultValue={otherText}
                value={otherText}
                className={classes.subText}
                width={300}
                isPassword={false}
                />
            </Grid>
          )}
        </Grid>
      )
    });
    return (
      <Grid container className={classes.optionsContainer}>
        {renderedOptions}
      </Grid>
    )
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justify="space-between"
      wrap={wrap ? "wrap" : "nowrap"}
      className={props.className + " " + classes.root}
    >
      <Grid item className={classes.editableRow}>
        <Typography className={classes.titleText}>{props.title}</Typography>
      </Grid>
      <Grid item className={classes.editableRow}>
        {!isEditable && (
          <Typography className={classes.subText}>
            {inputValue}
          </Typography>
        )}
        {isEditable && renderOptions()}
        {editEnabled()}
      </Grid>
    </Grid>
  );
}
