import { makeStyles } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes, routes } from "./routes";
import Header from "./Views/Header";
import "./Utils/fonts/fonts.css";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
  },
}));

function App() {
  const classes = useStyles();
  document.title = "Azelis CASE Lab"; //Set website name/title here
  return (
    <div className={classes.root}>
      <Header />
      {renderRoutes(routes)}
    </div>
  );
}

export default App;
