import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import NextButton from "./NextButton";
import { IMessageTextFieldProps } from "Interfaces/props/chatTextFieldProps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: IMessageTextFieldProps) => props.width || "90%",
    paddingLeft: 15,
    paddingTop: 5,
    "border-radius": "100px",
    border: "solid 2.5px #01366c",
    backgroundColor: "white",
    "-webkit-box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
    "box-shadow": "inset 0px 2px 0px 2px rgba(0,0,0,0.2)",
    right: 25,
    left: 25,
    bottom: 20,
    position: "absolute",
  },
  inputRoot: {
    [theme.breakpoints.down("xs")]: {
      width: 110,
    },
    [theme.breakpoints.down(350)]: {
      width: 110,
    },
  },
  input: {
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    outline: "none",
    fontSize: 15,
    height: 34,
    width: "150%",
    textOverflow: "ellipsis",
    "&::placeholder": {
      fontFamily: "VarelaRound",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "normal",
      color: "#01366c",
    },
  },
  buttonText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#01366c",
  },
}));

export default function MessageTextField(props: IMessageTextFieldProps) {
  const [message, setMessage] = React.useState("");
  const classes = useStyles(props);
  const inputRef = useRef(null);
  const changeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    props.onSend(message);
    let target: any = inputRef?.current;
    target.value = "";
    setTimeout(() => {
      setMessage("");
    }, 1000);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root + " " + props.className}
    >
      <Grid item className={classes.inputRoot}>
        <input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className={classes.input}
          placeholder={props.placeholder || "Message"}
          onChange={changeMessage}
          value={message}
        ></input>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ width: "max-content" }}
      >
        <Typography className={classes.buttonText}>Submit</Typography>
        <NextButton
          onClick={sendMessage}
          isReady={true}
          style={{
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            marginTop: -5,
          }}
        />
      </Grid>
    </Grid>
  );
}
