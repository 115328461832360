import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import * as React from "react";
import NarrowTextfield from "./NarrowTextfield";
import { ReactComponent as EditIcon } from "../Images/edit.svg";
import SaveButton from "./SaveButton";
import {useEffect} from "react";
import { IModifiableLineItemProps } from "Interfaces/props/modifiableLineItemProps";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: 45,
    width: (props: IModifiableLineItemProps) => props.width || "100%",
    marginTop: 15,
  },
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    maxWidth: "85%"
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  editableRow : {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%"
  },
  iconRoot: { cursor: "pointer" },
}));

export default function ModifiableLineItem(props: IModifiableLineItemProps) {
  const [isEditable, setIsEditable] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const classes = useStyles(props);
  const wrap = props.wrap || "nowrap"
  const isEditEnabled = props.editEnabled;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(props.subtitle);
  }, [])

  const handleClick = () => {
    if (isEditable) {
      let updatedAnswer = {
        text: inputValue
      };
      props.onSave(props.dbField, updatedAnswer);
    }
    setIsEditable(!isEditable);
  };

  React.useEffect(() => {
    setInputValue(props.subtitle);
  }, [props.subtitle]);

  const editEnabled = () => {
    return isEditEnabled ?
    (
      <Grid item className={classes.iconRoot} onClick={() => handleClick()} style={{marginTop: isEditable ? 'auto': ''}}>
        {isEditable ? (
          <SaveButton onClick={() => {}} />
        ) : (
          <SvgIcon viewBox="0 0 24 24">
            <EditIcon />
          </SvgIcon>
        )}
      </Grid>
    ) :
      (
        <></>
      )
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justify="space-between"
      wrap={wrap ? "wrap" : "nowrap"}
      className={props.className + " " + classes.root}
    >
      <Grid item className={classes.editableRow}>
        <Typography className={classes.titleText}>{props.title}</Typography>
      </Grid>
      <Grid item className={classes.editableRow}>
        {!isEditable && (
          <Typography className={classes.subText}>
            {props.isPassword ? new Array(inputValue.length).join("•") : inputValue}
          </Typography>
        )}
        {isEditable && (
          <NarrowTextfield
            onChange={handleChange}
            defaultValue={inputValue}
            value={inputValue}
            className={classes.subText}
            width={300}
            isPassword={props.isPassword}
          />
        )}
        {editEnabled()}
      </Grid>
    </Grid>
  );
}
