import snakecaseKeys from "snakecase-keys";

import { get, patch, post } from "Utils/utils";

export const projectApi = {
  addNotesApi: (note: {
    userId: string;
    projectId: string;
    isPrivate: boolean;
    body: string;
  }) => {
    return post("/notes/", {
      note: {
        user_id: note.userId,
        project_id: note.projectId,
        is_private: note.isPrivate,
        body: note.body,
      },
    });
  },
  addMessageApi: (message: {
    userId: string;
    projectId: string;
    isPrivate: boolean;
    body: string;
  }) => {
    return post("/messages/", {
      message: {
        user_id: message.userId,
        project_id: message.projectId,
        is_private: message.isPrivate,
        body: message.body,
      },
    });
  },
  getAdminProject: (id: string) =>
    get("/admin/projects/" + id).then((resp) => resp.data),
  publishProject: (params: any) => {
    return post(
      `/admin/projects/${params.projectId}/complete`,
      snakecaseKeys(params),
    );
  },
  updateCommercial: (
    id: string,
    commercial_assessment: {
      commercial_assessment: {
        annual_revenue_potential?: number;
        customer_eta_in_days?: number;
        sales_eta_in_days?: number;
        nda_required?: boolean;
        successProbability?: number;
        is_submitted?: boolean;
        lines?: string[];
      };
    }
  ) =>
    post(
      "/admin/projects/" + id + "/update_commercial_assessment",
      commercial_assessment
    ),
  updateProjectSteps: (params: {
    projectId: string;
    projectStatus?: Number;
    stepsPublished?: Boolean;
  }) => {
    return patch(`/admin/projects/${params.projectId}`, snakecaseKeys(params));
  },
  updateProjectLab: (params: { projectId: string; labId?: string }) => {
    return patch(`/admin/projects/${params.projectId}`, snakecaseKeys(params));
  },
  updateTechnical: (
    id: string,
    technical_assessment: {
      technical_assessment: {
        success_probability?: number;
        lab_eta_in_days?: number;
        is_submitted?: boolean;
      };
    }
  ) =>
    post(
      "/admin/projects/" + id + "/update_technical_assessment",
      technical_assessment
    ),
  updateApproval: (
    id: string,
    approvalStatus: null | boolean,
    approvalNotes: string
  ) =>
    post(`/admin/projects/${id}/approve`, {
      approver_notes: approvalNotes,
      is_approved: approvalStatus,
    }),
  updateNote: (id: string, body: string) =>
    patch(`/notes/${id}`, {
      note: { body },
    }),
  deleteNote: (id: string) => post(`/notes/${id}/delete_note`, {}),
  assignTSR: (projectId: string, tsrId: string) =>
    post(`/admin/projects/${projectId}/assign_tsr`, { tsr_id: tsrId }),
  getTsrID: (projectId: string) =>
    get(`/admin/projects/${projectId}/project_tsr`),
};
