import { makeStyles, SvgIcon, Typography } from "@material-ui/core";
import ProductTileBlue from "../Images/product-tile-blue.svg";
import { ReactComponent as IngredientIcon } from "../Images/ingredient.svg";
import { ReactComponent as DetailsIcon } from "../Images/details.svg";
import LargeButton from "./LargeButton";
import { ITSRTileProps } from "Interfaces/props/tsrTileProps";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${ProductTileBlue})`,
    width: "364px",
    height: "479px",
    margin: 25,
    padding: 20,
    [theme.breakpoints.down(450)]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 25,
      marginBottom: 25,
      backgroundSize: "cover",
      height: 479,
    },
    [theme.breakpoints.down(380)]: {
      height: 468,
    },
    [theme.breakpoints.down(350)]: {
      height: 400,
    },
  },
  iconRoot: {
    margin: "auto",
    display: "block",
    width: 55,
    height: 55,
    marginTop: 25,
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 25,
  },
  subTitleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 5,
  },
  status: {
    marginTop: 24,
    textTransform: 'uppercase'
  },
  listText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  listRoot: {
    marginTop: 10,
    display: "block",
  },
}));

export default function TSRTile(props: ITSRTileProps) {
  const classes = useStyles();
  const ARP = props.ARP
    ? props.ARP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;

  return (
    <div className={classes.root}>
      <SvgIcon viewBox="0 0 55 55" className={classes.iconRoot}>
        <IngredientIcon />
      </SvgIcon>
      <Typography className={classes.titleText}>{props.name}</Typography>
      <Typography className={classes.subTitleText}>{props.company}</Typography>
      {props.isTSR ? (
        <span className={classes.listRoot}>
          <Typography className={classes.listText}>
            Contact: {props.customer}
          </Typography>
        </span>
      ) : (
        <span className={classes.listRoot}>
          <Typography className={classes.listText}>
            Annual Revenue Potential: ${ARP}
          </Typography>
          <Typography className={classes.listText}>
            TSR: {props.technical_sales_representative}
          </Typography>
          <Typography className={classes.listText}>
            NDA Required: {props.NDA ? "Yes" : "No"}
          </Typography>
        </span>
      )}
      <Typography className={classes.subTitleText + ' ' + classes.status}>
        {props.project_status?.replace('_', ' ')}
      </Typography>
      <LargeButton
        text="DETAILS"
        color="green"
        icon={<DetailsIcon />}
        onClick={props.onClick}
        style={{ margin: "auto", marginTop: 25 }}
      />
    </div>
  );
}
