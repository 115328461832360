import * as React from "react";
import {
  fetchAllProjects,
  fetchProject,
  getProjects,
  searchProjects,
  setProjects,
} from "Redux/slices/projectsSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useStyles from "./styles";
import { useAppSelector } from "../../Redux/hooks";
import { getUserGroup, getUserId } from "../../Redux/slices/userSlice";
import { Grid, SvgIcon, Typography } from "@material-ui/core";
import SearchBar from "../../Components/SearchBar";
import BlueColumnHeaders from "../../Components/BlueColumnHeaders";
import { claimProjects } from "../../Utils/api/claimProject";
import { ReactComponent as ClaimIcon } from "../../Images/claim.svg";
import { Project } from "Interfaces/project";
import { capitalCase } from "change-case";

export default function AdminProjectsList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const projects = useAppSelector(getProjects);
  const userId = useAppSelector(getUserId);
  const userRole = useAppSelector(getUserGroup);
  const isClaimer = ["LabWorker", "LabManager"].includes(userRole);
  const [searchText, setSearchText] = React.useState<string>("");
  const [sortDir, setSortDir] = React.useState(1);

  React.useEffect(() => {
    if (searchText === "") dispatch(fetchAllProjects());
    else dispatch(searchProjects({ query: searchText, offset: 0 }));
  }, [dispatch, searchText]);

  const selectProject = (project: Project) => {
    dispatch(fetchProject(project.id));
    history.push(`/admin/projects/${project.id}`);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item style={{ margin: "auto" }}>
        <SearchBar
          placeholder="Search"
          centered
          className={classes.searchBar}
          onChange={setSearchText}
        />
        <BlueColumnHeaders
          labels={[
            "Ref Code",
            "Project Name",
            "Customer",
            "Contact",
            "Phase",
            "Chemist",
            "Lab",
          ]}
          onClick={(idx) => {
            const sortedProjects = [...projects].sort((a, b) => {
              switch (idx) {
                case 0:
                  return a.refcode > b.refcode
                    ? sortDir
                    : b.refcode > a.refcode
                    ? -1 * sortDir
                    : 0;
                case 1:
                  return a.name > b.name
                    ? sortDir
                    : b.name > a.name
                    ? -1 * sortDir
                    : 0;
                case 2:
                  return a.company > b.company
                    ? sortDir
                    : b.company > a.company
                    ? -1 * sortDir
                    : 0;
                case 3:
                  return a.customer > b.customer
                    ? sortDir
                    : b.customer > a.customer
                    ? -1 * sortDir
                    : 0;
                case 4:
                  return a.phase > b.phase
                    ? sortDir
                    : b.phase > a.phase
                    ? -1 * sortDir
                    : 0;
                case 5:
                  return a.chemist > b.chemist
                    ? sortDir
                    : b.chemist > a.chemist
                    ? -1 * sortDir
                    : 0;
                case 6:
                  const tempA = a.lab ? a.lab : "Unassigned";
                  const tempB = b.lab ? b.lab : "Unassigned";
                  return tempA > tempB
                    ? sortDir
                    : tempB > tempA
                    ? -1 * sortDir
                    : 0;
                default:
                  return 1;
              }
            });
            setSortDir(-1 * sortDir);
            dispatch(setProjects(sortedProjects));
          }}
        />
        <Grid container direction="column" className={classes.tableBody}>
          {projects.map((project, idx) => {
            const isFirst = idx === 0;
            const isLast = idx === projects.length - 1;

            const colorMap = {
              plan: "#01366c",
              other: "",
              inactiveGrey: "#878787",
            };

            const hasChemist = project?.chemist !== "Unassigned";
            const showClaim = isClaimer && !hasChemist;

            const rowColor =
              project.phase === "plan" ? colorMap.plan : colorMap.other;
            const canClaim = Boolean(project.is_approved) && isClaimer && !hasChemist;
            let clickAble = Boolean(project.is_approved) && isClaimer;
            
            const cellText = clickAble ? classes.yellowCellText : classes.cellText;
            const claimButtonColor = clickAble ? colorMap.other : colorMap.inactiveGrey;

            const rowColorStyle = { color: rowColor };

            return (
              <Grid
                key={idx}
                container
                direction="row"
                style={{
                  overflow: "visible",
                  cursor: "pointer",
                }}
                onClick={() => {
                  clickAble && selectProject(project);
                }}
              >
                <Grid
                  item
                  className={classes.leftCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                    position: "relative",
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {project.refcode}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.middleCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                    position: "relative",
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {project.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.middleCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {project.company}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.middleCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {project.customer}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.middleCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {capitalCase(project.phase)}
                  </Typography>
                </Grid>
                <Grid
                  className={classes.middleCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                    position: "relative",
                    cursor: canClaim ? "pointer" : undefined,
                    color: claimButtonColor,
                  }}
                  container
                  alignItems="center"
                  justify="center"
                  onClick={async (event: React.MouseEvent<HTMLDivElement>) => {
                    if (!canClaim) return;
                    await claimProjects(project.id, userId);
                    selectProject(project);
                    event.stopPropagation();
                  }}
                >
                  {showClaim && canClaim && (
                    <SvgIcon>
                      <ClaimIcon />
                    </SvgIcon>
                  )}
                  <Typography
                    className={cellText}
                    style={
                      showClaim
                        ? { color: claimButtonColor }
                        : { ...rowColorStyle }
                    }
                  >
                    {!showClaim ? project.chemist : canClaim ? "Claim Project" : "LPR Incomplete"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.rightCell}
                  style={{
                    borderTop: isFirst ? "inherit" : undefined,
                    borderBottom: isLast ? "inherit" : undefined,
                  }}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={cellText} style={rowColorStyle}>
                    {project.lab}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
