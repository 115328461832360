import { Grid, makeStyles, SvgIcon } from "@material-ui/core";
import React, { useState } from "react";
import { hideMobile, showMobile } from "../Utils/utils";
import AddButton from "./AddButton";
import OrangeDialogBar from "./OrangeDialogBar";
import { ReactComponent as PDFIcon } from "../Images/pdf.svg";
import { IOrangeDialogContainerProps } from "Interfaces/props/orangeDialogContainerProps";

const useStyles = makeStyles((theme) => ({
  root: {
    "border-radius": "5%",
    border: "solid 2.5px #01366c",
    "background-color": " #F79730",
    width: "444px",
    "min-height": " 350px",
    "box-shadow": "inset -4px -3px 0px 1px #F26410",
    outline: 0,
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
    position: "relative",
    "z-index": 5,
    "&:after": {
      "border-color": "rgba(247, 151, 48, 0)",
      "border-left-color": "#F79730",
      "border-width": " 20px",
      "margin-top": "-20px",
      left: "100%",
      top: "150px",
      border: "solid transparent",
      content: '""',
      height: 0,
      width: 0,
      position: "absolute",
      "pointer-events": "none",
    },
    "&:before": {
      " border-color": "rgba(1, 54, 108, 0)",
      "border-left-color": "#01366c",
      "border-width": "23px",
      "margin-top": "-23px",
      left: "100%",
      top: "150px",
      border: "solid transparent",
      content: '""',
      height: 0,
      width: 0,
      position: "absolute",
      "pointer-events": "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  mobileRoot: {
    margin: "auto",
    width: "80%",
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  mainContent: {
    marginTop: 26,
    width: 350,
    margin: "auto",
    marginBottom: 25,
    maxHeight: 380,
    overflowY: "auto",
    "@media (max-height: 900px)": {
      maxHeight: 310,
    },
    "@media (max-height: 700px)": {
      maxHeight: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxHeight: "none",
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  bottomBar: {
    width: 300,
    margin: "auto",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  iconRoot: {
    width: 25,
    height: 25,
  },
  attachmentLink: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  attachmentsContainer: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
}));

const OrangeDialogContainer: React.FC<IOrangeDialogContainerProps> = (
  props
) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const realIndex =
    props.slideIndex || props.slideIndex === 0
      ? props.slideIndex
      : currentIndex;
  const attachments = props.attachments || [];
  const [isReady, setReady] = useState(false);
  const classes = useStyles();
  const children = React.Children.toArray(props.children);
  const isStart = realIndex === 0;
  const isEnd = realIndex === children.length - 1;

  const transitionForward = () => {
    if (isEnd || !isReady) return;
    setCurrentIndex(realIndex + 1);
    setReady(false);
    if (props.onForward) props.onForward(realIndex);
  };

  const transitionBackward = () => {
    if (isStart) return;
    setCurrentIndex(realIndex - 1);
    setReady(true);
    if (props.onBackward) props.onBackward(realIndex);
  };

  const changeReady = (val: boolean) => {
    setReady(val);
  };

  const showAttachments = () => {
    if (!attachments.length) return;
    return attachments.map((attachment, index) => {
      return (
        <span key={attachment.index} className={classes.attachmentLink}>
          <SvgIcon viewBox='0 0 35 35' className={classes.iconRoot}>
            <PDFIcon />
          </SvgIcon>
          {attachment.filename}
        </span>
      );
    });
  };

  if (children.length === 0) return <React.Fragment />;
  //ts-ignore-next-line
  let mainElement = React.cloneElement(children[realIndex] as any, {
    setReady: changeReady,
  });
  let body = (
    <>
      <Grid item className={classes.mainContent}>
        {mainElement}
      </Grid>

      <Grid item className={classes.bottomBar}>
        <AddButton
          size={25}
          fontSize={15}
          label='Add Attachment'
          onClick={() => {}}
          style={{ marginTop: 10 }}
          file
          accept={
            ".pdf, tiff|image/*, .csv, .jpeg, .gif, .png, .xls, .xlsx, .ppt, .xlsx, .pptx, .docx, .doc"
          }
          onChange={props.onChange}
        />
        <Grid item className={classes.attachmentsContainer}>
          {showAttachments()}
        </Grid>
        <OrangeDialogBar
          isStart={isStart}
          isEnd={isEnd}
          onForward={transitionForward}
          onBackward={transitionBackward}
          isReady={isReady}
        />
      </Grid>
    </>
  );
  return (
    <>
      <Grid
        container
        direction='column'
        justify='space-between'
        className={classes.mobileRoot + " " + classes.showMobile}>
        {body}
      </Grid>
      <Grid
        container
        direction='column'
        justify='space-between'
        className={classes.root + " " + classes.hideMobile}>
        {body}
      </Grid>
    </>
  );
};

export default OrangeDialogContainer;
