import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BubbleMenu from "Views/Admin/BubbleMenu";
import { useState } from "react";
import NarrowTextfield from "Components/NarrowTextfield";
import {
  updatePrivateMessage,
  deletePrivateMessage,
} from "Redux/slices/projectsSlice";
import { useDispatch } from "react-redux";
import { projectApi } from "Utils/api/projectApi";
import { ILogMessageProps } from "Interfaces/props/logMessageProps";

const LogMessage = (props: ILogMessageProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [messageContent, setMessageContent] = useState(props.content);
  const [isEditing, setIsEditing] = useState(false);
  const cancelEditing = () => {
    setMessageContent(props.content);
    setIsEditing(false);
  };

  const updateMessage = () => {
    projectApi.updateNote(props.id, messageContent).then(() => {
      dispatch(
        updatePrivateMessage({
          id: props.id,
          content: messageContent,
        })
      );
      setIsEditing(false);
    });
  };

  const deleteMessage = () => {
    projectApi.deleteNote(props.id).then(() => {
      dispatch(
        deletePrivateMessage({
          id: props.id,
          content: messageContent,
        })
      );
      setIsEditing(false);
    });
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid
        container
        direction="column"
        style={{
          marginTop: 10,
          marginBottom: 10,
          width: "max-content",
        }}>
        {props.title && (
          <Grid item>
            <Typography className={classes.blueText}>{props.title}</Typography>
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.blueText}>{props.date}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.orangeText}>{props.time}</Typography>
        </Grid>
        <Grid item>
          {!isEditing && (
            <Typography className={classes.mainText}>
              {messageContent}
            </Typography>
          )}
          {isEditing && (
            <NarrowTextfield
              width={"auto"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMessageContent(event.target.value);
              }}
              value={messageContent}
              placeholder="Note"
            />
          )}
        </Grid>
      </Grid>
      {props.canDelete && (
        <Grid item>
          <Grid container justify="flex-end">
            <BubbleMenu
              onEdit={() => {
                setIsEditing(true);
              }}
              onDelete={deleteMessage}
              closeOnAction
            />
          </Grid>
          <Grid>
            {isEditing && (
              <Grid className={classes.mainText}>
                <span onClick={cancelEditing}>Cancel</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span onClick={updateMessage}>Save</span>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LogMessage;
