import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import BlueColumnHeaders from "../Components/BlueColumnHeaders";
import BlueTile from "../Components/BlueTile";
import Breadcrumbs from "../Components/Breadcrumbs";
import MultipleChoice from "../Components/MultipleChoice";
import PictureTile from "../Components/PictureTile";
import SearchBar from "../Components/SearchBar";
import SmallButton from "../Components/SmallButton";
import SampleImage from "../Images/example-paint.jpg";
import MapImage from "../Images/azelis-map.jpg";
import { headerCss, hideMobile, showMobile } from "../Utils/utils";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  item: { width: "100%" },
  headerText: {
    "font-family": "MuseoSlab",
    "font-size": "50px",
    "font-weight": 100,
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    "letter-spacing": "1.79px",
    "text-align": "center",
    color: "#01366c",
  },
  searchRow: {
    marginTop: 25,
  },
  searchBox: {
    flexGrow: 1,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: " normal",
    textAlign: "center",
    color: "#01366c",
    width: 600,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 15,
    },
  },
  thirdHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: 50,
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    color: "#01366c",
    marginTop: 30,
  },
  thirdSubHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "15px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    marginTop: 15,
    marginBottom: 25,
  },
  panelHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    color: "#01366c",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: { textAlign: "center" },
  },
  panel: {
    boxSizing: "border-box",
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  leftPanel: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
      //padding: 5,
    },
  },
  rightPanel: {
    width: "70%",
    borderRight: "2px dashed #01366c",
  },
  searchButton: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      marginTop: 15,
      marginBottom: 15,
    },
  },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
}));

const regions = ["West", "Southeast", "Southwest", "East", "Midwest"];

const results = new Array(10).fill({
  src: SampleImage,
  title: "Concrete Protection",
  body:
    "From capital infrastructures to back yard patios, protect that concrete investment from the elements and endure the test of time.",
});

const results2 = new Array(3).fill({
  src: SampleImage,
  title: "Coating Concrete",
  body:
    "High traffic area? Environmental exposure? Frequent sanitization?... What does your concrete coating need to endure?",
  description:
    "The challenge with concrete? It’s not as hard as a rock. Whether it’s to cover concrete in a high foot traffic zone or the exterior of a building, concrete coatings are needed for long-term durability of structure and aesthetic appeal. Get the performance improvements you’ve been looking for with these raw materials from Azelis Americas!",
});

const dummyMaterials = new Array(5).fill({
  title: "ACROFLOR 1000",
  description:
    "Radio telescope. Cosmic fugue lorem ipsum dolor sit amet tesseract. Star stuff star light harvesting adipisci velit, cosmic fugue.",
  caption: "Category goes here",
});

const dummyFormulas = new Array(3).fill({
  title: "Clear Coat 2K-Epoxy Flooring – Ambient Cure – Anti-Blushing",
  description:
    "Radio telescope. Cosmic fugue lorem ipsum dolor sit amet tesseract. Star stuff star light harvesting adipisci velit, cosmic fugue.",
  caption: "Application goes here",
});

const materialOptions = ["Adhesion promotors (5)", "Acrylic resin (3)"];
const formulaOptions = ["Paint & Primer (5)", "Interior Paint (3)"];

interface IMapPanel {
  onClose: () => void;
}

const MapPanel = (props: IMapPanel) => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ width: "100%", marginBottom: 25 }}
      >
        <Typography className={classes.headerText}>Regions</Typography>
        <IconButton style={{ marginLeft: 10 }} onClick={props.onClose}>
          <Close />
        </IconButton>
      </Grid>

      <Grid item style={{ margin: "auto" }}>
        <img
          style={{ width: "100%", maxWidth: 1100 }}
          src={MapImage}
          alt="Region Map"
        ></img>
      </Grid>
    </Grid>
  );
};

export default function ProblemToSolve() {
  const [region, setRegion] = React.useState(-1);
  const [firstSelection, setFirstSelection] = React.useState(0);
  const [secondSelection, setSecondSelection] = React.useState(0);
  const [selectedMaterial, setSelectedMaterial] = React.useState(-1);
  const [selectedFormula, setSelectedFormula] = React.useState(-1);
  const [stage, setStage] = React.useState(0);
  const [mobileNav, setMobileNav] = React.useState(0);
  const [isMap, setIsMap] = React.useState(false);
  const classes = useStyles();

  document.title = "Problem To Solve";

  const changeRegion = (idx: number) => {
    setRegion(idx);
  };

  const toStageTwo = (idx: number) => {
    setStage(1);
    setFirstSelection(idx);
  };

  const toStageThree = (idx: number) => {
    setStage(2);
    setSecondSelection(idx);
  };

  const changeStage = (idx: number) => {
    setStage(idx);
  };

  const showMap = () => {
    setIsMap(true);
  };
  const hideMap = () => {
    setIsMap(false);
  };

  const rightElement = (
    <>
      <Typography className={classes.panelHeaderText}>
        Raw materials recommendations
      </Typography>
      <SearchBar
        placeholder="Keyword Search"
        width="95%"
        style={{ marginTop: 15 }}
      />
      <MultipleChoice
        options={materialOptions}
        title="Categories:"
        horizontal
        onSelect={setSelectedMaterial}
        selectedIdx={selectedMaterial}
        fontSize={28}
      />
      <Grid container direction="row" justify="center">
        {dummyMaterials.map((material) => {
          return <BlueTile type="material" {...material} />;
        })}
      </Grid>
    </>
  );

  const leftElement = (
    <>
      <Typography className={classes.panelHeaderText}>
        Starting point formulas
      </Typography>
      <SearchBar
        placeholder="Keyword Search"
        width="95%"
        style={{ marginTop: 15 }}
      />
      <MultipleChoice
        options={formulaOptions}
        title="Applications: "
        horizontal
        onSelect={setSelectedFormula}
        selectedIdx={selectedFormula}
        fontSize={28}
      />
      <Grid container direction="row" justify="center">
        {dummyFormulas.map((formula) => {
          return (
            <Grid item key={formula.title}>
              <BlueTile type="formula" {...formula} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
  if (isMap) return <MapPanel onClose={hideMap} />;

  switch (stage) {
    case 0:
      return (
        <Grid container className={classes.root}>
          <Grid item style={{ margin: "auto" }}>
            <Typography className={classes.headerText}>
              Problem to Solve
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.searchRow}
          >
            <Grid item className={classes.searchBox}>
              <SearchBar placeholder="Keyword Search" width="100%" />
            </Grid>
            <Grid item className={classes.searchButton}>
              <SmallButton color="green" onClick={() => {}}>
                SEARCH
              </SmallButton>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <MultipleChoice
              horizontal
              tooltip
              title="Regions:"
              options={regions}
              selectedIdx={region}
              onSelect={changeRegion}
              fontSize={28}
              onToolTip={showMap}
              responsive
            />
          </Grid>
          <Grid container direction="row" justify="center">
            {results.map((result, idx) => {
              return (
                <PictureTile
                  src={result.src}
                  title={result.title}
                  body={result.body}
                  onClick={() => {
                    console.log("onclick");
                    toStageTwo(idx);
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid container className={classes.root}>
          <Grid item>
            <Breadcrumbs
              crumbs={["Problem To Solve", results[firstSelection].title]}
              onClick={changeStage}
            />
          </Grid>
          <Grid item className={classes.item}>
            <Typography className={classes.headerText}>
              {results[firstSelection].title}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography className={classes.subHeaderText}>
              {results[firstSelection].body}
            </Typography>
          </Grid>
          <Grid container direction="row" justify="center">
            {results2.map((result, idx) => {
              return (
                <PictureTile
                  src={result.src}
                  title={result.title}
                  body={result.body}
                  onClick={() => {
                    toStageThree(idx);
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid className={classes.root}>
          <Grid item style={{ marginTop: 25 }}>
            <Breadcrumbs
              crumbs={[
                "Problem To Solve",
                results[firstSelection].title,
                results2[secondSelection].title,
              ]}
              onClick={changeStage}
            />
            <Typography className={classes.thirdHeaderText}>
              {results2[secondSelection].title}
            </Typography>
            <Typography className={classes.thirdSubHeaderText}>
              {results2[secondSelection].description}
            </Typography>
          </Grid>
          <Grid item className={classes.showMobile}>
            <BlueColumnHeaders
              labels={["Formulas", "Materials"]}
              selectedIdx={mobileNav}
              onClick={(idx: number) => {
                setMobileNav(idx);
              }}
              small
            />
          </Grid>
          <Grid container direction="row">
            <span className={classes.hideMobile}>
              <Grid item className={classes.panel + " " + classes.rightPanel}>
                {rightElement}
              </Grid>
              <Grid item className={classes.panel + " " + classes.leftPanel}>
                {leftElement}
              </Grid>
            </span>
            <span className={classes.showMobile}>
              {mobileNav === 0 ? (
                <>
                  <Grid
                    item
                    className={classes.panel + " " + classes.leftPanel}
                  >
                    {leftElement}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>{rightElement}</Grid>
                </>
              )}
            </span>
          </Grid>
        </Grid>
      );
    default:
      return <></>;
  }
}
