const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const fullMonths = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatTimeForChat = (dateTime: string) => {
  if (!dateTime) return "";
  const newDate =  new Date(dateTime);
  const month = months[newDate.getMonth()];
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  const [date, time] = dateTime.split("T");
  if (!time) return date;
  const timePeriod = Number(hours) > 11 ? "p.m" : "a.m";

  return `${day}-${month}-${year} ${
    Number(hours) % 12
  }:${minutes} ${timePeriod}`;
};

export const formatDateTimeForNotes = (dateTime: string) => {
  if (!dateTime) return ["", ""];
  // const estTime = new Date(new Date(dateTime).toLocaleString("en-US", {timeZone: "America/New_York"}));
  const estTime = new Date(dateTime);
  const month = fullMonths[new Date(dateTime).getMonth()];
  const day = estTime.getDate();
  const year = estTime.getFullYear();
  const hours = estTime.getHours();
  const minutes = estTime.getMinutes();
  const timePeriod = Number(hours) > 11 ? "p" : "a";
  return [
    `${month} ${day},${year}`,
    `${Number(hours) % 12}:${minutes} ${timePeriod}`,
  ];
};

export default formatTimeForChat;
