import { Grid, makeStyles, Typography } from "@material-ui/core";
import { headerCss, hideMobile, post, showMobile } from "../Utils/utils";
import * as React from "react";
import NarrowTextfield from "../Components/NarrowTextfield";
import SmallButton from "../Components/SmallButton";
import BlueColumnHeaders from "../Components/BlueColumnHeaders";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginBottom: 25,
  },
  bodyText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  panelRoot: {
    flex: 1,
    marginTop: 25,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: { flex: "none" },
  },
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
    [theme.breakpoints.down("xs")]: {
      padding: 5,
      borderRight: "none",
      width: "100%",
    },
  },
  rightPanel: { width: "50%", padding: 25 },
  textField: {
    marginBottom: 25,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "5px !important",
    },
  },
  showMobile: showMobile(theme) as any,
  hideMobile: hideMobile(theme) as any,
  mobileItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      width: "100%",
    },
  },
}));

export default function ContactUs() {
  const [fullName, setFullName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [mobileNav, setMobileNav] = React.useState(0);
  const classes = useStyles();

  const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };
  const changeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(event.target.value);
  };
  const changeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const changePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const submit = () => {
    console.log({ fullName, company, message, email, phone });
    post("/contact_us", { name: fullName, company, message, phone, email });
  };

  const contactInfo = (
    <>
      <Typography className={classes.subHeaderText}>Contact Info</Typography>
      <Typography className={classes.titleText}>Email Address</Typography>
      <Typography className={classes.subText}>leads@azelis.com</Typography>
      <Typography className={classes.titleText}>Phone Number</Typography>
      <Typography className={classes.subText}>(123) 456-7890</Typography>
      <Typography className={classes.titleText}>Address</Typography>
      <Typography className={classes.subText}>
        262 Harbor Drive, 3rd Floor
        <br /> Stamford, CT 06902
      </Typography>
    </>
  );

  const messageForm = (
    <>
      <Typography className={classes.subHeaderText}>Message Us</Typography>
      <Grid item className={classes.mobileItem}>
        <NarrowTextfield
          placeholder="Full Name"
          onChange={changeName}
          width="100%"
          className={classes.textField}
        />
      </Grid>
      <Grid item className={classes.mobileItem}>
        <NarrowTextfield
          placeholder="Company"
          onChange={changeCompany}
          width="100%"
          className={classes.textField}
        />
      </Grid>
      <Grid container direction="row" justify="space-between">
        <Grid item className={classes.mobileItem}>
          <NarrowTextfield
            placeholder="Email Address"
            onChange={changeEmail}
            className={classes.textField}
            width={300}
          />
        </Grid>
        <Grid item className={classes.mobileItem}>
          <NarrowTextfield
            placeholder="Phone Number"
            onChange={changePhone}
            className={classes.textField}
            width={300}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.mobileItem}>
        <NarrowTextfield
          placeholder="Send us a little message…"
          onChange={changeMessage}
          className={classes.textField}
          width="100%"
          height={300}
          style={{ borderRadius: 35 }}
          multiline
        />
      </Grid>
      <Grid container direction="row" justify="flex-end">
        <SmallButton color="green" onClick={() => submit()}>
          SUBMIT
        </SmallButton>
      </Grid>
    </>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item style={{ width: "100%" }}>
        <Typography className={classes.headerText}>Contact Us</Typography>
      </Grid>
      <Grid
        item
        className={classes.showMobile}
        style={{ margin: "auto", marginTop: 25 }}>
        <BlueColumnHeaders
          labels={["Message Us", "Contact Info"]}
          onClick={(idx: number) => {
            setMobileNav(idx);
          }}
          selectedIdx={mobileNav}
          small
        />
      </Grid>
      <Grid container direction="row" className={classes.panelRoot}>
        <Grid container direction="column" className={classes.showMobile}>
          {mobileNav === 0 ? messageForm : contactInfo}
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.leftPanel + " " + classes.hideMobile}>
          {messageForm}
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.rightPanel + " " + classes.hideMobile}>
          {contactInfo}
        </Grid>
      </Grid>
    </Grid>
  );
}
