import { makeStyles, SvgIcon } from "@material-ui/core";
import { CircleCheckboxProps } from "Interfaces/props/circleCheckboxProps";
import * as React from "react";
import { ReactComponent as OptionCircle } from "../Images/option.svg";
import { ReactComponent as SelectedCircle } from "../Images/selected.svg";
//import OptionCircle from "../Images/optionCircle.png";
//import SelectedCircle from "../Images/selectedCircle.png";

const useStyles = makeStyles((theme) => ({
  root: {
    "background-size": "contain",
    transition: "all 0.3s ease",
    cursor: "pointer",
    width: (props: CircleCheckboxProps) => props.size || 25,
    height: (props: CircleCheckboxProps) => props.size || 25,
  },
}));

const CircleCheckbox = (props: CircleCheckboxProps) => {
  const classes = useStyles(props);

  return (
    <SvgIcon
      className={classes.root}
      onClick={props.toggleSelect}
      viewBox={"0 0 26 26"}
    >
      {props.isSelected ? <SelectedCircle /> : <OptionCircle />}
    </SvgIcon>
  );
};

export default CircleCheckbox;
