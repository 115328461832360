import { Grid, makeStyles, Typography } from "@material-ui/core";
import { InfoPanel } from "Components/InfoPanel";
import * as React from "react";
import { getUserGroup, getUserId } from "Redux/slices/userSlice";
import { projectApi } from "Utils/api/projectApi";
import BackButton from "../../Components/BackButton";
import BlueColumnHeaders from "../../Components/BlueColumnHeaders";
import ChatBox from "../../Components/ChatBox";
import CommercialAssessment from "../../Components/CommercialAssessment";
import LargeButton from "../../Components/LargeButton";
import LPRDialog from "../../Components/LPRDialog";
import NavText from "../../Components/NavText";
import SmallButton from "../../Components/SmallButton";
import TechnicalAssessment from "../../Components/TechnicalAssessment";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  fetchProject,
  getActiveProject,
} from "../../Redux/slices/projectsSlice";
import {
  getNDAValue,
  headerCss,
  hideMobile,
  showMobile,
} from "../../Utils/utils";
import WebSocketWrapper from "Components/WebSocketWrapper";
import { formatTimeForChat } from "Utils/timeFormats";
import { useHistory, useParams } from "react-router";
import ConfirmDialog from "Components/ConfirmDialog";
import LPRSuccessDialog from "./LPRSuccessDialog";
import { Project } from "Interfaces/project";
import { IApiState } from "Interfaces/apiState";
import { IMessage } from "Interfaces/message";
import { INote } from "Interfaces/note";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
    marginBottom: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
  },
  buttonText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    marginLeft: 10,
  },
  titleText: {
    fontFamily: "MuseoSlab",
    fontSize: "19px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
  },
  assessmentText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginTop: 35,
  },
  textField: { marginTop: 15 },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
  leftPanel: {
    width: "50%",
    borderRight: "2px dashed #01366c",
    boxSizing: "border-box",
    padding: 25,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
      padding: 5,
    },
  },
  searchBar: {
    margin: "auto",
    marginTop: 26,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MOBILE_LABELS = [
  "Information",
  "Application",
  "Customer Chat",
  "Internal Chat",
];

const canEditCA = ["TechnicalSalesRepresentative"];
const canEditTA = ["LabWorker", "LabManager"];

export default function AdminLPRDetails() {
  const dispatch = useAppDispatch();
  const params: any = useParams();
  const project = useAppSelector(getActiveProject);
  const userId = useAppSelector(getUserId);
  const userRole: string = useAppSelector(getUserGroup) || "";
  const classes = useStyles();

  const [secondColumn, setSecondColumn] = React.useState(0);
  const [mobileColumn, setMobileColumn] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [activeProject, setActiveProject] = React.useState<Partial<Project>>(
    {}
  );
  const [customerMessages, setCustomerMessages] = React.useState<IMessage[]>(
    []
  );
  const [internalMessages, setInternalMessages] = React.useState<IMessage[]>(
    []
  );
  const [apiState, setApiState] = React.useState<IApiState>({});
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogSubTitle, setDialogSubTitle] = React.useState("");
  const [dialogButton, setDialogButton] = React.useState("");
  const [approvalStatus, setApprovalStatus] = React.useState<boolean | null>(
    null
  );
  const [dialogCancelButtonText, setDialogCancelButtonText] =
    React.useState("");
  const [submitButtonColor, setSubmitButtonColor] = React.useState<
    "red" | "green" | "blue"
  >("green");
  // let caErrors = false;
  const [caErrors, setCAErrors] = React.useState<Function>(() => {
    if (localStorage.getItem("labId")) {
      return false;
    }
    return true;
  });
  const [showCAErrors, setShowCAErrors] = React.useState<boolean>(false);
  const history = useHistory();

  // const setCAErrors = (value: boolean) => {
  //   caErrors = value;
  // };

  React.useEffect(() => {
    console.log("caErrors value inside useState: ", caErrors);
  }, [caErrors]);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = React.useState("");

  const closeDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    history.push("/admin/lpr");
  };

  const updateCommercialAssessment = (updatedValue: any) => {
    const updatedProject = {
      ...activeProject,
      commercial_assessment: updatedValue,
    };

    dispatch({
      type: "projects/setActiveProject",
      payload: updatedProject,
    });
    setActiveProject(updatedProject);
  };

  React.useEffect(() => {
    if (!Object.keys(project).length) {
      setApiState({ loading: true });
      dispatch(fetchProject(params.projectId));
    }
    setActiveProject(project);
    setApiState({ loading: false, loaded: true });
  }, [dispatch, project]);

  React.useEffect(() => {
    const mappedMessages: IMessage[] =
      activeProject.public_messages?.map((val: INote) => ({
        message: val.body as string,
        self: val.user.id === userId,
        date: formatTimeForChat(val.created_at as string),
        title: val.user.name as string,
      })) || [];

    setCustomerMessages(mappedMessages);

    const mappedPrivateMessages: IMessage[] =
      activeProject.private_messages?.map((val: INote) => ({
        message: val.body as string,
        self: val.user.id === userId,
        date: formatTimeForChat(val.created_at as string),
        title: val.user.name as string,
      })) || [];

    setInternalMessages(mappedPrivateMessages);
  }, [activeProject, userId]);

  const editable = userRole === "TechnicalSalesRepresentative";
  const title = project.name;

  const approvalApi = (approvalNotes: string) => {
    projectApi
      .updateApproval(project.id, approvalStatus, approvalNotes)
      .then(() => {
        setConfirmationDialogOpen(false);
        setSuccessDialogOpen(true);
      });
  };

  const onNewMessage = (body: string) => {
    if (userId)
      projectApi
        .addNotesApi({
          body,
          projectId: project.id,
          isPrivate: true,
          userId: userId,
        })
        .then((response) => {
          const updatedNotes = [
            ...(activeProject.private_notes || []),
            response.data,
          ];
          setActiveProject({
            ...activeProject,
            private_notes: updatedNotes,
          });
        });
  };

  const createPublicMessage = (message: string) => {
    if (userId) {
      projectApi.addMessageApi({
        body: message,
        projectId: activeProject.id || "",
        isPrivate: false,
        userId: userId,
      });
    }
  };

  let publicMessages = activeProject.public_messages || [];
  const onRecivingMessage = (data: { message: INote }) => {
    publicMessages = [...publicMessages, data.message];
    setActiveProject({
      ...activeProject,
      public_messages: publicMessages,
    });
  };

  const wsParams = {
    projectId: activeProject.id || "",
  };

  let customerChat = (
    <WebSocketWrapper
      channel="MessagesChannel"
      onMessageRecived={onRecivingMessage}
      params={wsParams}
    >
      <Grid
        item
        style={{ margin: "auto", width: "max-content", marginTop: 25 }}
      >
        <ChatBox
          messages={customerMessages}
          canMessage={true}
          onNewMessage={createPublicMessage}
          title="Customer Chat"
        />
      </Grid>
    </WebSocketWrapper>
  );

  const createPrivateMessage = (message: string) => {
    if (userId) {
      projectApi.addMessageApi({
        body: message,
        projectId: activeProject.id || "",
        isPrivate: true,
        userId: userId,
      });
    }
  };

  let privateMessages = activeProject.private_messages || [];
  const onRecivingPrivateMessage = (data: { message: INote }) => {
    privateMessages = [...privateMessages, data.message];
    setActiveProject({
      ...activeProject,
      private_messages: privateMessages,
    });
  };

  const updateProjectTa = (field: string, value: number) => {
    setActiveProject({
      ...activeProject,
      technical_assessment: {
        ...activeProject.technical_assessment,
        [field]: value
      },
    });
  };

  let internalChat = (
    <WebSocketWrapper
      channel="PrivateMessagesChannel"
      onMessageRecived={onRecivingPrivateMessage}
      params={wsParams}
    >
      <Grid
        item
        style={{ margin: "auto", width: "max-content", marginTop: 25 }}
      >
        <ChatBox
          messages={internalMessages}
          canMessage={true}
          onNewMessage={createPrivateMessage}
          title="Internal Chat"
        />
      </Grid>
    </WebSocketWrapper>
  );

  const technicalAssessmentEditable =
    canEditTA.includes(userRole) &&
    ["technical_assessment", "vp_approval"].includes(
      activeProject?.stage || "vp_approval"
    );
  const commercialAssessmentEditable = canEditCA.includes(userRole);

  console.log(getNDAValue(activeProject), "activeProject");

  let informationElement = (
    <>
      <Typography className={classes.titleText}>Customer</Typography>
      <Typography className={classes.subText}>
        {activeProject.contact}
      </Typography>
      <Typography className={classes.titleText}>Contact</Typography>
      <Typography className={classes.subText}>
        {activeProject.customer_name}
      </Typography>
      {userRole === "Admin" ? (
        <>
          <Typography className={classes.titleText}>TSR</Typography>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography className={classes.subText}>{project.tsr}</Typography>
            </Grid>
            <Grid
              item
              style={{ marginLeft: "auto", marginRight: "auto" }}
              onClick={() => {
                history.push(`/admin/assign-tsr/${project.id}`);
              }}
            >
              <SmallButton color="blue" children="CHANGE TSR"></SmallButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {activeProject.id === params.projectId && (
        <CommercialAssessment
          editable={commercialAssessmentEditable}
          notesEditable={true}
          id={params.projectId}
          nda={getNDAValue(activeProject)}
          ndaLink={activeProject.commercial_assessment?.nda}
          saleEta={activeProject.commercial_assessment?.sales_eta_in_days}
          customerEta={
            activeProject.commercial_assessment?.customer_eta_in_days
          }
          arp={activeProject.commercial_assessment?.annual_revenue_potential}
          notes={activeProject.private_notes}
          successProbability={
            activeProject.commercial_assessment?.success_probability
          }
          products={activeProject.commercial_assessment?.lines}
          onNewMessage={onNewMessage}
          updateCommercialAssessment={updateCommercialAssessment}
          apiState={apiState}
          setCAErrors={setCAErrors}
        />
      )}
      {userRole === "VicePresident" ||
        userRole === "LabWorker" ||
        userRole === "LabManager" ? (
        <TechnicalAssessment
          editable={technicalAssessmentEditable}
          id={project.id}
          eta={activeProject.technical_assessment?.lab_eta_in_days}
          probability={activeProject.technical_assessment?.success_probability}
          lab={activeProject.lab}
          chemist={activeProject.technical_assessment?.chemist}
          setCAErrors={setCAErrors}
          updateProjectTA={updateProjectTa}
        />
      ) : (
        <></>
      )}
    </>
  );

  let rightColumnElement = <></>;
  let mobileRightElement = <></>;
  switch (secondColumn) {
    case 0:
      rightColumnElement = <InfoPanel project={activeProject} canEdit={true} />;
      break;
    case 1:
      rightColumnElement = customerChat;
      break;
    case 2:
      rightColumnElement = internalChat;
      break;
    default:
      break;
  }

  switch (mobileColumn) {
    case 0:
      mobileRightElement = informationElement;
      break;
    case 1:
      mobileRightElement = <InfoPanel project={activeProject} canEdit={true} />;
      break;
    case 2:
      mobileRightElement = customerChat;
      break;
    case 3:
      mobileRightElement = internalChat;
      break;
    default:
      break;
  }

  const markCompleteSubmission = () => {
    const projectId = activeProject?.id || "";
    if (canEditTA.includes(userRole)) {
      projectApi.updateTechnical(projectId, {
        technical_assessment: {
          is_submitted: true,
        },
      });
    }
    if (canEditCA.includes(userRole)) {
      projectApi.updateCommercial(projectId, {
        commercial_assessment: {
          is_submitted: true,
        },
      });
    }
  };
  return (
    <Grid container direction="column" className={classes.root}>
      <ConfirmDialog
        open={confirmationDialogOpen}
        handleClose={closeDialog}
        titleText={dialogTitle}
        subtext={dialogSubTitle}
        buttonText={dialogButton}
        cancelButtonText={dialogCancelButtonText}
        onSubmit={approvalApi}
        submitButtonColor={submitButtonColor}
        showTextField
      />
      <LPRSuccessDialog
        isOpen={successDialogOpen}
        successMessage={successDialogMessage}
        handleClose={closeSuccessDialog}
      />
      <LPRDialog
        isOpen={dialogOpen}
        handleClose={(goBack: boolean) => {
          setDialogOpen(false);
          if (goBack) {
            history.push("/admin/lpr");
          }
        }}
        isUser={!editable}
        productName={title}
        onSubmit={markCompleteSubmission}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ width: "max-content", height: "max-content" }}
        >
          <BackButton
            onClick={() => {
              history.push("/admin/lpr");
            }}
          />
          <Typography className={classes.buttonText}> All LPRs</Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.headerText}
            style={{ marginBottom: 0 }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item style={{ width: 103 }}></Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ flex: 1, overflowY: "auto", marginTop: 25 }}
      >
        <Grid container direction="column" className={classes.leftPanel}>
          <Grid
            container
            direction="row"
            //justify="center"
            className={classes.showMobile}
          >
            {MOBILE_LABELS.map((label, idx) => {
              return (
                <NavText
                  key={idx}
                  text={label}
                  onClick={() => {
                    setMobileColumn(idx);
                  }}
                  isActive={mobileColumn === idx}
                />
              );
            })}
          </Grid>
          <Grid container direction="column" className={classes.showMobile}>
            {mobileRightElement}
          </Grid>
          <Grid container direction="column" className={classes.hideMobile}>
            {informationElement}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={{ width: "50%", padding: 25 }}
          className={classes.hideMobile}
        >
          <Grid item>
            {userRole === "VicePresident" ? (
              <BlueColumnHeaders
                labels={["Application", "Internal Chat"]}
                onClick={(idx: number) => {
                  if (idx === 1) {
                    setSecondColumn(idx + 1);
                  } else {
                    setSecondColumn(idx);
                  }
                }}
                selectedIdx={secondColumn}
                small
              />
            ) : (
              <BlueColumnHeaders
                labels={["Application", "Customer Chat", "Internal Chat"]}
                onClick={(idx: number) => {
                  setSecondColumn(idx);
                }}
                selectedIdx={secondColumn}
                small
              />
            )}
            {rightColumnElement}
          </Grid>
        </Grid>
        <Grid item style={{ margin: "auto", marginTop: 15, marginBottom: 15 }}>
          {userRole === "VicePresident" && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ width: 800, margin: "auto" }}
            >
              {activeProject.stage === "vp_approval" && (
                <>
                  <SmallButton
                    color="red"
                    onClick={() => {
                      setConfirmationDialogOpen(true);
                      setDialogTitle("Close LPR?");
                      setDialogSubTitle(
                        "Let the TSR know why you're closing this LPR"
                      );
                      setDialogButton("Close");
                      setSubmitButtonColor("red");
                      setApprovalStatus(false);
                      setSuccessDialogMessage(
                        `The LPR for ${activeProject.name} was closed.`
                      );
                    }}
                  >
                    CLOSE
                  </SmallButton>
                  <LargeButton
                    color="orange"
                    text="MORE INFO REQUIRED"
                    onClick={() => {
                      setConfirmationDialogOpen(true);
                      setDialogTitle("Please elaborate");
                      setDialogSubTitle(
                        "Let the TSR know why you're requesting more info on this project"
                      );
                      setDialogButton("Request Info");
                      setSubmitButtonColor("blue");
                      setApprovalStatus(null);
                      setSuccessDialogMessage(
                        `More information has been requested.`
                      );
                    }}
                  />
                  <SmallButton
                    color="green"
                    onClick={() => {
                      setConfirmationDialogOpen(true);
                      setDialogTitle("Approve LPR?");
                      setDialogSubTitle(
                        "Are you sure you want to approve this LPR?"
                      );
                      setDialogButton("Approve");
                      setSubmitButtonColor("green");
                      setApprovalStatus(true);
                      setSuccessDialogMessage(
                        `The LPR for ${activeProject.name} was approved.`
                      );
                    }}
                  >
                    APPROVE
                  </SmallButton>
                </>
              )}
              {activeProject.stage !== "vp_approval" && (
                <>Project is not in Vp Approval Phase</>
              )}
            </Grid>
          )}
          {showCAErrors && (
            <div style={{ color: "red" }}>
              Please enter all of the required fields to mark as complete(*).
            </div>
          )}

          {(commercialAssessmentEditable || technicalAssessmentEditable) && (
            <LargeButton
              color="green"
              text="MARK COMPLETE"
              onClick={() => {
                console.log("here", caErrors);
                if (caErrors) {
                  setShowCAErrors(true);
                  return;
                } else if (showCAErrors) {
                  setShowCAErrors(false);
                }
                setDialogOpen(true);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
