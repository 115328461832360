import {Grid, makeStyles, Typography} from "@material-ui/core";
import SmallButton from "../Components/SmallButton";
import * as React from "react";
import {headerCss, hideMobile, showMobile} from "../Utils/utils";
import {useHistory} from "react-router";
import {customerApi} from "../Utils/api/customerAPi";
import {acceptPrivacyPolicy, getUserId, logout} from "../Redux/slices/userSlice";
import {useAppDispatch, useAppSelector} from "../Redux/hooks";

const useStyles = makeStyles((theme) => ({
  root: headerCss(theme) as any,
  headerText: {
    fontFamily: "MuseoSlab",
    fontSize: "50px",
    fontWeight: 100,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.79px",
    textAlign: "center",
    color: "#01366c",
  },
  bodyText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#01366c",
  },
  showMobile: showMobile(theme) as any,
  hideMobile: hideMobile(theme) as any,
  mobileHeaderText: {
    marginBottom: 25,
  },
  marginRoot: { marginTop: 25, marginBottom: 25 },
}));

export default function PrivacyPolicy() {
  const history = useHistory();
  const classes = useStyles();
  const userId = useAppSelector(getUserId) || "";
  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(logout());
    history.push('/login');
  }
  const onAccept = () => {
    customerApi
      .updateProfile(userId, { privacy_policy_accepted: true })
      .then(() => {
        dispatch(acceptPrivacyPolicy());
        localStorage.setItem("privacy_policy_accepted", "true");
        history.push('/customer/dashboard');
      });
  }
  return (
    <Grid
      container
      justify="center"
      className={classes.root}
    >
      <Grid item style={{ overflowY: "auto", marginBottom: 25 }}>
        <Typography className={classes.headerText}>Privacy Policy</Typography>
        <br />
        <br />
        <Typography
          className={classes.bodyText}
          style={{ width: "80%", margin: "auto" }}
        >
          Please note that the practices of Azelis Americas PC and H&IC (“Azelis
          Americas PC and H&ICl”), with respect to any data collected and used
          in connection with this website (hereafter refered as the “Site”), are
          governed by this online Privacy Policy as amended from time to time,
          and not the Privacy Policy in effect at the time the data was
          collected. Please regularly review our Privacy Policy. If you have
          objections to the Privacy Policy, you should immediately discontinue
          use of the Site and the products and services enabled by the Site.
          <br />
          <br />
          The Site and the products and services are intended for adult
          audiences, and Azelis Americas PC and H&IC does not knowingly collect
          any personal information from anyone under 13 years of age.
          <br />
          <br />
          As a visitor to this Site, you can engage in many activities without
          providing any personal information. Azelis Americas PC and H&IC may
          collect information about the use of the products and services and the
          Site, such as the number of downloads, types of services used, how
          many users we receive daily, and the IP/Internet address of your
          computer. This information is collected in aggregate form, without
          identifying any user individually. Azelis Americas PC and H&IC may use
          this aggregate, non-identifying statistical data for improving the
          Site, improving the products and services, providing more relevant
          experiences to our visitors, and for statistical analysis.
          <br />
          <br />
          <span>Protection of Personal Information</span>
          The personal information that you may provide in connection with
          registering yourself as a user of this Site or of the products and
          services is classified as Registration Information. Registration
          Information is not necessary to access many parts of this Site. A
          password may be required for any Members-Only information.
          <br />
          <br />
          Azelis Americas PC and H&IC takes its users’ privacy concerns
          seriously. If you believe that Azelis Americas PC and H&IC has not
          complied with this Privacy Policy with respect to your Personal
          Information, you may write to the following address:
          <br />
          <br />
          Attn: IT Director Azelis Americas PC and H&IC 262 Harbor Drive, 3rd
          Floor Stamford, CT 06902
          <br />
          <br />
          In your letter, please describe in as much detail as possible the ways
          in which you believe that the Privacy Policy has not been complied
          with. We will investigate your complaint promptly.
          <br />
          <br />
          Also, please note that Azelis Americas PC and H&IC is not responsible
          for the content or privacy practices of non-Azelis Americas PC and
          H&IC websites to which this Site or any other Azelis Americas PC and
          H&IC website may link. You should review the privacy policy of such
          sites before using the sites.
          <br />
          <br />© Copyright Azelis Americas PC and H&IC. All rights reserved.
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ width: 400, margin: "auto", marginBottom: 50 }}
      >
        <SmallButton color="red" onClick={onCancel}>
          CANCEL
        </SmallButton>
        <SmallButton color="green" onClick={onAccept}>
          ACCEPT
        </SmallButton>
      </Grid>
    </Grid>
  );
};
