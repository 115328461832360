import * as React from "react";
import { ReactComponent as SaveIcon } from "../Images/save.svg";
import { ReactComponent as SaveHoverIcon } from "../Images/save-hover.svg";
import { makeStyles, SvgIcon } from "@material-ui/core";
import { ISaveButtonProps } from "Interfaces/props/saveButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function SaveButton(props: ISaveButtonProps) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  const onHover = () => {
    setHover(true);
  };
  const onUnhover = () => {
    setHover(false);
  };
  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onUnhover}
      className={classes.root}
    >
      <SvgIcon viewBox="0 0 24 24">
        {hover ? <SaveHoverIcon /> : <SaveIcon />}
      </SvgIcon>
    </div>
  );
}
