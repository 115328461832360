import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import BlueButton from "../Images/blue-button.svg";
import BlueButtonHover from "../Images/blue-button-hover.svg";
import GreenButton from "../Images/green-button.svg";
import GreenButtonHover from "../Images/green-button-hover.svg";
import RedButton from "../Images/red-button.svg";
import RedButtonHover from "../Images/red-button-hover.svg";
import { ISmallButtonProps } from "Interfaces/props/smallButtonProps";

const rootCss = {
  width: "150px",
  height: "50px",
  display: "flex",
  justify: "center",
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  redRoot: {
    backgroundImage: `url(${RedButton})`,
    ...rootCss,
    cursor: "pointer",

    "&:hover": {
      backgroundImage: `url(${RedButtonHover})`,
    },
  },
  greenRoot: {
    backgroundImage: `url(${GreenButton})`,
    ...rootCss,
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${GreenButtonHover})`,
    },
  },
  blueRoot: {
    backgroundImage: `url(${BlueButton})`,
    ...rootCss,
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${BlueButtonHover})`,
    },
  },
  disabledRoot: {
    ...rootCss,
    opacity: 0.5,
    backgroundImage: (props: any) => props.disabledColorUrl,
  },
  buttonText: {
    margin: "auto",
    "font-family": "MontserratBold",
    "font-size": "14px",
    "font-weight": "bold",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": "normal",
    "letter-spacing": "1.5px",
    "text-align": "center",
    color: "#01366c",
  },
}));

export default function SmallButton(props: ISmallButtonProps) {
  let disabledColorUrl = "";
  let rootClass = "";
  let textColor = undefined;
  switch (props.color) {
    case "red":
      textColor = "white";
      disabledColorUrl = `url(${RedButton})`;
      break;
    case "blue":
      disabledColorUrl = `url(${BlueButton})`;
      break;
    case "green":
      disabledColorUrl = `url(${GreenButton})`;
      break;
    default:
      break;
  }
  const classes = useStyles({ ...props, disabledColorUrl });
  switch (props.color) {
    case "red":
      rootClass = classes.redRoot;
      break;
    case "blue":
      rootClass = classes.blueRoot;
      break;
    case "green":
      rootClass = classes.greenRoot;
      break;
    default:
      break;
  }
  return (
    <div
      className={rootClass + " " + props.className}
      style={props.style}
      onClick={props.onClick}
    >
      <Typography className={classes.buttonText} style={{ color: textColor }}>
        {props.children}
      </Typography>
    </div>
  );
}
