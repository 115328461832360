import { Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { ReactComponent as AddButtonSvg } from "../Images/add-button.svg";
import { ReactComponent as AddButtonHoverSvg } from "../Images/add-button-hover.svg";
import { useState } from "react";
import { IAddButtonProps } from "Interfaces/props/addButtonProps";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  iconRoot: {
    width: (props: IAddButtonProps) => (props.size ? props.size : 34),
    height: (props: IAddButtonProps) => (props.size ? props.size : 34),
  },
  labelText: {
    fontFamily: "VarelaRound",
    fontSize: (props: IAddButtonProps) =>
      props.fontSize ? props.fontSize : 15,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    marginLeft: 10,
  },
}));

export default function AddButton(props: IAddButtonProps) {
  const [isHovered, setHovered] = useState(false);
  const classes = useStyles(props);

  const fileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(event);
    event.stopPropagation();
    event.preventDefault();
  };

  const content = (
    <>
      <SvgIcon
        className={classes.iconRoot}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        viewBox="0 0 34 34"
      >
        {isHovered ? <AddButtonHoverSvg /> : <AddButtonSvg />}
      </SvgIcon>
      {props.label ? (
        <Typography className={classes.labelText}>{props.label}</Typography>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
      onClick={props.onClick}
      style={props.style}
    >
      {props.file ? (
        <label>
          <Grid container direction="row" alignItems="center">
            <input type="file" hidden multiple accept={props.accept} onChange={fileUpload} />
            {content}
          </Grid>
        </label>
      ) : (
        content
      )}
    </Grid>
  );
}
