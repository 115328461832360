import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import DialogTextfield from "../Components/DialogTextfield";
import MultipleChoice from "../Components/DialogMultipleChoice";
import OrangeDialogContainer from "../Components/OrangeDialogContainer";
import OrangeDialogSlide from "../Components/OrangeDialogSlide";
import ProgressBar from "../Components/ProgressBar";
import BackgroundVideo from "../Videos/mrfollicle_loop.mp4";
import { ReactComponent as RestartIcon } from "../Images/restart.svg";
import { ReactComponent as ExploreIcon } from "../Images/explore.svg";
import { get, post, hideMobile, showMobile, toBase64 } from "../Utils/utils";
import { Prompt, useHistory } from "react-router";
import { getUserId } from "../Redux/slices/userSlice";
import { useAppSelector } from "../Redux/hooks";
import LargeButton from "../Components/LargeButton";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    paddingTop: "20vh",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
      paddingTop: 0,
      marginTop: "90px",
      height: "auto",
    },
  },
  verticalCenter: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  sideItem: {
    marginTop: 100,
  },
  dialogRoot: {
    marginRight: "30%",
    marginLeft: "15%",
    zIndex: 11,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  titleText: {
    "font-family": "MuseoSlab",
    "font-size": "28px",
    "font-weight": 300,
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.25,
    "letter-spacing": "1px",
    "text-align": "center",
    color: "#01366c",
    textAlign: "center",
  },
  bodyText: {
    "font-family": "VarelaRound",
    "font-size": "15px",
    "font-weight": "normal",
    "font-stretch": "normal",
    "font-style": "normal",
    "line-height": 1.33,
    "letter-spacing": "normal",
    "text-align": "center",
    color: "#01366c",
    width: 200,
    margin: "auto",
    textAlign: "center",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "auto",
    width: "auto",
    minWidth: "100%",
    minHeight: "100%",
    backgroundColor: "white",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonRoot: {
    margin: "auto",
  },
  hideMobile: hideMobile(theme) as any,
  showMobile: showMobile(theme) as any,
}));

const marketApplications = [
  "Coatings",
  "Inks & Packaging",
  "Construction Solutions",
];

const startingPoints = [
  "I’m creating a new formula",
  "I need to adjust an existing formula",
  "I have to replace a raw material in my formula",
  "I need help with performance (or comparative) testing",
];
const marketingSources = [
  "My Azelis Sales Representative",
  "I've worked with the Azelis Lab previously",
  "Azelis email campaign",
  "Azelis website",
  "Industry websites",
  "Colleague / Word of mouth",
  "Other",
];
const progressBarLabels = [
  "Start",
  "Problem Statement",
  "Properties & Performance Targets",
  "Processing / Mixing Considerations",
  "Application Parameters",
  "Regulatory & Environmental",
  "Market Opportunity",
  "SUBMIT PROJECT",
];

const marketApplicationSubmenu = [
  [
    "Architectural Coating",
    "Industrial Coatings & Adhesives",
    "Concrete Coatings",
    "Textiles (Fabric/Yarn/Leather)",
  ],
  ["Pigment Dispersions", "Ink", "Overprint", "Package Adhesives"],
  [
    "Adhesives - Construction/Industrial",
    "Caulks/Sealants",
    "Flooring Adhesives",
    "Leveling/Overlay",
    "Patch/Repair",
    "Plasters/Drywall",
    "Roof Coatings",
    "Stucco/EIFS",
    "Tile Setting/Grout",
    "Waterproofing & Air Barriers",
  ],
];

const ProductLauncher = () => {
  const customer_id = useAppSelector(getUserId) || "";
  const [marketApplication, setMarketApplication] = useState(-1);
  const [marketingSource, setMarketingSource] = useState(-1);
  const [subMarketApplication, setSubMarketApplication] = useState(-1);
  const [startingPoint, setStartingPoint] = useState(-1);
  const [endUse, setEndUse] = useState("");
  const [attachments, setAttachments] = useState<any>([]);
  const [problemStatement, setProblemStatement] = useState("");
  const [marketingSourceOther, setMarketingSourceOther] = useState("");
  const [performanceTargets, setPerformanceTargets] = useState("");
  const [propertyTargets, setPropertyTargets] = useState("");
  const [shouldKnow, setShouldKnow] = useState("");
  const [applicationParameters, setApplicationParameters] = useState("");
  const [regulatorySpecification, setRegulatorySpecification] = useState("");
  const [claims, setClaims] = useState("");
  const [volumePotential, setVolumePotential] = useState("");
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState<any>([]);
  const [questionIds, setQuestionIds] = useState<any>([]);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const response = await get("/questions/list/1");
      setQuestions(response.data);
    })();
  }, []);

  useEffect(() => {
    setQuestionIds(questions.map((question: any) => question.id));
  }, [questions]);

  const changeEndUse = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEndUse(event.target.value);
  };
  const changeProblemStatement = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setProblemStatement(event.target.value);
  };

  const changePerformanceTarget = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPerformanceTargets(event.target.value);
  };

  const changePropertyTarget = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPropertyTargets(event.target.value);
  };

  const changeShouldKnow = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setShouldKnow(event.target.value);
  };

  const changeApplicationParameters = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setApplicationParameters(event.target.value);
  };

  const changeRegulatorSpecification = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRegulatorySpecification(event.target.value);
  };

  const changeClaims = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setClaims(event.target.value);
  };

  const changeVolumePotential = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setVolumePotential(event.target.value);
  };
  const changeMarketingSourceFollowupAnswer = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMarketingSourceOther(event.target.value);
  };
  const changeName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setName(event.target.value);
  };

  const changeComments = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value);
  };

  let marketApplicationAnswer =
    marketApplication >= 0 ? marketApplications[marketApplication] : "";
  let marketAppFollowupAnswer =
    subMarketApplication >= 0
      ? marketApplicationSubmenu[marketApplication][subMarketApplication]
      : "";
  let startingPointAnswer =
    startingPoint >= 0 ? startingPoints[startingPoint] : "";
  let marketingSourceAnswer =
    marketingSource >= 0 ? marketingSources[marketingSource] : "";

  const project = {
    name: name,
    customer_id: customer_id,
    project_type: "any",
    project_status: "commercial_assessment",
    is_submitted: true,
    attachments: attachments,
    answers: [
      {
        question_id: questionIds[0],
        text: startingPointAnswer,
        next_id: questions[0]?.next_id,
        is_first: true,
        is_answered: startingPointAnswer.length > 0,
      },
      {
        question_id: questionIds[1],
        text: marketingSourceAnswer,
        conditional_answers: marketingSourceOther,
        next_id: questions[1]?.next_id,
        is_first: false,
        is_answered: marketingSourceAnswer.length > 0,
      },
      {
        question_id: questionIds[2],
        text: marketApplicationAnswer,
        conditional_answers: marketAppFollowupAnswer,
        next_id: questions[2]?.next_id,
        is_first: false,
        is_answered: marketApplicationAnswer.length > 0,
      },
      {
        question_id: questionIds[3],
        text: endUse,
        next_id: questions[3]?.next_id,
        is_first: false,
        is_answered: endUse.length > 0,
      },
      {
        question_id: questionIds[4],
        text: problemStatement,
        next_id: questions[4]?.next_id,
        is_first: false,
        is_answered: problemStatement.length > 0,
      },
      {
        question_id: questionIds[5],
        text: performanceTargets,
        next_id: questions[5]?.next_id,
        is_first: false,
        is_answered: performanceTargets.length > 0,
      },
      {
        question_id: questionIds[6],
        text: propertyTargets,
        next_id: questions[6]?.next_id,
        is_first: false,
        is_answered: propertyTargets.length > 0,
      },
      {
        question_id: questionIds[7],
        text: shouldKnow,
        next_id: questions[7]?.next_id,
        is_first: false,
        is_answered: shouldKnow.length > 0,
      },
      {
        question_id: questionIds[8],
        text: applicationParameters,
        next_id: questions[8]?.next_id,
        is_first: false,
        is_answered: applicationParameters.length > 0,
      },
      {
        question_id: questionIds[9],
        text: regulatorySpecification,
        next_id: questions[9]?.next_id,
        is_first: false,
        is_answered: regulatorySpecification.length > 0,
      },
      {
        question_id: questionIds[10],
        text: claims,
        next_id: questions[10]?.next_id,
        is_first: false,
        is_answered: claims.length > 0,
      },
      {
        question_id: questionIds[11],
        text: volumePotential,
        next_id: questions[11]?.next_id,
        is_first: false,
        is_answered: volumePotential.length > 0,
      },
      {
        question_id: questionIds[12],
        text: name,
        next_id: questions[12]?.next_id,
        is_first: false,
        is_answered: name.length > 0,
      },
      {
        question_id: questionIds[13],
        text: comments,
        next_id: questions[13]?.next_id,
        is_first: false,
        is_answered: comments.length > 0,
      },
    ],
  };

  const submit = () => {
    post("/customer/projects", project).then(() => {
      setIsSubmitted(true);
    });
  };

  const onForward = (oldIndex: number) => {
    setSlideIndex(oldIndex + 1);
    if (oldIndex === 0 || oldIndex === 1 || oldIndex === 8 || oldIndex === 9)
      return;
    else if (currentIndex === 6 && !isSubmitted) submit();
    setCurrentIndex(currentIndex + 1);
  };

  const onBackward = (oldIndex: number) => {
    setSlideIndex(oldIndex - 1);
    if (oldIndex === 1 || oldIndex === 2 || oldIndex === 9 || oldIndex === 10)
      return;
    setCurrentIndex(currentIndex - 1);
  };

  const changeIndex = (newIndex: number) => {
    setCurrentIndex(newIndex);
    if (newIndex === 0) {
      setSlideIndex(0);
    } else if (newIndex === 8) {
      setSlideIndex(8);
    } else if (newIndex > 5) {
      setSlideIndex(newIndex + 2);
    } else {
      setSlideIndex(newIndex + 2);
    }
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files).map(async (file) => {
      return {
        filename: file.name,
        content_type: file.type,
        file: await toBase64(file),
      };
    });
    Promise.all(files).then((encodedFiles) => {
      setAttachments([...attachments, ...encodedFiles]);
    });
    event.target.value = "";
  };

  return (
    <>
      <video
        className={classes.video}
        style={{ zIndex: -1, objectFit: "fill" }}
        loop
        autoPlay
        muted
      >
        <source src={BackgroundVideo} type="video/mp4" />
      </video>
      <Prompt
        when={
          slideIndex !== 0 &&
          !isSubmitted &&
          slideIndex !== 8 &&
          slideIndex !== 9
        }
        message={JSON.stringify(project)}
      />
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.root}
      >
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.dialogRoot}>
            <OrangeDialogContainer
              onForward={onForward}
              onBackward={onBackward}
              onChange={onChange}
              attachments={attachments}
              slideIndex={slideIndex}
            >
              <OrangeDialogSlide>
                <MultipleChoice
                  options={startingPoints}
                  title="What is your starting point?"
                  onSelect={(idx: number) => {
                    setStartingPoint(idx);
                  }}
                  selectedOption={startingPoint}
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <MultipleChoice
                  options={marketApplications}
                  title="What is the market application for this project?"
                  onSelect={(idx: number) => {
                    setMarketApplication(idx);
                    setSubMarketApplication(-1);
                  }}
                  selectedOption={marketApplication}
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <MultipleChoice
                  options={marketApplicationSubmenu[marketApplication]}
                  title="What is the market application for this project?"
                  onSelect={(idx: number) => {
                    setSubMarketApplication(idx);
                  }}
                  selectedOption={subMarketApplication}
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="What is the end-use application?"
                  onChange={changeEndUse}
                  value={endUse}
                  titleFontSize="18px"
                />
                <DialogTextfield
                  title="Problem Statement / Project Goal:"
                  onChange={changeProblemStatement}
                  value={problemStatement}
                  titleFontSize="18px"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="What end-use performance standards are you targeting?"
                  onChange={changePerformanceTarget}
                  placeholder={
                    "Examples: Tensile strength, scrub resistance, ASTM B-117, etc. Please include any ASTM or testing standards that are applicable."
                  }
                  titleFontSize="18px"
                  value={performanceTargets}
                />
                <DialogTextfield
                  title="Are there in-can or application properties to meet?"
                  onChange={changePropertyTarget}
                  value={propertyTargets}
                  titleFontSize="18px"
                  placeholder="Examples: Viscosity, pH, VOC < 50 g/l, Color. Please include any ASTM or testing methods that are applicable."
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="Is there anything we should know about the processing, mixing, or production of this product?"
                  onChange={changeShouldKnow}
                  value={shouldKnow}
                  titleFontSize="18px"
                  placeholder="Are there any concerns with mixing, dispersion, foaming, or other processing? If mixed on site, what is the method?"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="What are the application parameters?"
                  onChange={changeApplicationParameters}
                  value={applicationParameters}
                  titleFontSize="18px"
                  placeholder="Is there anything important for us to know regarding: Equipment/method, Substrate(s), Film thickness, Environmental conditions, Pot-life, Cure method and speed"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="Are there regulatory specifications for this formula?"
                  onChange={changeRegulatorSpecification}
                  value={regulatorySpecification}
                  titleFontSize="18px"
                />
                <DialogTextfield
                  title="Are there environmental or sustainability claims important to this formulation?"
                  onChange={changeClaims}
                  value={claims}
                  titleFontSize="18px"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <DialogTextfield
                  title="Do you know the estimated annual volume potential for this product?"
                  onChange={changeVolumePotential}
                  value={volumePotential}
                  placeholder="Indicate the units/year in lbs, gallons, totes, or truckloads per year"
                  titleFontSize="18px"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <MultipleChoice
                  options={marketingSources}
                  title="Where did you learn about e-Lab?"
                  onSelect={(idx: number) => {
                    setMarketingSource(idx);
                    setMarketingSourceOther("");
                  }}
                  selectedOption={marketingSource}
                />
              </OrangeDialogSlide>
              {marketingSource === marketingSources.length - 1 && (
                <OrangeDialogSlide>
                  <DialogTextfield
                    title="Where did you learn about e-Lab?"
                    onChange={changeMarketingSourceFollowupAnswer}
                    value={marketingSourceOther}
                    titleFontSize="18px"
                  />
                </OrangeDialogSlide>
              )}
              <OrangeDialogSlide>
                <DialogTextfield
                  title="Please give your project a name"
                  onChange={changeName}
                  value={name}
                  titleFontSize="18px"
                />
                <DialogTextfield
                  title="Any final comments before we get started?"
                  onChange={changeComments}
                  value={comments}
                  titleFontSize="18px"
                />
              </OrangeDialogSlide>
              <OrangeDialogSlide>
                <div style={{ margin: "auto", width: "max-content" }}>
                  <Typography className={classes.titleText}>Thanks!</Typography>
                  <Typography className={classes.bodyText}>
                    Thanks, we got it! A chemist will be in touch soon. You can
                    explore the e-lab for more ideas, or create another project
                    while you wait.
                  </Typography>
                  <LargeButton
                    style={{ marginTop: 25 }}
                    className={classes.buttonRoot}
                    text="NEW PROJECT"
                    icon={<RestartIcon viewBox="0 -3 25 25" />}
                    color="green"
                    onClick={() => {
                      window.location.reload();
                    }}
                  />
                  <LargeButton
                    style={{ marginTop: 25 }}
                    className={classes.buttonRoot}
                    icon={<ExploreIcon viewBox="0 0 25 25" />}
                    text="EXPLORE LAB"
                    color="blue"
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                </div>
              </OrangeDialogSlide>
            </OrangeDialogContainer>
          </Grid>
          <Grid
            item
            className={classes.sideItem + " " + classes.hideMobile}
          ></Grid>
        </Grid>
        <Grid item className={classes.hideMobile}>
          <ProgressBar
            stages={progressBarLabels}
            currentStage={currentIndex}
            onClick={changeIndex}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductLauncher;
