import { Grid, SvgIcon, Typography } from "@material-ui/core";
import useStyles from "./styles";
import BlueColumnHeaders from "../../Components/BlueColumnHeaders";
import { userApi } from "Utils/api/userApi";
import { projectApi } from "Utils/api/projectApi";
import { useEffect } from "react";
import { useState } from "react";
import { IApiState } from "Interfaces/apiState";
import SmallButton from "Components/SmallButton";
import { useHistory, useParams } from "react-router";

export default function AssignTSR() {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [apiState, setApiState] = useState<IApiState>({});
  const [tsrs, setTsrs] = useState<any[]>([]);
  const [selectedId, setSeletecdId] = useState("");
  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    setApiState({ loading: true });
    userApi.fetchTsrs().then((response) => {
      setTsrs(response.data);
      setApiState({ loaded: true });
    });
    projectApi.getTsrID(params.projectId).then((response) => {
      setSeletecdId(response.data.tsr || "");
    });
  }, []);

  const selectTsr = (tsrId: string) => {
    if (tsrId === selectedId) {
      setSeletecdId("");
    } else {
      setSeletecdId(tsrId);
    }
  };

  const assignTsr = () => {
    if (!selectedId) {
      setError(true);
      return;
    }
    projectApi.assignTSR(params.projectId, selectedId).then(() => {
      history.push("/admin/lpr");
    });
  };

  const sortTSRS = (key: string) => {
    const sortedTsrs = [...tsrs].sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
    setTsrs(sortedTsrs);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item style={{ margin: "auto", width: "977px" }}>
        <Typography
          style={{
            height: "60px",
            fontFamily: "MuseoSlab",
            fontSize: "50px",
            fontWeight: 100,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "1.79px",
            textAlign: "center",
            color: "#01366c",
          }}>
          TSRs
        </Typography>
        {apiState.loading && <Typography>Loading....</Typography>}
        {error && <Typography>Please select a Tsr</Typography>}
        {apiState.loaded && (
          <>
            <BlueColumnHeaders
              labels={["Name", "Email"]}
              large
              onClick={(idx) => {
                sortTSRS(["name", "email"][idx]);
              }}></BlueColumnHeaders>
            <Grid container direction="column" className={classes.tableBody}>
              {tsrs.map((tsr, idx) => {
                const isFirst = idx === 0;
                const isLast = tsrs.length - 1 === idx;
                return (
                  <Grid
                    key={tsr.id}
                    container
                    direction="row"
                    style={{
                      overflow: "visible",
                      cursor: "pointer",
                      backgroundColor:
                        selectedId === tsr.id ? "rgba(146, 245, 252, 0.5)" : "",
                    }}
                    onClick={() => {
                      selectTsr(tsr.id);
                    }}>
                    <Grid
                      item
                      className={classes.leftCell}
                      style={{
                        borderTop: isFirst ? "inherit" : undefined,
                        borderBottom: isLast ? "inherit" : undefined,
                        position: "relative",
                        width: "476px",
                      }}
                      container
                      alignItems="center"
                      justify="center">
                      <Typography style={{ color: "#01366c" }}>
                        {tsr.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classes.rightCell}
                      style={{
                        borderTop: isFirst ? "inherit" : undefined,
                        borderBottom: isLast ? "inherit" : undefined,
                        position: "relative",
                        width: "476px",
                      }}
                      container
                      alignItems="center"
                      justify="center">
                      <Typography style={{ color: "#01366c" }}>
                        {tsr.email}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container direction="column">
              <SmallButton
                color="green"
                style={{ marginLeft: "auto", marginRight: "auto" }}
                onClick={assignTsr}>
                ASSIGN
              </SmallButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
