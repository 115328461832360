import * as React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router";
import ProductLauncher from "./Views/ProductLauncher";
import ProductLauncherEdit from "./Views/ProductLauncherEdit";
import InitialMenu from "./Views/InitialMenu";
import ProblemToSolve from "./Views/ProblemToSolve";
import UserManagement from "./Views/UserManagement";
import ContactUs from "./Views/ContactUs";
import Login from "./Views/Login";
import Livechat from "./Views/LiveChat";
import AdminAccount from "./Views/Admin/AdminAccount";
import AuthGuard from "./Components/Guards/AuthGuard";
import GuestGuard from "./Components/Guards/GuestGuard";
import PrivacyPolicy from "./Views/PrivacyPolicy";
import Dashboard from "./Views/Dashboard";
import ProjectDetails from "./Views/ProjectDetails";
import AdminProjectDetails from "./Views/Admin/AdminProjectDetails";
import AdminProjectsList from "./Views/Admin/AdminProjectsList";
import AdminLPRList from "./Views/Admin/AdminLPRList";
import AdminLPRDetails from "./Views/Admin/AdminLPRDetails";
import ForgotPassword from "./Views/ForgotPassword";
import ResetPassword from "./Views/ResetPassword";
import AssignTSR from "./Views/Admin/AssignTSR";
import { IGuardProps } from "Interfaces/props/guardProps";

export interface RouterProps {}

interface IRoute {
  exact?: boolean;
  guard?: React.FC<IGuardProps>;
  path?: string;
  component: React.FC<RouterProps>;
  routes?: IRoute[];
  routeType?: string;
}
export const renderRoutes = (routes: IRoute[] = []): React.ReactNode => (
  <Switch>
    {routes.map((route, i) => {
      const Guard = route.guard || React.Fragment;
      const Component = route.component;

      return (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Guard routeType={route.routeType}>
              {route.routes ? (
                renderRoutes(route.routes)
              ) : (
                <Component {...props} />
              )}
            </Guard>
          )}
        />
      );
    })}
  </Switch>
);

export const routes: IRoute[] = [
  {
    exact: false,
    path: "/login",
    guard: GuestGuard,
    component: Login,
  },
  {
    exact: false,
    path: "/forgot-password",
    guard: GuestGuard,
    component: ForgotPassword,
  },
  {
    exact: false,
    path: "/password-reset/:token",
    guard: GuestGuard,
    component: ResetPassword,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/",
    component: InitialMenu,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/customer/dashboard",
    component: Dashboard,
    routeType: "Customer",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/customer/projects/:projectId",
    component: ProjectDetails,
    routeType: "Customer",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/admin/users",
    component: UserManagement,
    routeType: "Admin",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/admin/account",
    component: AdminAccount,
    routeType: "Admin",
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/admin/lpr",
    component: AdminLPRList,
    routeType: "Admin",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/admin/lpr/:projectId",
    component: AdminLPRDetails,
    routeType: "Admin",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/admin/assign-tsr/:projectId",
    component: AssignTSR,
    routeType: "Admin",
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/admin/projects",
    component: AdminProjectsList,
    routeType: "Admin",
  },
  {
    exact: false,
    guard: AuthGuard,
    path: "/admin/projects/:projectId",
    component: AdminProjectDetails,
    routeType: "Admin",
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/product-launcher",
    component: ProductLauncher,
    routeType: "Customer",
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/product-launcher/:projectId",
    component: ProductLauncherEdit,
    routeType: 'Customer'
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/chat",
    component: Livechat,
    routeType: "Customer",
  },
  {
    exact: true,
    path: "/contact-us",
    component: ContactUs,
    routeType: "Customer",
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/problem-to-solve",
    component: ProblemToSolve,
    routeType: "Customer",
  },

  {
    component: () => <Redirect to="/login" />,
  },
];

// const Router = (props: RouterProps) => {
//   return (
//     <Switch>
//       <Route path="/login" component={Login} />
//       <PrivateRoute exact path="/" component={InitialMenu} />
//       <PrivateRoute path="/admin/projects/" component={Projects} />
//       <PrivateRoute path="/admin/account" component={AdminAccount} />
//       <PrivateRoute path="/admin/users" component={UserManagement} />
//       <PrivateRoute path="/admin/lpr" component={LPR} />
//       <PrivateRoute path="/admin" component={Admin} />
//       <PrivateRoute exact path="/customer/projects" component={Projects} />
//       <PrivateRoute path="/product-genius" component={FormulaGenius} />
//       <PrivateRoute path="/product-launcher" component={ProductLauncher} />
//       <PrivateRoute path="/problem-to-solve" component={ProblemToSolve} />
//       <PrivateRoute path="/contact-us" component={ContactUs} />
//       <PrivateRoute path="/chat" component={Livechat} />
//       <PrivateRoute path="/regulatory-search" component={RegulatorySearch} />
//     </Switch>
//   );
// };
//
// export default Router;
