import { IOrangeDialogSlideProps } from "Interfaces/props/orangeDialogSlideProps";
import React, { useEffect, useState } from "react";

const isArrayTrue = (arr: Array<boolean>) => {
  let isTrue = true;
  arr.forEach((element: boolean) => {
    if (!element) isTrue = false;
  });
  return isTrue;
};

const OrangeDialogSlide: React.FC<IOrangeDialogSlideProps> = (props) => {
  const setPropReady = props.setReady;
  let children = React.Children.toArray(props.children);
  const [readyArray, setReadyArray] = useState(
    new Array(children.length).fill(false)
  );

  useEffect(() => {
    if (isArrayTrue(readyArray)) {
      if (setPropReady) setPropReady(true);
    }
  }, [setPropReady, readyArray]);

  const setReady = (val: boolean, idx: number) => {
    setReadyArray((readyArray) =>
      readyArray.map((orig, i) => (i === idx ? val : orig))
    );
  };

  for (let i = 0; i < children.length; i++) {
    children[i] = React.cloneElement(children[i] as any, {
      setReady: setReady,
      idx: i,
    });
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default OrangeDialogSlide;
