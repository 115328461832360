import { makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { useAppSelector } from "Redux/hooks";
import { getActiveProject } from "Redux/slices/projectsSlice";
import ModifiableLineItem from "./ModifiableLineItem";
import { formatDate, patch } from "../Utils/utils";
import { getUserGroup } from "../Redux/slices/userSlice";
import ModifiableMultipleChoice from "./ModifiableMultipleChoice";
import { InfoPanelProps } from "Interfaces/props/infoPanelProps";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: "VarelaRound",
    fontSize: "19px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.32",
    letterSpacing: "0.24px",
    color: "#59cee7",
    marginTop: 15,
  },
  subHeaderText: {
    fontFamily: "MuseoSlab",
    fontSize: "28px",
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "1px",
    textAlign: "center",
    color: "#01366c",
    marginBottom: 25,
  },
  subText: {
    fontFamily: "VarelaRound",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#01366c",
    //marginTop: 5,
  },
  iconRoot: {
    width: 35,
    height: 35,
  },
  attachmentList: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  attachmentLink: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    marginRight: "15px",
  },
}));

const filteredQuestions = ["Please give your project a name"];
export const InfoPanel = (props: InfoPanelProps) => {
  const classes = useStyles();
  let project = useAppSelector(getActiveProject);
  const userRole = useAppSelector(getUserGroup);
  const patchUrl = "/answers";
  const editorRoles = [
    "TechnicalSalesRepresentative",
    "LabWorker",
    "LabManager",
  ];
  const isApproved = project?.is_approved;

  if (props.project) project = props.project;
  if (!project || Object.keys(project).length === 0 || !project.answers)
    return (
      <Typography className={classes.titleText} style={{ textAlign: "center" }}>
        This project has no application content
      </Typography>
    );
  const saveField = (answerID: string, updatedAnswer: object) => {
    patch(patchUrl + `/${answerID}`, updatedAnswer);
  };

  const renderAnswers = () => {
    const answers = project.answers.map((answer: any, idx) => {
      let optionAnswers, answerIndex;
      switch (answer.question.answer_type) {
        case "multiple_choice":
          optionAnswers = answer.question.multiple_choice_answers.split("|");
          answerIndex = optionAnswers.indexOf(answer.text);
          return (
            <ModifiableMultipleChoice
              key={idx}
              title={answer.question.text}
              subtitle={
                answer?.question?.conditional_question
                  ? `${answer.text}, ${answer.conditional_answers}`
                  : `${answer.text}`
              }
              dbField={answer.id}
              options={optionAnswers}
              isConditional={false}
              selectedIdx={answerIndex}
              editEnabled={
                editorRoles.includes(userRole) && !isApproved && props.canEdit
              }
              onSave={saveField}
            />
          );
        case "multiple_choice_multiple_answers":
          optionAnswers = answer.question.multiple_choice_answers.split("|");
          answerIndex = optionAnswers.indexOf(answer.text);
          let conditionalOptions: any = {};
          for (let key in answer?.question?.conditional_question) {
            if (answer.question.conditional_question.hasOwnProperty(key)) {
              conditionalOptions[key] =
                answer.question.conditional_question[
                  key
                ].multiple_choice_answers.split("|");
            }
          }
          let selectedConditionalAnswerIndex = conditionalOptions[
            answer.text
          ]?.indexOf(answer?.conditional_answers);
          return (
            <ModifiableMultipleChoice
              key={idx}
              title={answer.question.text}
              subtitle={
                answer?.question?.conditional_question
                  ? `${answer.text}, ${answer.conditional_answers}`
                  : `${answer.text}`
              }
              dbField={answer.id}
              options={optionAnswers}
              selectedIdx={answerIndex}
              isConditional={true}
              conditionalOptions={conditionalOptions}
              selectedConditionalIdx={selectedConditionalAnswerIndex}
              editEnabled={
                editorRoles.includes(userRole) && !isApproved && props.canEdit
              }
              onSave={saveField}
            />
          );
        case "multiple_choice_with_other_option":
          optionAnswers = answer.question.multiple_choice_answers.split("|");
          answerIndex = optionAnswers.indexOf(answer.text);
          let conditionalOther = answer.conditional_answers;
          return (
            <ModifiableMultipleChoice
              key={idx}
              title={answer.question.text}
              subtitle={
                conditionalOther.length > 0
                  ? `${answer.text}, ${conditionalOther}`
                  : `${answer.text}`
              }
              dbField={answer.id}
              options={optionAnswers}
              selectedIdx={answerIndex}
              isConditional={false}
              hasOther={true}
              otherText={conditionalOther}
              editEnabled={
                editorRoles.includes(userRole) && !isApproved && props.canEdit
              }
              onSave={saveField}
            />
          );
        case "text":
        default:
          if (filteredQuestions.includes(answer.question.text)) {
            return <></>;
          }
          return (
            <ModifiableLineItem
              key={idx}
              className={classes.subText}
              title={answer.question.text}
              subtitle={answer.text}
              editEnabled={
                editorRoles.includes(userRole) && !isApproved && props.canEdit
              }
              dbField={answer.id}
              onEdit={() => {}}
              onSave={saveField}
            />
          );
      }
    });
    return <>{answers}</>;
  };

  return (
    <>
      <Typography className={classes.titleText}>Name</Typography>
      <Typography className={classes.subText}>{project.name}</Typography>
      <Typography className={classes.titleText}>Request Date</Typography>
      <Typography className={classes.subText}>
        {formatDate(project.submitted_at)}
      </Typography>
      {renderAnswers()}
      {/* {renderAttachments()} */}
    </>
  );
};
