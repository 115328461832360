import * as React from 'react'
import { Redirect } from 'react-router-dom'
import {useAppSelector} from "../../../Redux/hooks";
import {getAuthState, getPrivacyPolicyAccepted, getUserGroup} from "../../../Redux/slices/userSlice";


const GuestGuard: React.FC = ({ children }) => {
  console.log('GuestGuard');
  const isAuth = useAppSelector(getAuthState)
  const privacyPolicyAccepted = useAppSelector(getPrivacyPolicyAccepted);
  const userRole = useAppSelector(getUserGroup);

  if (isAuth && userRole === 'Customer') {
    if (!privacyPolicyAccepted) {
      return <Redirect to="/privacy-policy" />;
    }
    return <Redirect to="/" />;
  } else if(isAuth && userRole !== 'Customer') {
    let isToLPR = userRole ==='TechnicalSalesRepresentative' || userRole ==='LabManager'|| userRole === 'VicePresident'
    if(isAuth && isToLPR) {
      return <Redirect to="/admin/lpr" />
    }
    return <Redirect to="/admin/projects" />
  }

  return <>{children}</>;
}

export default GuestGuard;
