import { makeStyles } from "@material-ui/core";
import DeleteIcon from "../Images/delete-small.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 34,
    height: 34,
    borderRadius: "50%",
    border: "2px solid red",
    cursor: "pointer",
  },
}));

type DeletBtnProps = {
  onClick: () => void;
};

const DeleteButton = ({ onClick }: DeletBtnProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <img src={DeleteIcon} alt="delete" />
    </div>
  );
};

export default DeleteButton;
